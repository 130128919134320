import React, {Component, Dispatch} from "react";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {searchProductsByFilter} from "../../../ActionCreators/ProductFilter";
import "./addProductModal.scss";
import axios from "axios";
import {getObjectId} from "../../../Common/Utility";
import {Product} from "mesmetric-v2-common/models/Product";
import Modal from "@material-ui/core/Modal";
import {AppState} from "../../../Store";
import {AllActions} from "../../../ActionCreators/ProductsList";
import {ProductFilterState} from "../../../State/ProductFilter";
import PrimeCategory from "./PrimeCategory";
import {getAxiosConfig} from "../../../ActionCreators/User";
import {parseError} from "../../../ActionCreators/Error";

interface OwnProps {
    onProductAdded: (id: string) => void
}

interface DispatchProps {
    searchProductsByFilter: any
}

interface StateProps {
    filter: ProductFilterState
}

type Props = OwnProps & DispatchProps & StateProps

interface State {
    open: boolean
    categoryId?: string
}

class AddProductModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            open: false
        }
    }

    setCategoryId = (id: string) => {
        this.setState({
            categoryId: id
        })
    };

    addProduct = () => {
        const newProductId = getObjectId();
        //@ts-ignore
        const product: Product = {_id: newProductId, isActive: true, primaryCategory: this.state.categoryId};

        axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/products', product, getAxiosConfig())
            .then(() => {
                this.props.searchProductsByFilter(this.props.filter);
                this.props.onProductAdded(newProductId);
            })
            .catch(parseError)
            .finally(() => {
                this.setState({
                    open: false
                });
            })
    };

    handleOpen = () => {
        this.setState({
            open: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false,
            categoryId: undefined
        })
    };


    public render = (): JSX.Element => {
        return <>
            <Button
                className={"add-button"}
                size={"small"}
                variant={"contained"}
                onClick={this.handleOpen}
            >Nowy produkt</Button>
            <Modal
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="modalStyle">
                    <PrimeCategory
                        label={"Kategoria produktu"}
                        path={"primaryCategory"}
                        onChange={this.setCategoryId}/>
                    <div className={"buttons"}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={this.addProduct}
                            disabled={!this.state.categoryId}>
                            <span>DODAJ</span>
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={this.handleClose}>
                            <span>ANULUJ</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    }
}

const mapStateToProps = (state: AppState) => ({
    filter: state.ProductFilter
});

const mapDispatchToProps = (dispatch: Dispatch<AllActions>) => ({
    searchProductsByFilter: (filter: ProductFilterState) => dispatch(searchProductsByFilter(filter))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductModal);