import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import Brochure2D3D from "./Brochure2D3D/Brochure2D3D";
import "./filesUploaded.scss";
import Photos from "./Photos/Photos";
import {GUIDELINES_PATH} from "../../../../ActionCreators/ProductData";
import {CheckboxButtonNotConnected} from "../../Common/CheckboxButton/CheckboxButton";
import Button from "@material-ui/core/Button";
import PhotoGallery from "./PhotoGallery/PhotoGallery";
import ProductPreview from "./ProductPreview/ProductPreview";

interface FilesUploadedProps {
    productId: string
}

interface FilesUploadedState {
    croppedThumbnails: boolean,
    galleryOpened: boolean,
    productPreviewOpened: boolean
}

class FilesUploaded extends Component<FilesUploadedProps, FilesUploadedState> {
    constructor(props: FilesUploadedProps) {
        super(props);

        this.state = {
            croppedThumbnails: true,
            galleryOpened: false,
            productPreviewOpened: false
        }
    }

    public render = (): JSX.Element =>
        <>
            {this.state.galleryOpened &&
            <PhotoGallery
                onClose={() => this.setState({galleryOpened: false})}
                croppedThumbnails={this.state.croppedThumbnails}
                path={"photos"}
            />}
            {this.state.productPreviewOpened &&
            <ProductPreview
                onClose={() => this.setState({productPreviewOpened: false})
                }/>}
            <Box title={"Wgrane zdjęcia i pliki"} className={"files-uploaded"}>
                <div className={"row files-control"}>
                    <CheckboxButtonNotConnected
                        label={"Przycięte miniatury"}
                        value={this.state.croppedThumbnails}
                        onChange={value => this.setState({croppedThumbnails: value})}
                    />
                    <Button
                        className={"gallery"}
                        size="small"
                        variant="contained"
                        onClick={() => this.setState({galleryOpened: true})}
                    >
                        <span>Galeria</span>
                    </Button>
                    <Button
                        className={"product-preview"}
                        size="small"
                        variant="contained"
                        onClick={() => this.setState({productPreviewOpened: true})}
                    >
                        <span>Podgląd produktu</span>
                    </Button>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <Photos
                            croppedThumbnails={this.state.croppedThumbnails}
                            productId={this.props.productId}
                            path={"photos"}
                            guidelinesPath={GUIDELINES_PATH}
                        />
                    </div>
                    <div className={"col-6"}>
                        <Brochure2D3D
                            productId={this.props.productId}
                            path={"files"}
                        />
                    </div>
                </div>
            </Box></>
}

export default FilesUploaded;