import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox";
import {SelectProps} from "@material-ui/core/Select";
import React, {ReactElement} from "react";
import {connect} from "react-redux";
import {UpdateFilterField} from "../../ActionCreators/ProductFilter";
import store, {AppState} from "../../Store";
import {MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

export enum FilterWidgetType {
    TextField = 'textField',
    Select = 'select',
    Checkbox = 'toggle',
    Hidden = 'hidden'
}

export interface FilterDefinition<ValueType> {
    name: string,
    label?: string,
    stateKey: keyof AllProductFilters,
    defaultValue: ValueType | null,
    nullValue?: ValueType,
    getCondition: (value: ValueType) => object | undefined
    widget: FilterWidgetType
}

export interface TextFieldFilterDefinition extends FilterDefinition<string> {
    widget: FilterWidgetType.TextField
    widgetOptions?: {
        textFieldProps?: TextFieldProps
    }
}

export interface CheckboxFilterDefinition extends FilterDefinition<boolean> {
    widget: FilterWidgetType.Checkbox
    widgetOptions?: {
        checkboxProps?: CheckboxProps
    }
}

export interface SelectFilterDefinition<ValueType = string, OptionType = { label: string, value: ValueType }> extends FilterDefinition<ValueType> {
    widget: FilterWidgetType.Select,
    options: OptionType[]
    widgetOptions?: {
        selectProps?: SelectProps
    }
}

export interface HiddenFilterDefinition<ValueType> extends FilterDefinition<ValueType> {
    widget: FilterWidgetType.Hidden
}

export type AllFilterDefinitions =
    SelectFilterDefinition<any, any>
    | CheckboxFilterDefinition
    | TextFieldFilterDefinition
    | HiddenFilterDefinition<any>

function getRegexBoundaryCondition(conditionPath: string) {
    return function (value: string) {
        return {
            [conditionPath]: {
                $regex: '\\b' + value
            }
        };
    }
}

function getRegexCondition(conditionPath: string) {
    return function (value: string) {
        return {
            [conditionPath]: {
                $regex: value
            }
        };
    }
}

function getInArrayCondition(conditionPath: string) {
    return function (value: string[]) {
        if (value.length === 0) {
            return undefined;
        }
        return {
            [conditionPath]: {
                $in: value
            }
        };
    }
}

function getBooleanCondition(conditionPath: string) {
    return function (value: boolean) {
        return {
            [conditionPath]: value
        }
    }
}

function getBooleanOrExistsCondition(conditionPath: string) {
    return function (value: boolean) {
        switch (value) {
            case true:
                return {[conditionPath]: true};
            case false:
                return {
                    $or: [
                        {[conditionPath]: false},
                        {[conditionPath]: {$exists: false}},
                    ]
                }
        }
    }
}

function getExistsAndNotEmptyCondition(conditionPath: string) {
    return function (value: boolean) {
        switch (value) {
            case true:
                return {[conditionPath]: {$exists: true, $ne: ""}};
            case false:
                return {
                    $or: [
                        {[conditionPath]: ""},
                        {[conditionPath]: {$exists: false}},
                    ]
                }
        }
    }
}


function getExistsAndNotEmptyArrayCondition(conditionPath: string) {
    return function (value: boolean) {
        switch (value) {
            case true:
                return {[conditionPath]: {$not: {$size: 0}, $exists: true}};
            case false:
                return {
                    $or: [
                        {[conditionPath]: {$size: 0}},
                        {[conditionPath]: {$exists: false}},
                    ]
                }
        }
    }
}


function getExistsCondition(conditionPath: string) {
    return function (value: boolean) {
        return {
            [conditionPath]: {
                $exists: value
            }
        }
    }
}

export enum PhotoFilterValues {
    firstOld = "Pierwsze stare",
    allOld = "Wszystkie stare",
    firstNew = "Pierwsze nowe",
    allNew = "Wszystkie nowe",
    any = "Dowolne"
}

export interface AllProductFilters {
    name: TextFieldFilterDefinition,
    brand: TextFieldFilterDefinition,
    isActive: CheckboxFilterDefinition,
    code: TextFieldFilterDefinition,
    categories: HiddenFilterDefinition<string[]>,
    photos: SelectFilterDefinition<PhotoFilterValues, { label: string, value: PhotoFilterValues }>,
    photoCount: CheckboxFilterDefinition,
    englishDescription: CheckboxFilterDefinition,
    has2D: CheckboxFilterDefinition,
    has3D: CheckboxFilterDefinition,
    hasBrochure: CheckboxFilterDefinition,
    hasMaterial: CheckboxFilterDefinition,
    hasColour: CheckboxFilterDefinition,
    hasSize: CheckboxFilterDefinition,
    hasValues: CheckboxFilterDefinition,
    hasStyles: CheckboxFilterDefinition,
}

export const allProductFilters: AllProductFilters = {
    isActive: {
        stateKey: "isActive",
        label: "Aktywny",
        name: 'isActive',
        widget: FilterWidgetType.Checkbox,
        defaultValue: true,
        getCondition: getBooleanCondition('isActive')
    },
    name: {
        stateKey: "name",
        label: "Nazwa",
        widget: FilterWidgetType.TextField,
        defaultValue: null,
        name: 'name',
        getCondition: getRegexBoundaryCondition('name'),
    },
    brand: {
        stateKey: "brand",
        label: "Marka",
        widget: FilterWidgetType.TextField,
        defaultValue: null,
        name: 'brand',
        getCondition: (value) => {
            return {
                brand: {
                    $in: store.getState().Brands.brands.filter(brand => brand.name.match(value)).map(brand => brand._id)
                }
            };
        }
    },
    code: {
        stateKey: "code",
        label: "Kod",
        widget: FilterWidgetType.TextField,
        defaultValue: null,
        name: 'code',
        getCondition: getRegexCondition('code')
    },
    categories: {
        name: "categories",
        stateKey: "categories",
        defaultValue: null,
        widget: FilterWidgetType.Hidden,
        label: "Kategorie",
        getCondition: getInArrayCondition('primaryCategory')
    },
    photos: {
        name: 'photos',
        stateKey: 'photos',
        defaultValue: PhotoFilterValues.any,
        widget: FilterWidgetType.Select,
        label: "Zdjęcia",
        nullValue: PhotoFilterValues.any,
        options: Object.values(PhotoFilterValues).map(enumValue => ({
            label: enumValue,
            value: enumValue
        })),
        getCondition: (value: PhotoFilterValues) => {
            switch (value) {
                case PhotoFilterValues.allNew:
                    return {'photos.meta.isLegacy': {$eq: false, $ne: true}};
                case PhotoFilterValues.allOld:
                    return {'photos.meta.isLegacy': {$eq: true, $ne: false}};
                case PhotoFilterValues.firstNew:
                    return {'photos.0.meta.isLegacy': false};
                case PhotoFilterValues.firstOld:
                    return {'photos.0.meta.isLegacy': true};
                default:
                    return {}
            }
        }
    },
    photoCount: {
        stateKey: "photoCount",
        label: "Ze zdjęciami",
        name: 'hasPhotos',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getExistsAndNotEmptyArrayCondition('photos')
    },
    hasMaterial: {
        stateKey: "hasMaterial",
        label: "Nowe materiały",
        name: 'hasMaterial',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getBooleanOrExistsCondition('updateInfo.hasMaterial')
    },
    hasColour: {
        stateKey: "hasColour",
        label: "Nowe kolory",
        name: 'hasColour',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getBooleanOrExistsCondition('updateInfo.hasColour')
    },
    hasSize: {
        stateKey: "hasSize",
        label: "Nowe wymiary",
        name: 'hasSize',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getBooleanOrExistsCondition('updateInfo.hasSize')
    },
    has2D: {
        stateKey: "has2D",
        label: "Plik 2D",
        name: 'has2D',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getExistsAndNotEmptyCondition('files.2d')
    },
    has3D: {
        stateKey: "has3D",
        label: "Plik 3D",
        name: 'has3D',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getExistsAndNotEmptyCondition('files.3d')
    },
    hasBrochure: {
        stateKey: "hasBrochure",
        label: "Plik PDF",
        name: 'hasBrochure',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getExistsAndNotEmptyCondition('files.brochure')
    },
    hasValues: {
        stateKey: "hasValues",
        label: "Wartości",
        name: 'hasValues',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getExistsAndNotEmptyArrayCondition('values')
    },
    hasStyles: {
        stateKey: "hasStyles",
        label: "Style",
        name: 'hasStyles',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getExistsAndNotEmptyArrayCondition('styles')
    },
    englishDescription: {
        stateKey: 'englishDescription',
        label: "Angielski opis",
        name: 'englishDescription',
        widget: FilterWidgetType.Checkbox,
        defaultValue: null,
        getCondition: getExistsAndNotEmptyCondition('description.en')
    }
}

const TristateCheckbox: React.FC<CheckboxProps & {
    value: boolean | null,
    onChange: (newValue: boolean | null) => void
}> = ({value, onChange, ...checkboxProps}) => {
    return <Checkbox
        checked={value === true}
        indeterminate={value === null}
        onClick={() => {
            switch (value) {
                case null:
                    onChange(true);
                    return;
                case true:
                    onChange(false);
                    return;
                case false:
                    onChange(null);
                    return;
            }
        }}
        {...checkboxProps}
    />
}
export const FormControlWrapper: React.FC<{ label?: string, key?: string }> = (props) => {
    return <FormControl key={props.key}>
        <InputLabel>{props.label}</InputLabel>
        {props.children}
    </FormControl>
}

export function getFilterWidget(filterDefinition: AllFilterDefinitions, compact: boolean = false) {
    const commonProps = {
        key: `filter-${filterDefinition.name}`
    }
    let filterComponent: ReactElement | undefined;
    switch (filterDefinition.widget) {
        case (FilterWidgetType.TextField):
            const ConnectedTextField = connect((state: AppState) => ({
                value: state.ProductFilter[filterDefinition.stateKey] || "",
            }), (dispatch => ({
                onChange: (event: any) => dispatch(UpdateFilterField(filterDefinition.stateKey, event.target.value))
            })))(TextField);
            const inputProps = {
                ...(compact ? {} : {label: filterDefinition.label}),
                placeholder: ''
            };
            return <ConnectedTextField title={""}{...commonProps} {...inputProps}/>
        // case (FilterWidgetType.Checkbox):
        //     const ConnectedCheckbox = connect((state: AppState) => ({
        //         value: state.ProductFilter[filterDefinition.stateKey] as boolean | null
        //     }), (dispatch => ({
        //         onChange: (value: boolean | null) => dispatch(UpdateFilterField(filterDefinition.stateKey, value))
        //     })))(TristateCheckbox);
        //     filterComponent =
        //         <ConnectedCheckbox {...commonProps} icon={<Clear/>} indeterminateIcon={<CheckBoxOutlineBlank/>}/>;
        //     if (!compact) {
        //         return <FormControlLabel control={filterComponent} label={filterDefinition.label}/>
        //     }
        //     return filterComponent;
        case (FilterWidgetType.Select):
            const ConnectedSelect = connect((state: AppState) => ({
                value: state.ProductFilter[filterDefinition.stateKey] as string | null
            }), (dispatch => ({
                onChange: (event: any) => dispatch(UpdateFilterField(filterDefinition.stateKey, event.target.value))
            })))(Select);
            filterComponent = <ConnectedSelect>
                {filterDefinition.options.map(option =>
                    <MenuItem key={`select-${filterDefinition.name}-${option.value}`}
                              value={option.value}>{option.label}</MenuItem>
                )}
            </ConnectedSelect>;
            break;
        case (FilterWidgetType.Hidden):
        default:
            return undefined
    }
    return compact ? filterComponent :
        <FormControl key={commonProps.key}>
            <InputLabel>{filterDefinition.label}</InputLabel>
            {filterComponent}
        </FormControl>
}

