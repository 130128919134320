import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {Files} from "mesmetric-v2-common/models/Product";
import {AppState} from "../../../../../Store";
import {updateValue} from "../../../../../ActionCreators/ProductData";
import ImageOutlined from "@material-ui/icons/ImageOutlined";
import Filter2Outlined from "@material-ui/icons/Filter2Outlined";
import Filter3Outlined from "@material-ui/icons/Filter3Outlined";
import Square from "../Square/Square";
import Button from "@material-ui/core/Button";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import "./brochure2D3D.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface ExternalProps {
    productId: string,
    path: string,
}

interface StateProps {
    productId: string,
    files: Files
}

interface DispatchProps {
    onChange: (value: Files) => void
}

type Brochure2D3DProps = StateProps & DispatchProps;

const files: { type: keyof Files, label: string, getIcon: () => JSX.Element }[] = [
    {
        type: 'brochure',
        label: 'Broszura',
        getIcon: () => <ImageOutlined/>
    },
    {
        type: '2d',
        label: '2D',
        getIcon: () => <Filter2Outlined/>
    },
    {
        type: '3d',
        label: '3D',
        getIcon: () => <Filter3Outlined/>
    }
];

const fileTypes: {
    [index in keyof Files]: string
} = {
    '2d': "2D",
    '3d': "3D",
    'brochure': "Broszura"
};

class Brochure2D3D extends Component<Brochure2D3DProps> {
    static defaultProps = {
        files: {}
    };

    private fileAvailable = (fileType: keyof typeof fileTypes): boolean => !!this.props.files && !!this.props.files[fileType];


    public render = (): JSX.Element[] =>
        files.filter(file => this.fileAvailable(file.type)).map((file, index) => {
            const uploadedFile = this.props.files && this.props.files[file.type];
            const fileSrc = uploadedFile && uploadedFile.srcResolved || "";
            return <div className={"row brochure-2D-3D"} key={index}>
                <div className={"col-8"}>
                    <Square>
                        {file.getIcon()}
                        {file.label}
                    </Square>
                </div>
                <div className={"col-4"}>
                    <div className={"buttons"}>
                        <Button
                            className={"download-button"}
                            size="small"
                            href={fileSrc}
                            target={"_blank"}
                            variant="contained">
                            <span>Pobierz</span>
                            <CloudDownloadOutlined/>
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => this.props.onChange(_.omit(this.props.files, [file.type]))}
                            color="secondary">
                            <DeleteIcon/>
                        </Button>
                    </div>
                </div>
            </div>;
        })

}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    productId: externalProps.productId,
    files: _.get(state.ProductData.productData, externalProps.path)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: Files) => dispatch(updateValue(externalProps.path, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Brochure2D3D);