import {Product} from "mesmetric-v2-common/models/Product";
import React from "react";
import {BadIcon, OKIcon} from "../../Common/Icons";
import {ProductTemplate} from "mesmetric-v2-common/models/ProductTemplate";
import styles from "./Products.module.scss";
import {getCroppedSrc} from "../../Utils/PhotoUtils";
import {ProductsFilters} from "mesmetric-v2-common/models/ProductsFilters";

export type Column = {
    name: string,
    width: number,
    format: (product: Product) => JSX.Element | string | undefined,
    canBeHidden?: boolean
    field: keyof ProductsFilters,
} & ({
    type: "BOOLEAN" | "TEXT" | "NUMBER"
} | {
    type: "DICTIONARY",
    options: { key: string, value: string }[]
});

enum AttributeNames {
    WYMIARY = "WYMIARY",
    KOLORY = "KOLORY",
    MATERIALY = "MATERIAŁY"
}

const hasNewAttributes = (attributeName: AttributeNames, product: Product) => {
    if (!product.attributes) {
        return <BadIcon/>
    }

    const attributeGroup = (product.primaryCategory.template as ProductTemplate)?.attributeGroups.find(attributeGroup => attributeGroup.label.pl.toUpperCase() === attributeName);
    return !!Object.keys(product.attributes).find(id => id === attributeGroup?._id) ? <OKIcon/> : <BadIcon/>
};

export const COLUMNS: Column[] = [
    {
        name: "Aktywny",
        field: "isActive",
        width: 83,
        type: "BOOLEAN",
        format: product => product.isActive ? <OKIcon/> : <BadIcon/>
    },
    {
        name: "Zdjęcia",
        width: 132,
        field: "photos",
        type: "DICTIONARY",
        options: [
            {key: "OLD_ONLY", value: "tylko stare"},
            {key: "NEW_ONLY", value: "tylko nowe"},
            {key: "OLD_AND_NEW", value: "nowe i stare"},
            {key: "NONE", value: "żadnych"}],
        format: product => {
            if (!product.photos?.length) {
                return "Brak zdjęcia";
            }

            return <img className={styles.productImage} src={getCroppedSrc(product.photos[0], {h: 100})}/>
        }
    },
    {
        name: "Nazwa",
        width: 200,
        field: "name",
        type: "TEXT",
        format: product => product.name
    },
    {
        name: "Producent",
        width: 200,
        field: "brand",
        type: "TEXT",
        format: product => product.brand?.name
    },
    {
        name: "Kategoria",
        width: 200,
        field: "primaryCategory",
        type: "TEXT",
        format: product => product.primaryCategory?.name?.pl
    },
    {
        name: "Kod produktu",
        width: 200,
        field: "code",
        canBeHidden: true,
        type: "TEXT",
        format: product => product.code
    },
    // {
    //     name: "Online shop",
    //     width: 109,
    //     type: "BOOLEAN",
    //     format: product => <BadIcon/>
    // },
    {
        name: "Kolejność",
        width: 106,
        field: "order",
        canBeHidden: true,
        type: "NUMBER",
        format: product => product.order?.toString()
    },
    {
        name: "Cena",
        width: 106,
        field: "price",
        canBeHidden: true,
        type: "DICTIONARY",
        options: [
            {key: "CONTAINS", value: "jest cena"},
            {key: "NOT_CONTAINS", value: "nie ma ceny"},
            {key: "NO_CURRENCY", value: "nie ma waluty"},
            {key: "NOT_CALCULATED", value: "nie ma przeliczenia"}
        ],
        format: product => product.price?.value?.toString()
    },
    {
        name: "Nowe wymiary",
        width: 120,
        canBeHidden: true,
        field: "newSizes",
        type: "BOOLEAN",
        format: product => hasNewAttributes(AttributeNames.WYMIARY, product)
    },
    {
        name: "Nowe materiały",
        width: 120,
        field: "newMaterials",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => hasNewAttributes(AttributeNames.MATERIALY, product)
    },
    {
        name: "Nowe kolory",
        width: 120,
        field: "newColors",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => hasNewAttributes(AttributeNames.KOLORY, product)
    },
    {
        name: "Angielski opis",
        width: 120,
        field: "englishDescription",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => product.description?.en ? <OKIcon/> : <BadIcon/>
    },
    {
        name: "Plik 2D",
        width: 120,
        field: "file2D",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => product.files?.["2d"] ? <OKIcon/> : <BadIcon/>
    },
    {
        name: "Plik 3D",
        width: 120,
        field: "file3d",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => product.files?.["3d"] ? <OKIcon/> : <BadIcon/>
    },
    {
        name: "Plik PDF",
        width: 120,
        field: "filePDF",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => product.files?.brochure ? <OKIcon/> : <BadIcon/>
    },
    {
        name: "Wartości",
        width: 120,
        field: "values",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => product.values?.length ? <OKIcon/> : <BadIcon/>
    },
    {
        name: "Nakładka na kategorie",
        width: 240,
        field: "filters",
        canBeHidden: true,
        type: "TEXT",
        format: product => {
            if (!product.filters) {
                return undefined;
            }
            const found = Object.keys(product.filters).find(key => key.toUpperCase() === "NAKŁADKA NA KATEGORIE");
            if (found) {
                return product.filters[found].map((item: any) => item.label.pl.toLowerCase()).sort().join(", ");
            }
            return undefined;
        }
    },
    {
        name: "Style",
        width: 120,
        field: "styles",
        canBeHidden: true,
        type: "BOOLEAN",
        format: product => product.styles?.length ? <OKIcon/> : <BadIcon/>
    },
    {
        name: "Ilość zdjęć",
        width: 120,
        field: "photosCount",
        canBeHidden: true,
        type: "NUMBER",
        format: product => {
            const photos = [product.photos?.some(photo => photo?.meta.isLegacy) && `Starych: ${product.photos?.filter(photo => photo?.meta.isLegacy).length}`,
                product.photos?.some(photo => !photo?.meta.isLegacy) && `Nowych: ${product.photos?.filter(photo => !photo?.meta.isLegacy).length}`
            ];

            if (photos.filter(Boolean).length > 1) {
                return <>
                    {photos[0]}<br/>
                    {photos[1]}
                </>
            }
            return photos.filter(Boolean)?.[0] || undefined
        }
    },
    {
        name: "Powiązanych produktów",
        width: 120,
        canBeHidden: true,
        field: "relatedProductsCount",
        type: "NUMBER",
        format: product => (product.related?.length || 0).toString()
    }
];