import {ActionTypes, AllActions} from "../ActionCreators/ProductCard";
import {initialState, ProductCardState} from "../State/ProductCard";
import update from "immutability-helper"
export default function ProductCardReducer (state: ProductCardState = initialState, action: AllActions): ProductCardState {
    switch (action.type) {
        case ActionTypes.setWorkingCopy: {
            return update(state, {
                workingCopy: {
                    $set: action.product
                }
            })
        }
        default:
            return state;
    }
}

