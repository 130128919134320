import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {emphasize, makeStyles, useTheme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from "react-select";
import {ControlProps} from "react-select/src/components/Control";
import {MultiValueProps} from "react-select/src/components/MultiValue";
import {Props as SelectProps} from "react-select/base"
type DefaultOptionType = {
    [key:string]: any
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function inputComponent({inputRef, ...props}: any) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control<OptionType>(props:ControlProps<OptionType>) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps},
    } = props;
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function MultiValue<OptionType>(props:MultiValueProps<OptionType>) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

const components = {
    Control,
    MultiValue,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null
};

const styleStyles = {
    valueContainer: (styles:any) => {
        return {
            ...styles,
            padding: '2px 0px'
        }
    },
    menuPortal: (base:any) => ({
        ...base,
        zIndex: 9999
    })
}

type StyledSelectProps<OptionType> = SelectProps<OptionType>;
export default function StyledSelect<OptionType>(props: StyledSelectProps<OptionType>) {
    const classes = useStyles();
    return (
        <Select
            {...props}
            styles={styleStyles}
            classes={classes}
            TextFieldProps={{
                margin: 'normal',
                label: props.label,
                InputLabelProps: {
                    htmlFor: props.inputId,
                    shrink: true,
                },
            }}
            components={components}
        />
    );
}