import React, {Component} from "react";
import styles from "../Products.module.scss";
import {Column} from "../Columns";
import {GridCellProps} from "react-virtualized";
import {Product} from "mesmetric-v2-common/models/Product";
import {FAKE_PRODUCT, NOT_FETCHED, TO_BE_FETCHED} from "../../../ActionCreators/Products";
import {cls} from "../../../Utils/StyleUtils";

type Props = GridCellProps & {
    lastOpenedId?: string,
    items: Product[],
    onClick: (index: number) => void,
    columns: Column[],
    tempSaveIds: string[]
}

class ContentCell extends Component<Props> {
    public render = (): JSX.Element => {
        const product = this.props.items[this.props.rowIndex - 1];
        const productId = product._id;
        const fakeRow = productId === FAKE_PRODUCT._id;
        const fetching = productId === NOT_FETCHED || productId === TO_BE_FETCHED;
        return <div
            className={(fakeRow || fetching) ? undefined : cls(
                styles.cell,
                {[styles.even]: this.props.rowIndex % 2 === 0},
                {[styles.odd]: this.props.rowIndex % 2 !== 0},
                {[styles.lastActive]: this.props.lastOpenedId === productId},
                {[styles.notSaved]: this.props.tempSaveIds.includes(productId)})}
            style={{
                ...this.props.style,
                height: fakeRow ? 0 : this.props.style.height,
                minWidth: this.props.style.width,
                maxWidth: this.props.style.width
            }}
            key={`${this.props.columnIndex}${this.props.rowIndex}`}
            onClick={fakeRow ? undefined : () => this.props.onClick(this.props.rowIndex - 1)}
        >
            {(fakeRow || fetching) ? "" : (this.props.columns[this.props.columnIndex].format(product) || "-")}
        </div>;
    }

}

export default ContentCell;