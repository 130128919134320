import React, {Component} from "react";
import {GridCellProps} from "react-virtualized";
import {Product} from "mesmetric-v2-common/models/Product";
import styles from "../Products.module.scss";
import {getCroppedSrc} from "../../../Utils/PhotoUtils";
import {cls} from "../../../Utils/StyleUtils";
import {NOT_FETCHED, TO_BE_FETCHED} from "../../../ActionCreators/Products";

type Props = GridCellProps & {
    lastOpenedId?: string,
    items: Product[],
    onClick: (index: number) => void,
    columnWidth: number,
    tempSaveIds: string[]
}

class GridCell extends Component<Props> {
    public render = (): JSX.Element => {
        const index = this.props.rowIndex * 3 + this.props.columnIndex;
        const product: Product | undefined = this.props.items[index];
        const productId = product?._id;
        const width = this.props.columnWidth - 50;
        const fetching = productId === NOT_FETCHED || productId === TO_BE_FETCHED;

        return <div className={cls(styles.gridCell,
            {[styles.lastActiveGrid]: this.props.lastOpenedId === productId},
            {[styles.notSavedGrid]: this.props.tempSaveIds.includes(productId)})}
                    style={this.props.style}>
            {!!product &&
            <div
                className={styles.gridCellContent}
                onClick={product ? () => this.props.onClick(index) : undefined}
            >
                <div className={styles.imageContainer}
                     style={{width: width, height: width}}>
                    {fetching ? null : (product?.photos.length ?
                        <div className={styles.gridCellImage}
                             style={{backgroundImage: `url(${getCroppedSrc(product.photos[0], {w: 429})})`}}
                        />
                        : "Brak zdjęcia")}
                </div>
                <div className={styles.gridCellLabels}>
                    <div
                        className={styles.gridCellName}>{fetching ? "Ładowanie" : (product?.name || "Brak nazwy")}</div>
                    <div
                        className={styles.gridCellBrand}>{fetching ? "Ładowanie" : (product?.brand?.name || 'Brak marki')}</div>
                </div>
            </div>}

        </div>;
    }

}

export default GridCell;