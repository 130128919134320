import React, {useMemo} from "react";
import {allProductFilters, getFilterWidget} from "./Filter";
import Grid from "@material-ui/core/Grid";

export const FilterControls = (props: any) => {
    const filterWidgets = useMemo(() => {
        return Object.values(allProductFilters).map(filter => getFilterWidget(filter)).filter(widget => widget).map(widget => <Grid item xs={2}>{widget}</Grid>);
        }, [allProductFilters]);
    return (
        <Grid container xs={12}>
            {filterWidgets}
        </Grid>
    )
}