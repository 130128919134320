const DELAY_IN_MS = 1000;

class AutoSaveManager {
    private timeout: NodeJS.Timeout | undefined;
    private interval: NodeJS.Timeout | undefined;
    private isSaving = false;
    public attemptAutoSave = (): Promise<void> => new Promise<void>((resolve) => {
        //when save in progress, try again
        if (this.isSaving) {
            this.interval && clearInterval(this.interval);
            this.interval = setInterval(() => {
                if (this.isSaving) {
                    return;
                }
                this.createTimeout(resolve);
                this.interval && clearInterval(this.interval);
                this.interval = undefined;
            }, DELAY_IN_MS);
        } else {
            this.createTimeout(resolve);
        }
    });

    public done = (): void => {
        this.isSaving = false;
    };

    private createTimeout = (resolve: (value?: (PromiseLike<void> | void)) => void): void => {
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            this.isSaving = true;
            resolve();
            this.timeout && clearTimeout(this.timeout);
            this.timeout = undefined;
        }, DELAY_IN_MS);
    };

    public isPending = (): boolean => this.timeout !== undefined || this.interval !== undefined;

}

export const SaveManager = new AutoSaveManager();
export const GuidelinesSaveManager = new AutoSaveManager();