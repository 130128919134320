import React, {Component} from "react";
import {TextField as MaterialTextField} from "@material-ui/core";
import {TextFieldProps} from "@material-ui/core/TextField";

type onValueChangeCallback = (updatedValue?: string) => void

export interface LabelInputProps {
    value?: string
    onValueChange: onValueChangeCallback,
    label?: string,
    textFieldProps?: TextFieldProps,
    emitChangeOnBlur?: boolean
}

interface TextFieldState {
    value?: string
}

class TextField extends Component<LabelInputProps, TextFieldState> {
    constructor(props: LabelInputProps) {
        super(props);

        const {value} = props;
        this.state = {
            value
        }
    }

    public componentDidUpdate(prevProps: Readonly<LabelInputProps>): void {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
    }

    public render = (): JSX.Element =>
        <MaterialTextField
            key={`label-input`}
            value={this.state.value}
            onChange={event => {
                const value = event.target.value;
                this.setState({value});
                if (this.props.emitChangeOnBlur !== true) {
                    this.props.onValueChange(value)
                }
            }}
            onBlur={this.props.emitChangeOnBlur ? event => this.props.onValueChange(this.state.value) : undefined}
            label={this.props.label}
            margin="dense"
            {...this.props.textFieldProps}
        />;
}

export default TextField