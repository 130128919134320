import Button, {ButtonProps} from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1)
    }
}));

export default ({buttonText, children, ...buttonProps}: {buttonText?: string} & ButtonProps) => {
    if (!children) {
        children = <span>{buttonText || "Dodaj nowy"}</span>
    }
    const classes = useStyles();
    return <Button {...buttonProps} className={classes.button} variant="outlined">{children}</Button>
}
