import React, {Component, CSSProperties} from "react";
import {TemplateGuideLine} from "mesmetric-v2-common/models/ProductTemplate";
import "./guideline.scss";
import {RGBColor} from "react-color";

interface GuidelineProps {
    line: TemplateGuideLine,
    container: React.RefObject<HTMLDivElement>,
    onChange: (line: TemplateGuideLine) => void,
    onRemove: (line: TemplateGuideLine) => void,
    selectedColor: RGBColor
}

interface GuidelineState {
    position: number,
    unit: string,
    startingPoint: number,
    initialPosition: number
}

class Guideline extends Component<GuidelineProps, GuidelineState> {
    private readonly styleAxis: "left" | "top";
    private readonly mouseMoveAxis: "pageY" | "pageX";
    private readonly elementOffset: "offsetTop" | "offsetLeft";
    private readonly lineRef: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly clickOffset: number;

    constructor(props: GuidelineProps) {
        super(props);
        const isHorizontal = this.props.line.type === "horizontal";
        this.styleAxis = isHorizontal ? "top" : "left";
        this.mouseMoveAxis = isHorizontal ? "pageY" : "pageX";
        this.elementOffset = isHorizontal ? "offsetTop" : "offsetLeft";
        this.clickOffset = isHorizontal ? 0 : 1;
        this.state = {
            initialPosition: 0,
            position: this.getPosition(this.props.line.position, true),
            unit: "%",
            startingPoint: 0
        };
    }


    private getPosition = (pos: number, percent: boolean): number => {
        let position = pos;
        const maxSize = percent ? 100 : this.props.container.current?.clientWidth || 0;

        if (position > maxSize) {
            position = maxSize;
        } else if (position < 0) {
            position = 0;
        }
        return position;
    };

    private getStyle = (): CSSProperties => ({
        backgroundColor: `rgb(${this.props.line.color.r},${this.props.line.color.g},${this.props.line.color.b})`,
        [this.styleAxis]: this.state.position + this.state.unit
    });

    private mouseUpListener = () => {
        document.removeEventListener("mouseup", this.mouseUpListener);
        document.removeEventListener("mousemove", this.mouseMoveListener);
        this.props.onChange({
            ...this.props.line,
            position: this.state.position
        })
    };

    private convertToPercent = (value: number) => value / (this.props.container.current?.clientWidth || 0) * 100;

    private mouseMoveListener = (event: MouseEvent): void => {
        this.setState({
            position: this.getPosition(this.convertToPercent(this.state.initialPosition + event[this.mouseMoveAxis] - this.state.startingPoint), true),
            unit: "%"
        })
    };

    private onMouseDown = (event: React.MouseEvent) => {
        event.preventDefault();
        if (event.shiftKey) {
            this.props.onRemove(this.props.line);
            return;
        }
        if (event.ctrlKey) {
            this.props.onChange({
                ...this.props.line,
                color: this.props.selectedColor
            });
            return;
        }
        if (event.button > 1) {
            return;
        }
        this.setState({
            startingPoint: event[this.mouseMoveAxis],
            initialPosition: (this.state.position / 100) * (this.props.container.current?.clientWidth || 0)
        });
        document.addEventListener("mousemove", this.mouseMoveListener);
        document.addEventListener("mouseup", this.mouseUpListener)
    };

    public render = () =>
        <div
            ref={this.lineRef}
            style={this.getStyle()}
            className={`guideline ${this.props.line.type}`}
            onMouseDown={this.onMouseDown}
        />
}

export default Guideline;