import React, {Component} from 'react'
import "./nameFIelds.scss"
import {TextField} from "@material-ui/core";
import {Label} from "mesmetric-v2-common/models/Label";
import _ from "lodash";
import {connect} from "react-redux";
import {AppState} from "../../../../../../../Store";
import {updateValue} from "../../../../../../../ActionCreators/ProductData";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";

interface ExternalProps {
    className: string
    path: string
    onLabelChange: (value: Label) => void
}

interface StateProps {
    className: string
    value: Label
    onLabelChange: (value: Label) => void
}

interface DispatchProps {
    onLabelUpdate: (value: Label) => void
}

class NameFields extends Component<StateProps & DispatchProps> {
    static defaultProps = {
        value: {}
    };

    render = (): JSX.Element => {
        return <div className={this.props.className}>
            <TextField
                className={"name-field top"}
                id="standard-basic"
                label="Nazwa"
                size={"medium"}
                value={this.props.value.pl || ""}
                onChange={(event) =>
                    this.props.onLabelChange(
                        {
                            pl: event.target.value ? event.target.value : "", en: this.props.value.en
                        })}/>
            <TextField
                className={"name-field"}
                id="standard-basic"
                label="Name"
                size={"medium"}
                value={this.props.value.en || ""}
                onChange={(event) =>
                    this.props.onLabelChange(
                        {
                            pl: this.props.value.pl, en: event.target.value ? event.target.value : ""
                        })}/>
        </div>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        value: _.get(state.ProductData.productData, externalProps.path),
        className: externalProps.className,
        onLabelChange: externalProps.onLabelChange
    });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onLabelUpdate: (value: Label) => dispatch(updateValue(externalProps.path, value)),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(NameFields);