import React, {Component} from 'react'
import "./attributeGroup.scss";
import {ProductAttributeGroup} from "mesmetric-v2-common/models/ProductAttributeGroup";
import _ from "lodash";
import AttributeGroupCard from "./Card/AttributeGroupCard";
import {AppState} from "../../../../Store";
import {connect} from "react-redux";
import Attributes from "../Attributes/Attributes";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import Variants from "./Variants/Variants";
import {Dictionary} from "mesmetric-v2-common/models/Dictionary";

interface ExternalProps {
    index: number
    path: string
    attributeGroupProvided: ProductAttributeGroup
}

interface StateProps {
    index: number
    path: string
    attributeGroupProvided: ProductAttributeGroup
    attributeGroup: any
}

interface DispatchProps {
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
}

class AttributeGroup extends Component<StateProps & DispatchProps> {
    static defaultProps = {
        attributeGroup: {}
    };

    render = (): JSX.Element => {
        return <>
            {this.props.attributeGroupProvided?.withVariants ?
                <Variants
                    path={`${this.props.path}.variants`}
                    attributesProvided={this.props.attributeGroupProvided?.attributes}
                    attributeGroupProvided={this.props.attributeGroupProvided}
                    onDictionaryUpdated={this.props.onDictionaryUpdated}/>
                :
                <AttributeGroupCard
                    title={this.props.attributeGroupProvided?.label?.pl}
                    groupId={this.props.attributeGroupProvided?._id}
                    expanded>
                    <Attributes
                        path={this.props.path}
                        attributesProvided={this.props.attributeGroupProvided?.attributes}
                        onDictionaryUpdated={this.props.onDictionaryUpdated}
                    />
                </AttributeGroupCard>}
        </>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        attributeGroup: _.get(state.ProductData.productData, externalProps.path),
        attributeGroupProvided: externalProps.attributeGroupProvided,
        path: externalProps.path,
        index: externalProps.index
    });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onDictionaryUpdated: (attributeIndex: number, value: Dictionary) =>
        dispatch(updateValue(`primaryCategory.template.attributeGroups[${externalProps.index}].attributes[${attributeIndex}].dictionary`, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeGroup);