import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {AppState} from "../../../../../Store";
import {updateValue} from "../../../../../ActionCreators/ProductData";
import {Category} from "mesmetric-v2-common/models/Category";
import Dictionary from "../../../../../DataProviders/Dictionary";

interface ExternalProps {
    path: string,
    label: string
}

interface StateProps {
    value: any,
    categories: any,
    label: string
}

interface DispatchProps {
    onChange: (value: any) => void
    onCategoriesUpdate: (value: any) => void
}

type AutocompleteSelectFieldProps = StateProps & DispatchProps;

type OptionType = {
    [key: string]: any
}

interface AutocompleteSelectFieldState {
    loaded: boolean,
    options: OptionType[]
}

class PrimaryCategory extends Component<AutocompleteSelectFieldProps, AutocompleteSelectFieldState> {
    constructor(props: AutocompleteSelectFieldProps) {
        super(props);

        this.state = {
            loaded: false,
            options: [this.props.value].filter(Boolean).map(this.categoriesOptionMapper)
        };
    }

    private categoriesOptionMapper = (item: Category) => {
        return {
            labelDropdown: item.name?.pl,
            labelPill: item.path?.replace(/-/g, " "),
            selectable: item?.children?.length === 0,
            parentCategory: "inne",
            children: item.children,
            value: item
        };
    };

    private fetchOptions = () =>
        Dictionary.getCollectionData('categories').then((result: Array<any>) => {
            const options = result.map(this.categoriesOptionMapper);

            // complete options with parent category
            options.forEach(option => {
                if (option.children?.length !== 0) {
                    const childrenIds = option.children;
                    childrenIds?.forEach(childId => {
                        const opt = options.find(option => option.value._id === childId.toString());
                        if (opt) {
                            opt.parentCategory = option.value.path
                        }
                    });
                }
            });

            this.setState({
                options: options.filter(option => option.selectable),
                loaded: true
            });

        });

    public render = () => {
        const props = {
            value: this.state.options.find(option => option.value?._id === this.props.value?._id) || {},
            onChange: (event: any, newValue: any) => {
                this.props.onChange(newValue?.value);
                const updatedCategories = ([...this.props.categories] || []).filter(category => category?._id !== this.props.value?._id);
                if (newValue) {
                    updatedCategories.filter(category => category?._id === newValue?.value?._id).length < 1 && updatedCategories.push(newValue?.value);
                }
                this.props.onCategoriesUpdate(updatedCategories)
            }
        };

        return <Autocomplete
            className={"categories"}
            options={this.state.options.sort((a, b) => ('' + a.parentCategory).localeCompare(b.parentCategory))}
            onOpen={() => !this.state.loaded && this.fetchOptions()}
            getOptionLabel={option => option.labelPill || ""}
            noOptionsText={"Brak opcji"}
            groupBy={option => option.parentCategory}
            loading={!this.state.loaded}
            renderOption={option => option.labelDropdown || ""}
            renderInput={(params) =>
                <TextField {...params}
                           label={this.props.label}
                           variant="outlined"
                           size={"small"}
                />
            }
            {...props}
        />
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path),
    categories: _.get(state.ProductData.productData, "categories"),
    label: externalProps.label
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch(updateValue(externalProps.path, value)),
    onCategoriesUpdate: (value: any) => dispatch(updateValue("categories", value))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryCategory)