import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {RouteComponentProps, withRouter} from "react-router";
import styles from "./Brands.module.scss";
import Box from "../ProductView/Common/Box/Box";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import TableCell from "@material-ui/core/TableCell";
import AddItem from "./AddItem/AddItem";
import {addBrand, BackendBrand, deleteBrand, editBrand} from "../../ActionCreators/Brands";
import {AutoSizer, Column as VirtualizedColumn, Table as VirtualizedTable} from 'react-virtualized';
import {TableCellProps} from "react-virtualized/dist/es/Table";
import EditItem from "./EditItem/EditItem";
import {CircularProgress} from "@material-ui/core";
import {ItemData} from "./ItemForm/ItemForm";
import RemoveBrand from "./RemoveBrand/RemoveBrand";

interface StateProps extends RouteComponentProps {
    items: BackendBrand[],
    isSearching: boolean
}

interface DispatchProps {
    addItem: (userData: ItemData) => Promise<string | void>,
    editItem: (id: string, itemData: ItemData) => Promise<string | void>,
    deleteItem: (id: string) => Promise<string | void>
}

type Props = StateProps & DispatchProps;

type DataKey = keyof BackendBrand | "actions";

type Column = { name: string, dataKey: DataKey };

const COLUMNS: Column[] = [
    {name: "Nazwa", dataKey: "name"},
    {name: "Kod", dataKey: "code"},
    {name: "Aktywna", dataKey: "isActive"},
    {name: "Mnożnik", dataKey: "priceMultiplier"},
    {name: "Kolejność", dataKey: "order"},
    {name: "", dataKey: "actions"}];

const ROW_HEIGHT = 65;
const HEADER_HEIGHT = 56;

class Brands extends Component<Props> {

    private getCell = (props: TableCellProps): JSX.Element => {
        const item: BackendBrand = props.rowData;

        const getContent = (key: DataKey): JSX.Element | string => {
            switch (key) {
                case "actions":
                    return <>
                        <EditItem
                            className={styles.editButton}
                            doAction={userData => this.props.editItem(item._id as string, userData)}
                            item={item}
                            otherItems={this.props.items.filter(el => el._id !== item._id)}
                        />
                        {item.isRemovable && <RemoveBrand deleteBrand={() => this.props.deleteItem(item._id)}/>}
                    </>;
                case "isActive":
                    return item.isActive ? "Tak" : "Nie";
                default:
                    return props.cellData
            }
        };

        return <TableCell
            variant={"body"}
            component={"div"}
            className={`${styles.cell} ${props.rowIndex % 2 === 0 ? styles.even : styles.odd} ${props.dataKey === "actions" ? styles.buttons : ""}`}
            style={{height: ROW_HEIGHT}}>
            {getContent(props.dataKey as DataKey)}
        </TableCell>;
    };

    private renderColumn = (column: Column, index: number): JSX.Element =>
        <VirtualizedColumn
            key={index}
            flexGrow={1}
            headerRenderer={(headerProps) =>
                <TableCell
                    variant={"head"}
                    component={"div"}
                    className={styles.cell}
                    style={{height: HEADER_HEIGHT}}>
                    {column.name}
                </TableCell>
            }
            cellRenderer={(props: TableCellProps) => this.getCell(props)}
            dataKey={column.dataKey}
            width={500}
        />;

    public render = (): JSX.Element =>
        <div className={styles.content}>
            <Box title={"Marki"}
                 className={styles.box}
                 headerComponent={<AddItem
                     className={styles.add}
                     otherItems={this.props.items}
                     doAction={this.props.addItem}/>}
            >
                {(this.props.isSearching || this.props.items.length > 6) &&
                <div className={styles.loading}><CircularProgress size={24}/></div>}
                <AutoSizer>
                    {({height, width}) => (
                        <VirtualizedTable
                            height={height}
                            width={width}
                            rowHeight={ROW_HEIGHT!}
                            headerHeight={HEADER_HEIGHT!}
                            rowGetter={({index}) => this.props.items[index]}
                            rowCount={this.props.items.length}
                            className={styles.table}
                            rowClassName={styles.row}
                            headerClassName={styles.header}
                        >
                            {COLUMNS.map(this.renderColumn)}
                        </VirtualizedTable>
                    )}
                </AutoSizer>
            </Box>
        </div>;
}

const mapStateToProps = (state: AppState, externalProps: RouteComponentProps): StateProps => ({
    ...externalProps,
    items: state.Brands.brands,
    isSearching: state.Brands.isSearching,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    addItem: (userData: ItemData) => dispatch(addBrand(userData)),
    editItem: (id: string, userData: ItemData) => dispatch(editBrand(id, userData)),
    deleteItem: (id: string) => dispatch(deleteBrand(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Brands));