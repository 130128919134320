import React, {Component, RefObject} from "react"
import {makeStyles} from "@material-ui/core";
import * as models from "mesmetric-v2-common/models"
import {ConnectedCategoryTree, getIdsOfSelectedCategoriesWithoutChildren} from "../Components/CategoryTree";
import {ConnectedProductsList} from "./ProductListing/ProductsList";
import {connect} from "react-redux";
import {AppState} from "../Store";
import {RouteComponentProps, withRouter} from "react-router";
import {UpdateFilterField} from "../ActionCreators/ProductFilter";
import "./productListing.scss";
import AbsoluteSpinner from "../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import Button from "@material-ui/core/Button";
import PaddedPaper from "../Components/PaddedPaper/PaddedPaper";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    loadingBox: {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #eee',
        padding: theme.spacing(1),
        '& > .MuiCircularProgress-root': {
            marginLeft: theme.spacing(1)
        }
    },
}));

interface ProductListingProps extends RouteComponentProps {
    isSearching: boolean
}

interface ProductListingState {
    categoriesOpened: boolean
}

export const EDIT_PRODUCT_SUB_PATH = "/products/edit";

class ProductListing extends Component<ProductListingProps, ProductListingState> {
    private readonly categoryTree: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: ProductListingProps) {
        super(props);
        this.state = {
            categoriesOpened: false
        }
    }

    public componentDidMount(): void {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    private handleClickOutside = (event: MouseEvent): void => {
        if (this.state.categoriesOpened) {
            const notClickedInsideCategories = (this.categoryTree.current && !this.categoryTree.current.contains(event.target as HTMLElement));
            if (notClickedInsideCategories) {
                this.setState({categoriesOpened: false})
            }
        }
    };


    private onRowClick = (row: models.Product) => this.props.history.push(`${EDIT_PRODUCT_SUB_PATH}/${row._id}`);

    private onProductAdded = (id: string) => this.props.history.push(`${EDIT_PRODUCT_SUB_PATH}/${id}`);

    render = (): JSX.Element =>
        <PaddedPaper className={"main-content"}>
            <div className={"product-listing"}>
                <div className={`product-categories ${this.state.categoriesOpened ? "opened" : "closed"}`}
                     ref={this.categoryTree}>
                    <Button
                        size="small"
                        onClick={() => this.setState({categoriesOpened: !this.state.categoriesOpened})}
                        variant="contained">
                        <span>{this.state.categoriesOpened ? "Ukryj kategorie" : "Pokaż kategorie"}</span>
                    </Button>
                    <FilterCategoryTree/>
                </div>

                <div className={"product-list"}>
                    <ConnectedProductsList rowOnClick={this.onRowClick} onProductAdded={this.onProductAdded}/>
                </div>
                {this.props.isSearching && <AbsoluteSpinner label={"Wczytuję produkty"}/>}
            </div>
        </PaddedPaper>;
}


export const FilterCategoryTree = connect((state: AppState) => ({
    defaultSelected: state.ProductFilter.categories
}), dispatch => ({
    onCategoriesSelected: (categories: any) => dispatch(UpdateFilterField('categories', getIdsOfSelectedCategoriesWithoutChildren(categories)))
}))(ConnectedCategoryTree)

const mapStateToProps = (state: AppState) => ({
    isSearching: state.ProductsList.isSearching
})

export const ConnectedProductListing = withRouter(connect(mapStateToProps)(ProductListing));
export default ProductListing