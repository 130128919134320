import AsyncSelect from "react-select/async";
import axios from "axios";
import React, {Fragment, useState} from "react";
import {makeStyles, Typography} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DeleteIcon from '@material-ui/icons/Delete';

import IconButton from "@material-ui/core/IconButton";
import DragAndDropArray from "../../Helpers/DragAndDropArray";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";
import {emphasize} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import update from "immutability-helper"
import {getCroppedSrc} from "../ProductCard/Photos";
import PaddedPaper from "../PaddedPaper/PaddedPaper";
import {getAxiosConfig} from "../../ActionCreators/User";
import {parseError} from "../../ActionCreators/Error";

const ProductItem = ({product, onDelete}) => {
    return (
        <Fragment>
            <PaddedPaper>
                {product.photos.length > 0 ? <img alt=""
                                                  src={getCroppedSrc(product.photos[0], {w: 100})}/> : 'Brak zdjęcia'}

            </PaddedPaper>
            <div>{product.name} / {product.primaryCategory.name.pl} / {product.code}</div>
            <IconButton variant="contained" onClick={() => {
                onDelete();
            }}>
                <DeleteIcon/>
            </IconButton>
        </Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

const Control = (props) => {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps},
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}


const RelatedProducts = React.memo((props) => {
    const [value, setValue] = useState(null);
    const classes = useStyles();
    const existingIds = props.relatedProducts.map(product => product._id);
    existingIds.push(props.currentId);
    return (<div>
        <Typography>Produkty powiązane</Typography>
        <AsyncSelect
            classes={classes}
            components={{
                Control, DropdownIndicator: () => null,
                IndicatorSeparator: () => null
            }}
            placeholder="Zacznij wpisywać nazwę lub kod produktu"
            noOptionsMessage={input => {
                return input.inputValue === '' ? 'Wpisz coś' : 'Brak wyników'
            }}
            loadingMessage={({inputValue}) => {
                return 'Szukam...';
            }}
            value={value}
            loadOptions={async (searchTerm) => {
                const searchParams = {
                    params: {
                        condition: {
                            $or: [
                                {
                                    name: {
                                        $regex: '\\b' + searchTerm
                                    }
                                },
                                {
                                    code: {
                                        $regex: '^' + searchTerm
                                    }
                                }
                            ],
                            _id: {
                                $nin: existingIds
                            }
                        }
                    }
                };
                return axios.get(process.env.REACT_APP_DATA_ENDPOINT + '/products/searchOld/', searchParams, getAxiosConfig())
                    .then(({data: result}) => {
                        return result.products
                            .map(item => ({
                                label:
                                    <div>{item.name} / {item.primaryCategory !== null ? item.primaryCategory.name.pl : '-'} / {item.code} </div>,
                                value: item
                            }));
                    }).catch(parseError);
            }}
            onChange={(newValue) => {
                setValue(null);
                props.onChange(update(props.relatedProducts, {
                    $push: [newValue.value]
                }));
            }}
        />
        <Divider/>
        {props.relatedProducts.length > 0 ?
            <DragDropContext onDragEnd={function (result) {
                if (!result.destination) {
                    return;
                }
                DragAndDropArray.moveItem(props.relatedProducts, result.source.index, result.destination.index, props.onChange);
            }}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <List ref={provided.innerRef}>
                            {props.relatedProducts.map((product, index) =>
                                <Draggable key={product._id} draggableId={product._id} index={index}>
                                    {(provided, snapshot) => (
                                        <ListItem disableGutters
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                        ><ProductItem product={product} onDelete={() => {
                                            DragAndDropArray.removeItem(props.relatedProducts, product, props.onChange);
                                        }}/></ListItem>
                                    )}
                                </Draggable>
                            )}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
            :
            <Typography variant="body1">Brak produktów powiązanych</Typography>
        }
    </div>)
});

export default RelatedProducts