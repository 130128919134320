import React from "react";
import * as models from "mesmetric-v2-common/models";
import {Product} from "mesmetric-v2-common/models";
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {FilterControls} from "./FilterControls";
import ProductActions from "../ProductCard/ProductActions/ProductActions";
import {getCroppedSrc} from "../../Utils/PhotoUtils";

const PhotoGrid = withStyles({
    root: {
        cursor: 'pointer',
        '& > div.info': {
            display: 'flex',
            alignItems: 'center',
            '& > div.label': {
                '& > .name':
                    {
                        fontFamily: 'Jenson',
                        fontSize: '2em',
                        marginBottom: '-0.25em'
                    },
                '& > .brand':
                    {
                        fontSize: '1em',
                        textTransform: 'uppercase',
                        marginBottom: '0.5em'
                    }
            },
            '& > div.buttons': {
                marginLeft: 'auto',
            }
        },
        '& > div.rectangle': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            '& > .overlay': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#000',
                zIndex: 1,
                opacity: 0.05
            },
            position: 'relative',
            width: '100%',
            paddingBottom: '100%',
            '& > img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                transform: 'translateY(-50%)',
                top: '50%',
                position: 'absolute'
            }
        }
    }
})(Grid);

export const ProductsGrid = (props: {
    productsList: Product[]
    rowOnClick?: (row: models.Product) => void,
    scrollableRef: any
}) => {
    return (
        <Grid container spacing={2}>
            <FilterControls/>
            <Grid container spacing={2} className={"photo-grid"} ref={props.scrollableRef}>
                {props.productsList.map(product => {
                    return (
                        <PhotoGrid sm={6} xs={12} md={4} xl={4} key={product._id} item onClick={() => {
                            props.rowOnClick && props.rowOnClick(product);
                        }}>
                            <div className={'rectangle'}>
                                {/*<div className={'overlay'}/>*/}
                                {product.photos.length > 0 ?
                                    <img src={getCroppedSrc(product.photos[0], {w: 429})}/> : '-'}
                            </div>
                            <div className={'info'}>
                                <div className={'label'}>
                                    <div className={'name'}>{product.name}</div>
                                    <div className={'brand'}>{product.brand ? product.brand.name : ''}</div>
                                </div>
                                <div className={'buttons'}
                                     onClick={(event) => {
                                         event.stopPropagation()
                                     }}>
                                    <ProductActions productId={product._id} displayIcon/>
                                </div>
                            </div>
                        </PhotoGrid>
                    )
                })}</Grid>
        </Grid>
    )
}