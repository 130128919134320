import {FAKE_PRODUCT, ViewType} from "../ActionCreators/Products";
import {Product} from "mesmetric-v2-common/models";
import {LocalStorageKey} from "../Utils/StorageUtils";
import {ProductsFilters} from "mesmetric-v2-common/models/ProductsFilters";


export interface ProductsState {
    viewType: ViewType,
    isSearching: boolean,
    products: Product[],
    hiddenColumns: (keyof ProductsFilters)[],
    count?: number
}

export const initialState: ProductsState = {
    viewType: localStorage.getItem(LocalStorageKey.VIEW_TYPE) as ViewType || "TABLE",
    isSearching: false,
    // @ts-ignore
    products: [FAKE_PRODUCT],
    hiddenColumns: JSON.parse(localStorage.getItem(LocalStorageKey.HIDDEN_COLUMNS) || "[]")
};