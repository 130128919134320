import React, {useEffect, useState} from "react";
import {makeStyles, Modal, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import MesmetricTable from "../Components/MesmetricTable";
import PaddedPaper from "../Components/PaddedPaper/PaddedPaper";
import {getAxiosConfig} from "../ActionCreators/User";
import {parseError} from "../ActionCreators/Error";

const CreateNew = (props) => {
    return <Button className={props.classes.button} onClick={props.onClick} variant="outlined">Dodaj nowy</Button>
}
const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2),
        outline: 'none',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    button: {
        marginTop: theme.spacing(1)
    }
}));

const tableColumns = [
    {
        name: 'name',
        accessPath: 'label',
        label: 'Nazwa słownika'
    }
]


const Dictionaries = () => {
    const classes = useStyles();
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [newDictionaryName, setNewDictionaryName] = useState('')
    const [dictionaries, setDictionaries] = useState([])
    const [canCreate, setCanCreate] = useState(true)
    const fetchDictionaries = () => {
        axios.get(process.env.REACT_APP_DATA_ENDPOINT + '/dictionaries', getAxiosConfig())
            .then(res => setDictionaries(res.data)).catch(parseError)
    };

    useEffect(() => {
        fetchDictionaries()
    }, []);
    const addNewItem = (name) => {
        return axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/dictionaries', {
            name
        }, getAxiosConfig());

    }
    return <PaddedPaper>
        <Typography>Słowniki</Typography>
        <Modal open={modalIsOpen} onClose={() => {
            setNewDictionaryName('')
            setModalIsOpen(false)
        }}>
            <div className={classes.paper}>
                <TextField label="Nazwa słownika" fullWidth={true} value={newDictionaryName}
                           onChange={event => {
                               const newName = event.target.value;
                               setCanCreate(!dictionaries.find(dictionary => {
                                   return dictionary.name === newName
                               }))
                               setNewDictionaryName(newName)
                           }}/>
                <Button disabled={!canCreate} className={classes.button} variant="outlined" onClick={() => {
                    addNewItem(newDictionaryName)
                        .then(() => {
                            setModalIsOpen(false);
                            fetchDictionaries();
                        }).catch(parseError)
                }}>Dodaj</Button>
            </div>
        </Modal>
        {dictionaries.length ?
            <MesmetricTable columns={tableColumns} rows={dictionaries}
                            incompleteCheck={row => row.items?.some(item => !item.label.en)}/>
            :
            <Typography>Mima</Typography>
        }
        <CreateNew
            classes={classes}
            onClick={() => {
                setModalIsOpen(!modalIsOpen)
            }}
        />
    </PaddedPaper>
}
export default Dictionaries