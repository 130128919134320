import {Product} from "mesmetric-v2-common/models";

export enum ActionTypes {
    setScroll = "setScroll",
    setFilter = "setFilter",
    updateFilter = "updateFilter",
    setIsSearching = "setIsSearching",
    updateProductInList = "updateProductInList",
    replaceProducts = "replaceProducts",
    setProductsAt = "setProductsAt"
}

export interface SetScrollAction {
    type: ActionTypes.setScroll
    data: number
}

export interface ReplaceProductsAction {
    type: ActionTypes.replaceProducts,
    data: Product[]
}

export interface SetProductsAtAction {
    type: ActionTypes.setProductsAt,
    data: Product[],
    at: number
}

export interface UpdateFilterAction {
    type: ActionTypes.updateFilter,
    updateSpec: any
}

export interface SetIsSearchingAction {
    type: ActionTypes.setIsSearching,
    data: boolean
}


export interface UpdateProductInListAction {
    type: ActionTypes.updateProductInList,
    product: Product
}

export function setScroll(newScroll: number): SetScrollAction {
    return {
        type: ActionTypes.setScroll,
        data: newScroll
    }
}

export function replaceProducts(products: Product[], startAt: number = 0): ReplaceProductsAction {
    return {
        type: ActionTypes.replaceProducts,
        data: products
    }
}


export function setProductsAt(products: Product[], at: number = 0): SetProductsAtAction {
    return {
        type: ActionTypes.setProductsAt,
        data: products,
        at: at
    }
}

export function updateFilter(updateSpec: any): UpdateFilterAction {
    return {
        type: ActionTypes.updateFilter,
        updateSpec: updateSpec
    }
}

export function setIsSearching(isSearching: boolean): SetIsSearchingAction {
    return {
        type: ActionTypes.setIsSearching,
        data: isSearching
    }
}

export function updateProduct(product: Product): UpdateProductInListAction {
    return {
        type: ActionTypes.updateProductInList,
        product: product
    }
}

export type AllActions =
    SetScrollAction
    | ReplaceProductsAction
    | UpdateProductInListAction
    | SetIsSearchingAction
    | SetProductsAtAction
    | UpdateFilterAction;