import React, {Component} from 'react'
import "./productTemplateAttributes.scss";
import AttributeGroupCard from "./AttributeGroup/Card/AttributeGroupCard";
import {AppState} from "../../../Store";
import _ from "lodash";
import {connect} from "react-redux";
import {ProductAttributeGroup} from "mesmetric-v2-common/models/ProductAttributeGroup";
import AttributeGroup from "./AttributeGroup/AttributeGroup";
import LegacyData from "../../ProductCard/LegacyData";

interface ExternalProps {
    path: string,
    visible: boolean
}

interface StateProps {
    attributeGroupsProvided: ProductAttributeGroup[]
    legacyData: any
    path: string,
    visible: boolean
}

class ProductTemplateAttributes extends Component<StateProps> {
    static defaultProps = {
        attributeGroupsProvided: [],
        legacyData: {}
    };

    private splitArray = (arr: ProductAttributeGroup[]): any => {
        const result = [[], [], []];

        for (let i = 0; i < arr.length; i++) {
            // @ts-ignore
            result[i % 3].push(arr[i]);
        }
        return result;
    };

    private getAttributeGroupsColumn = (attributeGroupsProvided: ProductAttributeGroup[], index: number): JSX.Element => {
        return <div className={"col-4"} key={index}>
            {attributeGroupsProvided.map((providedAttributeGroup: ProductAttributeGroup) => {
                    const indexOfGroup = this.props.attributeGroupsProvided.indexOf(providedAttributeGroup);
                    return <AttributeGroup
                        key={indexOfGroup}
                        index={indexOfGroup}
                        attributeGroupProvided={providedAttributeGroup}
                        path={`${this.props.path}.${providedAttributeGroup._id}`}/>
                }
            )}
        </div>
    };

    render = (): JSX.Element => {
        const chunkedProvidedAttributeGroups = this.splitArray(this.props.attributeGroupsProvided);
        return <div className={"product-template-attributes"} hidden={!this.props.visible}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <AttributeGroupCard
                        title={"Stara baza"}
                        expanded>
                        <LegacyData
                            colors={this.props.legacyData?.colors}
                            materials={this.props.legacyData?.materials}
                            sizes={this.props.legacyData?.sizes}/>
                    </AttributeGroupCard>
                </div>
            </div>
            <div className={"row"}>
                {chunkedProvidedAttributeGroups.map((chunk: ProductAttributeGroup[], index: number) => this.getAttributeGroupsColumn(chunk, index))}
            </div>
        </div>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        legacyData: _.get(state.ProductData.productData, "legacyData"),
        attributeGroupsProvided: _.get(state.ProductData.productData, "primaryCategory.template.attributeGroups"),
        path: externalProps.path,
        visible: externalProps.visible
    });
};

export default connect(mapStateToProps, {})(ProductTemplateAttributes);