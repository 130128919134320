import React, {Component} from 'react'
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import "./attributeSize.scss"
import {ProductAttribute} from "mesmetric-v2-common/models/ProductAttribute";
import {AppState} from "../../../../Store";
import _ from "lodash";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue} from "../../../../ActionCreators/ProductData";

interface ExternalProps {
    index: number
    path: string
    providedAttribute: ProductAttribute.Size
}

interface StateProps {
    index: number
    providedAttribute: ProductAttribute.Size
    productAttribute: string
}

interface DispatchProps {
    onAttributeUpdate: (value: string) => void
}

class AttributeSize extends Component<StateProps & DispatchProps> {
    static defaultProps = {
        productAttribute: ""
    };

    private onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): any => {
        this.props.onAttributeUpdate(event.target.value ? event.target.value : "")
    };

    render = (): JSX.Element => {
        return <div className={"attribute-size"}>
            <TextField
                className={"field"}
                label={this.props.providedAttribute?.label?.pl}
                size={"small"}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{this.props.providedAttribute?.unit}</InputAdornment>,
                }}
                variant="outlined"
                value={this.props.productAttribute || ""}
                onChange={this.onChange}/>
        </div>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        productAttribute: _.get(state.ProductData.productData, externalProps.path, ""),
        index: externalProps.index,
        providedAttribute: externalProps.providedAttribute
    });
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onAttributeUpdate: (value: string) => dispatch(updateValue(externalProps.path, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AttributeSize);