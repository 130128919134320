import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../Store";
import {RouteComponentProps, withRouter} from "react-router";
import styles from "./Products.module.scss";
import Box from "../ProductView/Common/Box/Box";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {AutoSizer, Grid, MultiGrid, ScrollEventData} from 'react-virtualized';
import {CircularProgress} from "@material-ui/core";
import {ItemData} from "./ItemForm/ItemForm";
import {addProduct, FAKE_PRODUCT, fetchMoreProducts, LIMIT, ViewType} from "../../ActionCreators/Products";
import {Product} from "mesmetric-v2-common/models/Product";
import {Column, COLUMNS} from "./Columns";
import HeaderCell from "./Cells/HeaderCell";
import ContentCell from "./Cells/ContentCell";
import GridCell from "./Cells/GridCell";
import Tools from "./Tools/Tools";
import {LocalStorageKey} from "../../Utils/StorageUtils";
import {ProductsFilters} from "mesmetric-v2-common/models/ProductsFilters";

interface StateProps extends RouteComponentProps {
    items: Product[],
    isSearching: boolean,
    viewType: ViewType,
    hiddenColumns: (keyof ProductsFilters)[]
}

interface DispatchProps {
    addItem: (userData: ItemData) => Promise<string | void>,
    fetchMoreProducts: (startIndex: number, endIndex: number) => void
}

type Props = StateProps & DispatchProps;

interface State {
    showCategories: boolean,
    scrollTop?: number
}

export const ROW_HEIGHT = 138;
export const HEADER_HEIGHT = 65;
let GRID_ROW_HEIGHT = 0;

export const EDIT_PRODUCT_SUB_PATH = "/products/edit";

class Products extends Component<Props, State> {
    private virtualizedRef = React.createRef<MultiGrid>();
    private readonly tempSaveIds: string[];

    constructor(props: Props) {
        super(props);
        this.tempSaveIds = Object.keys(localStorage).filter(key => key.startsWith("product-")).map(key => localStorage.getItem(key)).map(item => item && JSON.parse(item)).map(product => product._id);
        this.state = {
            showCategories: false,
            scrollTop: parseInt(localStorage.getItem(LocalStorageKey.PRODUCTS_SCROLL_TOP) || "0")
        }
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.items.some(item => item._id === FAKE_PRODUCT._id) && this.props.items.length && this.props.items[0]._id !== FAKE_PRODUCT._id) {
            this.fetchMoreProductsIfNeeded(parseInt(localStorage.getItem(LocalStorageKey.PRODUCTS_SCROLL_TOP) || "0"));
        }
        if (prevProps.viewType !== this.props.viewType) {
            localStorage.removeItem(LocalStorageKey.PRODUCTS_SCROLL_TOP);
        } else if (prevProps.hiddenColumns !== this.props.hiddenColumns) {
            this.virtualizedRef.current?.recomputeGridSize();
        }
    }

    private addItem = async (userData: ItemData): Promise<string | void> => {
        const id = await this.props.addItem(userData);
        id && this.goToEdit(id);
    };

    private onRowClick = (index: number): void => {
        const itemId = this.props.items[index]._id;
        localStorage.setItem(LocalStorageKey.PRODUCTS_LAST_OPENED_ID, itemId);
        this.goToEdit(itemId);
    };

    private goToEdit = (id: string) => this.props.history.push(`${EDIT_PRODUCT_SUB_PATH}/${id}`);

    private getColumns = (): Column[] => COLUMNS.filter(column => !this.props.hiddenColumns.includes(column.field));

    private fetchMoreProductsIfNeeded = (scrollTop: number) => {
        if (this.props.items.length > 250) {
            if (this.props.viewType === "TABLE") {
                const currentIndex = Math.floor(scrollTop / ROW_HEIGHT);
                const packageNumber = Math.floor(currentIndex / LIMIT);
                const startIndexValue = packageNumber * LIMIT - LIMIT;
                const endIndexValue = packageNumber * LIMIT + LIMIT * 2;
                this.props.fetchMoreProducts(startIndexValue < 0 ? 0 : startIndexValue, endIndexValue > this.props.items.length ? this.props.items.length : endIndexValue);
            } else {
                const currentIndex = Math.floor(scrollTop / GRID_ROW_HEIGHT) * 3;
                const packageNumber = Math.floor(currentIndex / LIMIT);
                const startIndexValue = packageNumber * LIMIT - LIMIT;
                const endIndexValue = packageNumber * LIMIT + LIMIT * 2;
                this.props.fetchMoreProducts(startIndexValue < 0 ? 0 : startIndexValue, endIndexValue > this.props.items.length ? this.props.items.length : endIndexValue);
            }
        }
    };

    private handleScroll = (scrollTop: number) => {
        this.fetchMoreProductsIfNeeded(scrollTop);
        this.state.scrollTop !== undefined && this.setState({scrollTop: undefined});
        localStorage.setItem(LocalStorageKey.PRODUCTS_SCROLL_TOP, scrollTop.toString());
    };

    private renderGrid = (height: number, width: number) => {
        let items = this.props.items;
        if (items.length === 1 && items[0]._id === FAKE_PRODUCT._id) {
            items = [];
        }
        GRID_ROW_HEIGHT = width / 3 - 10 + 55;
        return <Grid
            cellRenderer={props => {
                return <GridCell
                    {...props}
                    lastOpenedId={localStorage.getItem(LocalStorageKey.PRODUCTS_LAST_OPENED_ID) || undefined}
                    onClick={this.onRowClick}
                    tempSaveIds={this.tempSaveIds}
                    columnWidth={width / 3 - 10}
                    items={items}
                />
            }
            }
            rowCount={Math.ceil(items.length / 3)}
            rowHeight={GRID_ROW_HEIGHT}
            width={width}
            height={height}
            onScroll={(data: ScrollEventData) => this.handleScroll(data.scrollTop)}
            scrollTop={this.state.scrollTop}
            columnCount={3}
            columnWidth={width / 3 - 10}/>
    };

    private renderTable = (height: number, width: number, columns: Column[]) =>
        <MultiGrid
            ref={this.virtualizedRef}
            rowHeight={(params) => params.index === 0 ? HEADER_HEIGHT : ROW_HEIGHT}
            fixedColumnCount={COLUMNS.filter(column => !column.canBeHidden).length}
            width={width}
            height={height}
            rowCount={this.props.items.length + 1}
            columnCount={columns.length}
            className={styles.grid}
            scrollTop={this.state.scrollTop}
            classNameBottomLeftGrid={styles.leftSide}
            onScroll={(data: ScrollEventData) => this.handleScroll(data.scrollTop)}
            enableFixedColumnScroll
            estimatedRowSize={ROW_HEIGHT}
            columnWidth={(params) => columns[params.index].width}
            fixedRowCount={1}
            cellRenderer={props =>
                props.style.height === HEADER_HEIGHT ?
                    <HeaderCell
                        {...props}
                        columns={columns}
                    /> :
                    <ContentCell
                        {...props}
                        lastOpenedId={localStorage.getItem(LocalStorageKey.PRODUCTS_LAST_OPENED_ID) || undefined}
                        onClick={this.onRowClick}
                        columns={columns}
                        tempSaveIds={this.tempSaveIds}
                        items={this.props.items}
                    />
            }
        />;

    public render = (): JSX.Element => {
        return <div className={styles.content}>
            <Box title={"Produkty"}
                 className={styles.box}
                 headerComponent={<Tools addItem={this.addItem}/>}
            >
                {(this.props.isSearching || this.props.items.length > 6) &&
                <div className={styles.loading}><CircularProgress size={24}/></div>}
                <AutoSizer>
                    {({height, width}) => (
                        this.props.viewType === "TABLE" ?
                            this.renderTable(height, width, this.getColumns()) :
                            this.renderGrid(height, width)
                    )}
                </AutoSizer>
            </Box>
        </div>;
    }

}

const mapStateToProps = (state: AppState, externalProps: RouteComponentProps): StateProps => ({
    ...externalProps,
    items: state.Products.products,
    isSearching: state.Products.isSearching,
    viewType: state.Products.viewType,
    hiddenColumns: state.Products.hiddenColumns
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    addItem: (data: ItemData) => dispatch(addProduct(data)),
    fetchMoreProducts: (startIndex, endIndex) => dispatch(fetchMoreProducts(startIndex, endIndex))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products));