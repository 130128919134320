import React, {Component, Dispatch} from "react";
import * as models from "mesmetric-v2-common/models";
import axios from "axios";
import {Toc, ViewComfy} from "@material-ui/icons";
import {ProductsGrid} from "./ProductsGrid";
import {IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {AllActions, setScroll} from "../../ActionCreators/ProductsList";
import {AppState} from "../../Store";
import {allProductFilters} from "./Filter";
import {ProductFilterState} from "../../State/ProductFilter";
import ProductsTable from "./ProductsTable";
import FilterButton from "./FilterButton";
import AddProductModal from "./AddProductModal/AddProductModal";
import {getAxiosConfig} from "../../ActionCreators/User";

export const searchProducts = (filter: ProductFilterState, skip?: number, limit?: number) => {
    let searchCondition: any = {};
    (Object.keys(filter) as Array<keyof ProductFilterState>).forEach(key => {
        const filterValue = filter[key] as any;
        if (filterValue === null || filterValue === "") {
            return;
        }
        searchCondition = Object.assign(searchCondition, (allProductFilters[key].getCondition as (value: any) => object | undefined)(filterValue));
    });
    return axios.get(process.env.REACT_APP_DATA_ENDPOINT + '/products/searchOld/', {
        ...getAxiosConfig(),
        params: {
            condition: searchCondition,
            skip: skip,
            limit: limit
        }
    })
        .then(res => {
            return res.data
        });
};


enum viewType {
    table = "table",
    grid = "grid"
}

const ViewType: React.FC<{ currentViewType: viewType, onChange: Dispatch<viewType> }> = (props) => {
    return (
        <div className={"view-type"}>
            <Typography variant={"body1"}>Typ widoku</Typography>
            <IconButton disabled={props.currentViewType === viewType.table}
                        onClick={() => props.onChange(viewType.table)}><Toc/></IconButton>
            <IconButton disabled={props.currentViewType === viewType.grid}
                        onClick={() => props.onChange(viewType.grid)}><ViewComfy/></IconButton>
        </div>
    )
}

interface ProductsListProps {
    rowOnClick: (row: models.Product) => void
    onProductAdded: (id: string) => void
    scrollY: number
    productsList: models.Product[]
    setScroll: (newScroll: number) => void
}

interface ProductsListState {
    listViewType: viewType
}

class ProductsList extends Component<ProductsListProps, ProductsListState> {
    private scrollableList = React.createRef<HTMLDivElement>();

    constructor(props: ProductsListProps) {
        super(props);
        this.state = {
            listViewType: (localStorage.getItem('viewType') || viewType.table) as viewType
        }
    }

    public componentDidMount(): void {
        if (this.scrollableList.current) {
            this.scrollableList.current.scrollTop = this.props.scrollY;
        }
    }

    private onRowClick = (row: models.Product) => {
        this.props.setScroll(this.scrollableList.current?.scrollTop || 0);
        this.props.rowOnClick(row);
    };

    public render = (): JSX.Element => {
        return <>
            <ViewType currentViewType={this.state.listViewType} onChange={value => {
                this.setState({
                    listViewType: value
                }, () => {
                    if (this.scrollableList.current) {
                        this.scrollableList.current.scrollTop = 0
                    }
                });
            }}/>
            <FilterButton/>
            <AddProductModal onProductAdded={this.props.onProductAdded}/>
            {this.state.listViewType === viewType.table ?
                <div style={{width: '100%', position: 'relative', overflowX: 'auto'}} ref={this.scrollableList}>
                    <ProductsTable productsList={this.props.productsList} rowOnClick={this.onRowClick}/>
                </div> :
                <ProductsGrid productsList={this.props.productsList} rowOnClick={this.onRowClick}
                              scrollableRef={this.scrollableList}/>
            }
        </>
    }
}

const mapStateToProps = (state: AppState) => ({
    scrollY: state.ProductsList.scrollY,
    productsList: state.ProductsList.products
});

const mapDispatchToProps = (dispatch: Dispatch<AllActions>) => ({
    setScroll: (newScroll: number) => dispatch(setScroll(newScroll))
});

export const ConnectedProductsList = connect(mapStateToProps, mapDispatchToProps)(ProductsList);