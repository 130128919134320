import React, {Component} from "react";
import {InputAdornment, TextField as MaterialTextField} from "@material-ui/core";
import {AppState} from "../../../../Store";
import _ from "lodash";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {getCalculatedPrice, updateValue} from "../../../../ActionCreators/ProductData";
import {connect} from "react-redux";

interface ExternalProps {
    path: string
}

interface StateProps {
    value: number,
    price: number,
    currencyValue: number,
    multiplier: number,
    brandMultiplier: number,
    primaryCategory: string
}

interface DispatchProps {
    onChange: (value: any) => void
}

type Props = StateProps & DispatchProps

class CalculatedPrice extends Component<Props> {
    componentDidUpdate(prevProps: Readonly<StateProps & DispatchProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props !== prevProps) {
            this.props.onChange(getCalculatedPrice(this.props.primaryCategory, this.props.brandMultiplier, this.props.price, this.props.currencyValue, this.props.multiplier));
        }
    }

    public render = (): JSX.Element =>
        <MaterialTextField
            id="outlined-basic"
            label={"Cena po przeliczeniu"}
            variant="outlined"
            disabled
            value={this.props.value}
            size={"small"}
            InputProps={{
                endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
            }}/>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path),
    price: _.get(state.ProductData.productData, "price.value", 0),
    currencyValue: _.get(state.ProductData.productData, "price.currency.rate", 0),
    multiplier: _.get(state.ProductData.productData, "price.type.multiplier", 1),
    brandMultiplier: _.get(state.ProductData.productData, "brand.priceMultiplier", 1),
    primaryCategory: _.get(state.ProductData.productData, "primaryCategory.name.pl", ""),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>) => ({
    onChange: (value: any) => dispatch(updateValue("price.calculated", value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculatedPrice)