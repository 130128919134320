import React from "react";
import "./productActions.scss"
import CloneProductPopper from "../../../Components/Poppers/CloneProductPopper/CloneProductPopper";
import {Product} from "mesmetric-v2-common/models/Product";
import DeleteProductPopper from "../../../Components/Poppers/DeleteProductPopper/DeleteProductPopper";

const ProductActions = (props: {
    productId: Product["_id"],
    displayIcon?: boolean
}) => {
    return (
        <div className={"action-buttons"}>
            <CloneProductPopper
                productId={props.productId}
                displayIcon={props.displayIcon}
            />
            <DeleteProductPopper
                productId={props.productId}
            />
        </div>
    );

};

export default ProductActions;