import {AllProductFilters, FilterDefinition} from "../Views/ProductListing/Filter";

type filterState<T> = {
    [prop in keyof T]: T[prop] extends FilterDefinition<infer ValueType> ? (ValueType | null) : never
}
export type ProductFilterState = filterState<AllProductFilters>

export const initialState: ProductFilterState = {
    brand: null,
    isActive: true,
    code: null,
    name: null,
    categories: null,
    // @ts-ignore
    photos: "Dowolne",
    hasBrochure: null,
    has2D: null,
    hasColour: null,
    hasMaterial: null,
    hasSize: null,
    has3D: null,
    hasStyles: null,
    hasValues: null,
    englishDescription: null,
    photoCount: null
}

