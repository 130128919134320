import React, {Component} from "react";
import {Clear, List, Search} from "@material-ui/icons";
import {IconButton, Popover} from "@material-ui/core";
import {AppState} from "../../../Store";
import {connect} from "react-redux";
import styles from "./Tools.module.scss";
import {FAKE_PRODUCT, searchProducts} from "../../../ActionCreators/Products";
import Categories from "../Categories/Categories";
import {clearCategories} from "../../../ActionCreators/Categories";
import {polishPlural} from "../../../Utils/DataUtils";

interface StateProps {
    categoriesCount: number,
    itemsCount?: number
}

interface DispatchProps {
    searchProducts: () => void,
    clearCategories: () => void
}

type Props = StateProps & DispatchProps;

interface State {
    anchor?: HTMLButtonElement
}

class FilterControls extends Component<Props, State> {
    public state: State = {};

    public render = (): JSX.Element => <>
        <div className={!!this.props.categoriesCount ? styles.categories : undefined}>
            <IconButton
                className={styles.button}
                color={this.state.anchor ? "primary" : undefined}
                onClick={event => this.setState({anchor: this.state.anchor ? undefined : event.currentTarget})}>
                <List/>
                {!!this.props.categoriesCount &&
                <div className={styles.buttonCounter}>{this.props.categoriesCount}</div>}
            </IconButton>
            {!!this.props.categoriesCount &&
            <IconButton
                className={styles.button}
                onClick={() => this.props.clearCategories()}>
                <Clear/>
            </IconButton>}
        </div>
        {!!this.state.anchor &&
        <Popover
            open
            anchorEl={this.state.anchor}
            onClose={() => this.setState({anchor: undefined})}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className={styles.categoriesPopup}>
                <span className={styles.header}>Kategorie</span>
                <Categories/>
            </div>

        </Popover>}
        <IconButton
            className={`${styles.button} ${styles.search}`}
            onClick={() => {
                this.setState({anchor: undefined});
                this.props.searchProducts()
            }}
        >
            <Search/>
        </IconButton>
        {this.props.itemsCount !== undefined && <span
            className={styles.counter}>{this.props.itemsCount} {polishPlural("produkt", "produkty", "produktów", this.props.itemsCount)}</span>}
    </>
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        categoriesCount: state.ProductsFilters.categories?.length || 0,
        itemsCount: state.Products.count
    }
};

const mapDispatchToProps: DispatchProps = ({
    searchProducts,
    clearCategories
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterControls);