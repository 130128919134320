import {Typography} from "@material-ui/core";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CropIcon from "@material-ui/icons/Crop";
import axios from "axios";
import React, {Dispatch, SetStateAction, useState} from "react";
import DragAndDropArray from "../../Helpers/DragAndDropArray";
import PhotoCropper from "../Inputs/PhotoCropper";
import {Photo} from "mesmetric-v2-common/models";
import update from "immutability-helper"
import {getObjectId} from "../../Common/Utility";
import ButtonCircularProgress from "../UI/ButtonCircularProgress";
import {makeStyles} from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {TemplateGuideLine} from "mesmetric-v2-common/models/ProductTemplate";
import "./photos.scss"
import PaddedPaper from "../PaddedPaper/PaddedPaper";
import {getAxiosConfig} from "../../ActionCreators/User";
import {parseError} from "../../ActionCreators/Error";

type CropPostTransform = {
    w?: number,
    h?: number
}

export function getCroppedSrc(photo: Photo.Model, postTransform?: CropPostTransform): string {
    if (!photo.src) {
        return '';
    }
    const paddingParameterMap = {
        'padLeft': 'pl',
        'padRight': 'pr',
        'padTop': 'pt',
        'padBottom': 'pb',
    };
    let crop = photo.crops.find(c => c.active);
    if (!crop) {
        crop = photo.crops.find(crop => crop.aspect === '1:1');
    }
    const post = postTransform || {w: 100};
    const queryTokens: string[] = [];
    if (crop) {
        (['x', 'y', 'w', 'h'] as Array<keyof Photo.Crop>).forEach(prop => {
            queryTokens.push(`c${prop}=${Math.floor(Number(crop?.[prop]))}`);
        });
        (['padLeft', 'padRight', 'padTop', 'padBottom'] as Array<keyof Pick<Photo.Crop, 'padLeft' | 'padRight' | 'padTop' | 'padBottom'>>).forEach(prop => {
            if (crop?.[prop] !== undefined) {
                queryTokens.push(`${paddingParameterMap[prop]}=${crop[prop]}`)
            }
        });
    }
    (['w', 'h'] as Array<keyof CropPostTransform>).forEach(key => {
        if (post[key] === undefined) {
            return;
        }
        queryTokens.push(`${key}=${post[key]}`);
    });
    queryTokens.push('t=lanczos');
    return photo.srcResolved + '?' + queryTokens.join('&');
}

const useStyles = makeStyles({
    dragBox: {
        width: '100%',
        height: '200px',
        borderStyle: 'dotted',
        borderColor: '#ccc',
        borderWidth: '2px',
        padding: '25px',
        textAlign: 'center',
        '&.active': {
            backgroundColor: '#c0ffc3'
        }
    }
})

function getCropByRatio(crops: Photo.Crop[], ratio: string): Photo.Crop | undefined {
    return crops.find(crop => crop.aspect === ratio);
}

export const Photos = (props: {
    photos: Photo.Model[],
    onChange: Dispatch<SetStateAction<Array<Photo.Model>>>,
    onGuidesUpdated: (guides: TemplateGuideLine[]) => void
}) => {
    const onDrop = (acceptedFiles: Array<File>) => {
        acceptedFiles.forEach(file => {
            const fileReader = new FileReader();
            const formData = new FormData();
            let newPhoto: Photo.Model;
            formData.append("image", file);
            axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/products/photos', formData, getAxiosConfig())
                .then(r => {
                    newPhoto.srcResolved = r.data.srcResolved;
                    newPhoto.src = r.data.src;
                    props.onChange((currentPhotos: Photo.Model[]) => {
                        return update(currentPhotos, {
                            $splice: [
                                [currentPhotos.findIndex(photo => photo._id === newPhoto._id), 1, newPhoto]
                            ]
                        });
                    });
                }).catch(parseError);
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                newPhoto = {
                    _id: getObjectId(),
                    src: '',
                    crops: [],
                    srcResolved: fileReader.result as string,
                    meta: {
                        isLegacy: false
                    },
                };
                props.onChange((currentPhotos: Photo.Model[]) => {
                    return update(currentPhotos, {
                        $push: [newPhoto]
                    });
                });
            }
        })
    }
    const [cropperData, setCropperData] = useState<{ src: string, isOpen: boolean, aspect?: number, aspectName: string }>({
        src: '',
        isOpen: false,
        aspectName: 'free'
    });
    const [currentlyCroppedPhoto, setCurrentlyCroppedPhoto] = useState<Photo.Model | null>();

    const [cropperHasClosed, setCropperHasClosed] = useState(true);

    const [showCroppedThumbnails, setShowCroppedThumbnails] = useState(true);

    const classes = useStyles();
    return (
        <div className={"photos"}>
            {!cropperHasClosed &&
            <PhotoCropper
                onGuidesUpdated={props.onGuidesUpdated}
                onExited={() => {
                    setCropperHasClosed(true);
                }}
                currentCrop={(currentlyCroppedPhoto && currentlyCroppedPhoto.crops.length) ? getCropByRatio(currentlyCroppedPhoto.crops, '1:1') : undefined}
                open={cropperData.isOpen}
                src={cropperData.src}
                aspect={cropperData.aspect}
                aspectName={cropperData.aspectName}
                onClose={() => {
                    setCropperData({
                        ...cropperData,
                        isOpen: false
                    });
                }}
                onSave={(cropData: Photo.Crop) => {
                    if (!currentlyCroppedPhoto) {
                        return;
                    }
                    props.onChange((currentPhotos: Photo.Model[]) => {
                        const editedPhotoIndex = currentPhotos.findIndex(photo => photo.src === currentlyCroppedPhoto.src);
                        const foundAspectIndex = currentlyCroppedPhoto.crops.findIndex(crop => {
                            return crop.aspect === cropperData.aspectName
                        });
                        let updatedPhoto: typeof currentlyCroppedPhoto;
                        if (foundAspectIndex === -1) {
                            updatedPhoto = update(currentlyCroppedPhoto, {
                                crops: {
                                    $push: [cropData]
                                }
                            })
                        } else {
                            updatedPhoto = update(currentlyCroppedPhoto, {
                                crops: {
                                    $splice: [
                                        [foundAspectIndex, 1, cropData]
                                    ]
                                }
                            })
                        }
                        return update(currentPhotos, {
                            $splice: [
                                [editedPhotoIndex, 1, updatedPhoto]
                            ]
                        });
                    });
                    setCropperData({
                        ...cropperData,
                        isOpen: false
                    });
                }}
            />}
            <Typography>Zdjęcia produktów</Typography>
            {props.photos.length > 0 ? (
                <>
                    <DragDropContext onDragEnd={function (result) {
                        if (!result.destination) {
                            return;
                        }
                        DragAndDropArray.moveItem(props.photos, result.source.index, result.destination.index, props.onChange);
                    }}>
                        <Droppable droppableId="photos-droppable-list">{(provided, snapshot) => (
                            <List ref={provided.innerRef}>
                                {props.photos.map((photo, index) => (
                                    <Draggable key={`photo-${index}`} draggableId={`photo-${index}`} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItem
                                                disableGutters
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <PaddedPaper style={{
                                                    width: 164,
                                                    height: 164,
                                                    position: 'relative',
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    {!photo.src && <ButtonCircularProgress size={24}/>}
                                                    <img crossOrigin={"anonymous"}
                                                         style={{
                                                             maxWidth: '100%',
                                                             maxHeight: '100%',
                                                             opacity: !photo.src ? 0.3 : 1
                                                         }}
                                                         src={photo.src && showCroppedThumbnails ? getCroppedSrc(photo, {w: 132}) : photo.srcResolved}/>
                                                </PaddedPaper>
                                                <div className={"photo-control"}>
                                                    <Button disabled={!photo.src} size="small" variant="contained"
                                                            onClick={photo.src ? () => {
                                                                setCurrentlyCroppedPhoto(photo);
                                                                setCropperHasClosed(false);
                                                                setCropperData({
                                                                    aspect: 1,
                                                                    src: photo.srcResolved || '',
                                                                    isOpen: true,
                                                                    aspectName: '1:1'
                                                                });
                                                            } : undefined}>
                                                        <span>1:1</span>
                                                        <CropIcon fontSize={"small"}/>
                                                    </Button>
                                                    <Button disabled={!photo.src} size="small" variant="contained"
                                                            onClick={photo.src ? () => {
                                                                setCurrentlyCroppedPhoto(photo);
                                                                setCropperHasClosed(false);
                                                                setCropperData({
                                                                    src: photo.srcResolved || '',
                                                                    isOpen: true,
                                                                    aspectName: '1:1'
                                                                });
                                                            } : undefined}>
                                                        <span>Dowolny</span>
                                                        <CropIcon fontSize={"small"}/>
                                                    </Button>
                                                    <Button size="small" variant="contained"
                                                            color="secondary"
                                                            onClick={() => {
                                                                DragAndDropArray.removeItem(props.photos, photo, props.onChange);
                                                            }}><DeleteIcon/>
                                                    </Button>
                                                </div>
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}</Droppable>
                    </DragDropContext>
                    <FormControlLabel
                        control={
                            <Checkbox checked={showCroppedThumbnails} onChange={(event, checked) => {
                                setShowCroppedThumbnails(checked);
                            }}/>} label={"Miniatury z przyciętymi zdjęciami"}/>
                </>
            ) : <Typography>Brak zdjęć</Typography>}
        </div>
    )
}
export default Photos