import React, {Component} from "react";
import {Product} from "mesmetric-v2-common/models";
import {connect} from "react-redux";
import "./productView.scss";
import {RouteComponentProps} from "react-router";
import {Tab, Tabs} from "@material-ui/core";
import ProductDetails from "./ProductDetails/ProductDetails";
import axios from "axios";
import _ from "lodash";
import Spinner from "./Common/Spinner/Spinner";
import {AppState} from "../../Store";
import {removeProductData, setProductData} from "../../ActionCreators/ProductData";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import DescriptionAndNotes from "./DescriptionAndNotes/DescriptionAndNotes";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import Filters from "./Filters/Filters";
import ProductTemplateAttributes from "./ProductTemplateAttributes/ProductTemplateAttributes";
import {getAxiosConfig} from "../../ActionCreators/User";
import {parseError} from "../../ActionCreators/Error";

type DispatchProps = {
    setProductData: (product: Product) => void,
    removeProductData: () => void
}

type StateProps = {
    productLoaded: boolean,
    productId: string
}

type ProductViewProps = RouteComponentProps<{ id: string }> & DispatchProps & StateProps;

interface ProductViewState {
    selectedTabKey: number
}


const TABS: { key: number, name: string }[] = [
    {
        key: 0,
        name: "GŁÓWNE"
    },
    {
        key: 1,
        name: "ATRYBUTY SZABLONU"
    },
    {
        key: 2,
        name: "FILTRY"
    },
    {
        key: 3,
        name: "OPIS I NOTATKI"
    },
    {
        key: 4,
        name: "PRODUKTY POWIĄZANE"
    }
];

class ProductView extends Component<ProductViewProps, ProductViewState> {
    constructor(props: ProductViewProps) {
        super(props);
        this.state = {
            selectedTabKey: 0
        }
    }

    public componentDidMount(): void {
        this.getProduct().then(this.props.setProductData);
    }

    public componentWillUnmount(): void {
        this.props.removeProductData();
    }

    private getProduct = async (): Promise<Product> => {
        try {
            const response = await axios.get<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.match.params.id}`, getAxiosConfig());
            if (response.data._id === undefined) {
                return Promise.reject();
            }
            return (_.omit(response.data, ["createdAt", "updatedAt", "__v"]) as Product);
        } catch (e) {
            parseError(e);
            throw new Error();
        }
    };

    private renderProduct = (): JSX.Element | null => <>
        <Tabs value={this.state.selectedTabKey}
              onChange={(e, newTabKey) => this.setState({selectedTabKey: newTabKey})}>
            {TABS.map(tab => <Tab key={tab.key} label={tab.name}/>)}
        </Tabs>
        <div className={"scrollable"}>
            <div className={"product-content"}>
                <ProductDetails visible={this.state.selectedTabKey === 0} productId={this.props.productId}/>
                <ProductTemplateAttributes visible={this.state.selectedTabKey === 1} path={"attributes"}/>
                <Filters visible={this.state.selectedTabKey === 2} path={"filters"}/>
                <DescriptionAndNotes visible={this.state.selectedTabKey === 3}/>
                <RelatedProducts visible={this.state.selectedTabKey === 4} path={"related"}/>
            </div>
        </div>
    </>;

    public render = (): JSX.Element =>
        <div className={"product-view"}>
            {!this.props.productLoaded && <Spinner size={64}/>}
            {this.props.productLoaded && this.renderProduct()}
        </div>
}

const mapStateToProps = (state: AppState): StateProps => ({
    productLoaded: state.ProductData.productData !== undefined,
    productId: state.ProductData.productData?._id || "",
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setProductData: (product: Product) => dispatch(setProductData(product, undefined, true)),
    removeProductData: () => dispatch(removeProductData())
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductView);