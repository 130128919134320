import React, {Component} from "react";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {search} from "../../ActionCreators/ProductFilter";
import "./filterButton.scss";

interface DispatchProps {
    search: () => void
}

type Props = DispatchProps;

class FilterButton extends Component<Props> {
    public render = (): JSX.Element =>
        <Button
            className={"filter-button"}
            color={"primary"}
            size={"small"}
            variant={"contained"}
            onClick={this.props.search}
        >Filtruj</Button>;

}

const mapDispatchToProps: DispatchProps = ({
    search
});

export default connect(null, mapDispatchToProps)(FilterButton);