import React, {Dispatch, Reducer, useCallback, useContext, useEffect, useReducer, useState} from "react";
import MultilanguageTextField from "../Components/Inputs/MultilanguageTextField";

import {makeStyles, TextField} from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {RouteComponentProps} from "react-router";
import axios from "axios"
import update from "immutability-helper"
import {
    ProductAttribute,
    ProductAttributeGroup,
    ProductAttributeType,
    ProductTemplate
} from "mesmetric-v2-common/models";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DictionaryTable from "../Components/DictionaryTable";
import Divider from "@material-ui/core/Divider";
import {getObjectId} from "../Common/Utility";
import FormDialog from "../Components/UI/FormDialog";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import {Label} from "mesmetric-v2-common/models/Label";
import StyledSelect from "../Components/Inputs/StyledSelect";
import TrashIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import * as actions from "./TemplateEditor/actionCreator"
import {setCategories} from "./TemplateEditor/actionCreator"
import {actionTypes, allActions} from "./TemplateEditor/actions";
import templateDataReducer from "./TemplateEditor/reducer";
import {ConnectedCategoryTree, getSelectedCategoriesWithoutChildren} from "../Components/CategoryTree";
import AbsoluteSpinner from "../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import PaddedPaper from "../Components/PaddedPaper/PaddedPaper";
import {ProductFilter} from "mesmetric-v2-common/models/ProductFilter";
import {getAxiosConfig} from "../ActionCreators/User";
import {parseError} from "../ActionCreators/Error";

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .01)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const FatDivider = withStyles(({spacing}) => ({
    root: {
        margin: `${spacing(2)}px 0`
    }
}))(Divider);

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2),
        outline: 'none',
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    button: {
        marginTop: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    summaryBar: {
        transition: 'background-color 0.2s'
    }
}));

type AttributeProps<T extends ProductAttribute.Base> = {
    attribute: T,
    path: string,
    groupId: string
}

type AttributeComponent<T extends ProductAttribute.Base> = (props: AttributeProps<T>) => JSX.Element | null

type AttributeGroupProps = {
    attributeGroupData: ProductAttributeGroup,
    path: string
}

const attributeComponents: {
    [i in ProductAttributeType]: AttributeComponent<any>
} = {
    [ProductAttributeType.Dictionary]: React.memo(({attribute, groupId, path}: AttributeProps<ProductAttribute.Dictionary>) => {
        const dispatch = useContext(TemplateDataDispatch);
        const onChange = (updatedDictionary: typeof attribute.dictionary) => {
            dispatch(actions.updateAttribute(
                update(attribute, {
                    dictionary: {
                        $set: updatedDictionary
                    }
                }), path));
        };
        return <DictionaryTable dictionary={attribute.dictionary} onChange={onChange}/>
    }),
    [ProductAttributeType.Size]: React.memo(({attribute, groupId, path}: AttributeProps<ProductAttribute.Size>) => {
        const dispatch = useContext(TemplateDataDispatch);
        return <TextField label="Jednostka" value={attribute.unit}
                          onChange={event => {
                              dispatch({
                                  type: actionTypes.UpdateAttribute,
                                  data: {
                                      path: path,
                                      attribute: {...attribute, unit: event.target.value},
                                  }
                              })
                          }}
        />
    })
}

const MultiLanguageLabelText = (props: { label: Label, incomplete?: boolean, divProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> }) => {
    return <div {...props.divProps} >
        {props.label.pl}(<span style={{fontStyle: "italic"}}>{props.label.en ||
    <strong>Brak tłumaczenia</strong>}</span>)
        {props.incomplete && <span style={{color: "red", paddingLeft: "4px"}}>(Brak niektórych tłumaczeń)</span>}
    </div>;
};

const AttributePanel = ({attribute, attributeGroupId, isExpanded, setCurrentlyExpandedAttributeId, path}: {
    attribute: ProductAttribute.Any,
    attributeGroupId: string,
    isExpanded: boolean,
    setCurrentlyExpandedAttributeId: (attributeId: string) => void,
    path: string
}) => {
    const classes = useStyles();
    const dispatch = useContext(TemplateDataDispatch);
    const incomplete = attribute.type === "dictionary" && attribute.dictionary.items?.some(item => !item?.label?.en);
    return <ExpansionPanel onChange={(e, newExpanded) => {
        setCurrentlyExpandedAttributeId(newExpanded ? attribute._id : '');
    }} expanded={isExpanded}>
        <ExpansionPanelSummary className={classes.summaryBar}
                               style={isExpanded ? {backgroundColor: '#eee'} : undefined}>
            <MultiLanguageLabelText label={attribute.label} incomplete={incomplete} divProps={{style: {flexGrow: 1}}}/>
            <IconButton
                size={"small"} onClick={e => {
                dispatch(actions.removeAttribute(attribute, attributeGroupId));
                e.stopPropagation();
            }}><TrashIcon/></IconButton></ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
            <AttributeItem path={`${path}`} attribute={attribute} attributeGroupId={attributeGroupId}/>
        </ExpansionPanelDetails>
    </ExpansionPanel>
};


const AttributeItem = ({attribute, attributeGroupId, path}: {
    attribute: ProductAttribute.Any,
    attributeGroupId: string,
    path: string
}) => {
    const dispatch = useContext(TemplateDataDispatch);
    const AttributeComponent = attributeComponents[attribute.type];
    return <div><MultilanguageTextField value={attribute.label} onLabelChange={updatedLabel => {
        dispatch(actions.updateAttribute(update(attribute, {
            label: {
                $set: updatedLabel
            }
        }), path));
    }} label="Nazwa atrybutu"/>
        <FatDivider/>
        <AttributeComponent path={path} attribute={attribute} groupId={attributeGroupId}/>
    </div>
}

const FilterPanel = React.memo(({filter, setCurrentlyExpandedFilterId, isExpanded, path}: {
    filter: ProductFilter,
    // attributeGroupId: string,
    isExpanded: boolean,
    setCurrentlyExpandedFilterId: (filterId: string) => void,
    path: string
}) => {
    const classes = useStyles();
    const dispatch = useContext(TemplateDataDispatch);
    const incomplete = filter.dictionary?.items?.some(item => !item.label.en);
    return <>
        <ExpansionPanel onChange={(e, newExpanded) => {
            setCurrentlyExpandedFilterId(newExpanded ? filter._id : '');
        }} expanded={isExpanded}>
            <ExpansionPanelSummary className={classes.summaryBar}
                                   style={isExpanded ? {backgroundColor: '#eee'} : undefined}>
                <MultiLanguageLabelText label={filter.label} incomplete={incomplete} divProps={{style: {flexGrow: 1}}}/>
                <IconButton
                    size={"small"} onClick={e => {
                    dispatch(actions.removeFilter(filter));
                    e.stopPropagation();
                }}><TrashIcon/></IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{display: 'block'}}>
                <FilterItem filter={filter} path={path}/>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </>
})

const FilterItem = ({filter, path}: {
    filter: ProductFilter,
    path: string
}) => {
    const dispatch = useContext(TemplateDataDispatch);
    const onChange = (updatedDictionary: typeof filter.dictionary) => {
        dispatch(actions.updateFilter(
            update(filter, {
                dictionary: {
                    $set: updatedDictionary
                }
            }), path));
    };
    return <div><MultilanguageTextField value={filter.label} onLabelChange={updatedLabel => {
        dispatch(actions.updateFilter(update(filter, {
            label: {
                $set: updatedLabel
            }
        }), path));
    }} label="Nazwa filtru"/>
        <FatDivider/>
        <DictionaryTable dictionary={filter.dictionary} onChange={onChange}/>
        {/*<AttributeComponent path={path} attribute={attribute} groupId={attributeGroupId}/>*/}
    </div>
}

const AttributeGroup = React.memo(({attributeGroupData, path}: AttributeGroupProps) => {
    const dispatch = useContext(TemplateDataDispatch);
    const [expandedAttributeId, setExpandedAttributeId] = useState('');
    return <div style={{width: '100%'}}>
        <MultilanguageTextField value={attributeGroupData.label}
                                onLabelChange={updatedLabel => {
                                    dispatch({
                                        type: actionTypes.UpdateGroup,
                                        data: {
                                            ...attributeGroupData,
                                            label: updatedLabel
                                        }
                                    });
                                }}/>
        <FormControlLabel control={<Checkbox checked={attributeGroupData.withVariants} onChange={(event, checked) => {
            dispatch({
                type: actionTypes.UpdateGroup,
                data: {

                    ...attributeGroupData,
                    withVariants: checked
                }
            })
        }}/>} label={<Typography variant={"body2"}>Z wariantami?</Typography>}/>
        {
            attributeGroupData.attributes.map((attribute, attributeIndex) => (
                    <AttributePanel key={`attribute-panel-for-${attribute._id}`} attribute={attribute}
                                    attributeGroupId={attributeGroupData._id}
                                    isExpanded={attribute._id === expandedAttributeId}
                                    setCurrentlyExpandedAttributeId={setExpandedAttributeId}
                                    path={`${path}.attributes.${attributeIndex}`}
                    />
                )
            )
        }
    </div>
})

const FilterGroup = React.memo((filterGroupData: any) => {
    const dispatch = useContext(TemplateDataDispatch);
    const [expandedAttributeId, setExpandedAttributeId] = useState('');
    return <div style={{width: '100%'}}>
        <FormControlLabel control={<Checkbox checked={filterGroupData?.withVariants} onChange={(event, checked) => {
            dispatch({
                type: actionTypes.UpdateGroup,
                data: {

                    ...filterGroupData,
                    withVariants: checked
                }
            })
        }}/>} label={<Typography variant={"body2"}>Z wariantami?</Typography>}/>
        {
            filterGroupData.filters.map((filter: any, filterIndex: any) => (
                    <AttributePanel key={`attribute-panel-for-${filter._id}`} attribute={filter}
                                    attributeGroupId={filterGroupData._id}
                                    isExpanded={filter._id === expandedAttributeId}
                                    setCurrentlyExpandedAttributeId={setExpandedAttributeId}
                                    path={`filterGroup.filters.${filterIndex}`}
                    />
                )
            )
        }
    </div>
})

const getDefaultNewAttribute = (): ProductAttribute.Size => ({
    _id: getObjectId(),
    type: ProductAttributeType.Size,
    unit: "cm",
    label: {
        pl: "Nowy atrybut",
        en: "New attribute",
    },
});

const getDefaultNewFilter = (): ProductFilter => ({
    _id: getObjectId(),
    label: {
        pl: "Nowy filter",
        en: "New filter",
    },
    dictionary: {
        _id: getObjectId(),
        items: [],
        label: 'Słownik filtru'
    }
});

const getCommonAttributeProperties: () => {
    _id: string,
    label: {
        pl: string,
        en: string
    }
} = () => {
    return {
        _id: getObjectId(),
        label: {
            pl: "Nowy atrybut",
            en: "New attribute"
        }
    }
};

const createNewSizeAttribute: () => ProductAttribute.Size = () => {
    return {
        ...getCommonAttributeProperties(),
        type: ProductAttributeType.Size,
        unit: "cm",
    }
}

const createNewDictionaryAttribute: () => ProductAttribute.Dictionary = () => {
    return {
        ...getCommonAttributeProperties(),
        type: ProductAttributeType.Dictionary,
        dictionary: {
            _id: getObjectId(),
            items: [],
            label: 'Słownik atrybutu'
        }
    }
};

const attributeCreators: {
    [attributeType in ProductAttributeType]: () => ProductAttribute.Any
} = {
    [ProductAttributeType.Dictionary]: createNewDictionaryAttribute,
    [ProductAttributeType.Size]: createNewSizeAttribute
};


const AddNewAttributeModal = React.memo((props: {
    attributeGroupId: string,
    setModalIsOpen: (isOpen: boolean) => void
}) => {
    const dispatch = useContext(TemplateDataDispatch);
    const [selectedType, setSelectedType] = useState('');
    const [newAttributeData, setNewAttributeData] = useState<ProductAttribute.Any>(createNewSizeAttribute());
    return <FormDialog
        canSave={selectedType !== ''}
        open
        onClose={() => {
            setNewAttributeData(getDefaultNewAttribute());
            props.setModalIsOpen(false);
        }}
        isLoading={false}
        onSave={() => {
            dispatch({
                type: actionTypes.AddAttribute,
                data: {
                    groupId: props.attributeGroupId,
                    attribute: newAttributeData
                }
            });
            setNewAttributeData(createNewSizeAttribute());
            props.setModalIsOpen(false);
        }}
        title="Dodaj nowy atrybut">
        <div>
            <MultilanguageTextField value={newAttributeData.label} onLabelChange={updatedLabel => {
                setNewAttributeData({
                    ...newAttributeData,
                    label: updatedLabel
                })
            }}/>
            <StyledSelect<{ label: string, value: ProductAttributeType }>
                options={[
                    {
                        label: "Slownik",
                        value: ProductAttributeType.Dictionary
                    },
                    {
                        label: "Wymiar",
                        value: ProductAttributeType.Size
                    }
                ]}
                placeholder={"Wybierz rodzaj atrybutu"}
                menuPortalTarget={document.body}
                onChange={(value: any) => {
                    setSelectedType(value.value);
                    setNewAttributeData({
                        ...attributeCreators[value.value as ProductAttributeType](),
                        label: newAttributeData.label
                    })
                }}
            />
        </div>
    </FormDialog>
})

const AddNewFilterModal = React.memo((props: {
    setModalIsOpen: (isOpen: boolean) => void
}) => {
    const dispatch = useContext(TemplateDataDispatch);
    const [newFilterData, setNewFilterData] = useState<ProductFilter>(getDefaultNewFilter());
    return <FormDialog
        canSave
        open
        onClose={() => {
            props.setModalIsOpen(false);
        }}
        isLoading={false}
        onSave={() => {
            // dispatch(actions.removeFilter(filter));
            dispatch({
                type: actionTypes.AddFilter,
                data: newFilterData
            });
            props.setModalIsOpen(false);
        }}
        title="Dodaj nowy filtr">
        <div>
            <MultilanguageTextField value={newFilterData.label} onLabelChange={updatedLabel => {
                setNewFilterData({
                    ...newFilterData,
                    label: updatedLabel
                })
            }}/>
        </div>
    </FormDialog>
})

const AttributeGroupPanel = ({attributeGroup, isExpanded, setExpandedGroupId, path}: {
    attributeGroup: ProductAttributeGroup,
    isExpanded: boolean,
    setExpandedGroupId: (groupId: string) => void,
    path: string
}) => {
    const classes = useStyles();
    const dispatch = useContext(TemplateDataDispatch);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const incomplete = attributeGroup?.attributes?.some(attribute => attribute?.type === ProductAttributeType.Dictionary && attribute?.dictionary?.items?.some(item => !item?.label?.en));
    return <>
        {newModalIsOpen &&
        <AddNewAttributeModal
            attributeGroupId={attributeGroup._id}
            setModalIsOpen={setNewModalIsOpen}/>}

        <ExpansionPanel onChange={(event: React.ChangeEvent<{}>, expanded: boolean) =>
            setExpandedGroupId(expanded ? attributeGroup._id : '')
        } expanded={isExpanded} key={`attribute-group-${attributeGroup._id}`}>
            <ExpansionPanelSummary className={classes.summaryBar}
                                   style={isExpanded ? {backgroundColor: '#eee'} : undefined}>
                <MultiLanguageLabelText label={attributeGroup.label} incomplete={incomplete}
                                        divProps={{style: {flexGrow: 1}}}/><IconButton
                size={"small"} onClick={e => {
                dispatch({
                    type: actionTypes.RemoveGroup,
                    data: {
                        groupId: attributeGroup._id
                    }
                });
                e.stopPropagation();
            }}><TrashIcon/></IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{display: 'block'}}>
                <AttributeGroup path={path} attributeGroupData={attributeGroup}/>
            </ExpansionPanelDetails>
            <Divider/>
            <ExpansionPanelActions style={{justifyContent: 'flex-start'}}>
                <Button onClick={event => {
                    setNewModalIsOpen(true);
                }} variant={"outlined"}>Dodaj nowy atrybut do rozdziału</Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    </>
};

const TemplateDataDispatch = React.createContext<Dispatch<allActions>>((value => {
}));


const getNewAttributeGroupData: () => ProductAttributeGroup = () => {
    return {
        attributes: [],
        _id: getObjectId(),
        label: {
            pl: "Nowy rozdział",
            en: "New chapter"
        }, withVariants: false
    };
}

interface categorySelectorProps {

}

const CategorySelector: React.FC<categorySelectorProps> = (props => {
    return <div>a</div>
})

export default (props: RouteComponentProps<{ id: string }>) => {
    const [reducerTemplateData, dispatch] = useReducer<Reducer<ProductTemplate, any>>(templateDataReducer, {
        _id: "",
        attributeGroups: [],
        label: "",
        categories: [],
        guidelines: [],
    });

    const [newAttributeGroupData, setNewAttributeGroupData] = useState<ProductAttributeGroup>(getNewAttributeGroupData());
    const [newGroupModalVisible, setNewGroupModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [currentlyExpandedGroupId, setCurrentlyExpandedGroupId] = useState('');
    const [expandedFilterId, setExpandedFilterId] = useState('');
    const fetchTemplate = useCallback(() => {
        axios.get<ProductTemplate>(process.env.REACT_APP_DATA_ENDPOINT + `/templates/${props.match.params.id}`, getAxiosConfig())
            .then(({data}) => {
                setIsLoading(false);
                dispatch(actions.setTemplateData(data));
            }).catch(parseError)
    }, []);
    const saveTemplate = useCallback((templateData) => {
        setIsUpdating(true);
        axios.put(process.env.REACT_APP_DATA_ENDPOINT + `/templates`, templateData, getAxiosConfig())
            .then(({data}) => {
                setIsUpdating(false);
            }).catch(parseError);
    }, []);

    useEffect(() => {
        fetchTemplate();
    }, []);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    return <PaddedPaper>
        <Grid container>
            <TemplateDataDispatch.Provider value={dispatch}>
                <Grid item xs={12} style={{position: 'relative'}}>
                    {isLoading ? <div>Wczytuję szablon...</div> :
                        <>
                            <FormDialog canSave={true} isLoading={false} onSave={() => {
                                dispatch({type: actionTypes.AddGroup, data: newAttributeGroupData});
                                setNewAttributeGroupData(getNewAttributeGroupData());
                                setNewGroupModalVisible(false);
                            }} onClose={() => {
                                setNewGroupModalVisible(false);
                            }} open={newGroupModalVisible} title="Nowy rozdział">
                                <>
                                    <MultilanguageTextField textFieldProps={{
                                        fullWidth: true
                                    }} value={newAttributeGroupData.label}
                                                            onLabelChange={updatedLabel => {
                                                                setNewAttributeGroupData({
                                                                    ...newAttributeGroupData,
                                                                    label: updatedLabel
                                                                })
                                                            }}/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={newAttributeGroupData.withVariants}
                                                      onChange={(event, checked) => {
                                                          setNewAttributeGroupData({
                                                              ...newAttributeGroupData,
                                                              withVariants: checked
                                                          })
                                                      }} value="withVariants"/>
                                        }
                                        label="Z wariantami?"
                                    />
                                </>
                            </FormDialog>
                            <TextField value={reducerTemplateData.label} label="Nazwa szablonu" onChange={event => {
                                dispatch(actions.setTemplateData({
                                    ...reducerTemplateData,
                                    label: event.target.value
                                }));
                            }}/>
                            <FatDivider/>
                            {reducerTemplateData.attributeGroups.length > 0 ?
                                reducerTemplateData.attributeGroups.map((attributeGroup, index) => (<AttributeGroupPanel
                                        isExpanded={attributeGroup._id === currentlyExpandedGroupId}
                                        setExpandedGroupId={setCurrentlyExpandedGroupId}
                                        key={`attribute-group-${attributeGroup._id}`}
                                        attributeGroup={attributeGroup}
                                        path={`attributeGroups.${index}`}
                                    />
                                ))
                                : <Typography>Nie dodano jeszcze żadnego rozdziału</Typography>}
                            <Button variant={"outlined"} onClick={e => {
                                setNewGroupModalVisible(true)
                            }
                            }>Dodaj nowy rozdział</Button>
                            <FatDivider/>
                            {newModalIsOpen && <AddNewFilterModal setModalIsOpen={setNewModalIsOpen}/>}
                            <ExpansionPanel>
                                <ExpansionPanelSummary>
                                    Filtry {reducerTemplateData?.filterGroup?.filters?.some(filter => filter?.dictionary?.items?.some(item => !item?.label?.en)) &&
                                <span style={{color: "red", paddingLeft: "4px"}}>(Brak niektórych tłumaczeń)</span>}
                                </ExpansionPanelSummary>
                                {}
                                <ExpansionPanelDetails style={{display: 'block'}}>
                                    {reducerTemplateData.filterGroup && reducerTemplateData.filterGroup.filters?.length > 0 ?
                                        <>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={reducerTemplateData.filterGroup?.withVariants}
                                                    onChange={(event, checked) => {
                                                        dispatch({
                                                            type: actionTypes.UpdateFilterGroup,
                                                            data: {

                                                                ...reducerTemplateData.filterGroup,
                                                                withVariants: checked
                                                            }
                                                        })
                                                    }}/>}
                                                label={<Typography variant={"body2"}>Z wariantami?</Typography>}/>
                                            {
                                                reducerTemplateData.filterGroup?.filters.map((filter: any, filterIndex: any) => (
                                                        <FilterPanel filter={filter}
                                                                     isExpanded={filter._id === expandedFilterId}
                                                                     setCurrentlyExpandedFilterId={setExpandedFilterId}
                                                                     path={`filterGroup.filters.${filterIndex}`}/>
                                                    )
                                                )
                                            }
                                        </> : <Typography>Nie dodano jeszcze żadnego filtru</Typography>}
                                </ExpansionPanelDetails>
                                <ExpansionPanelActions style={{justifyContent: 'flex-start', marginLeft: "18px"}}>
                                    <Button onClick={event => {
                                        setNewModalIsOpen(true);
                                    }} variant={"outlined"}>Dodaj nowy filtr</Button>
                                </ExpansionPanelActions>
                            </ExpansionPanel>
                            <FatDivider/>
                            <ExpansionPanel>
                                <ExpansionPanelSummary>
                                    Kategorie
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    {!isLoading && reducerTemplateData._id !== '' && <ConnectedCategoryTree
                                        defaultSelected={reducerTemplateData.categories.map(category => category._id || '')}
                                        onCategoriesSelected={selectedCategories => {
                                            dispatch(setCategories(getSelectedCategoriesWithoutChildren(selectedCategories).filter(category => {
                                                return category.template === undefined || (category.template as ProductTemplate)._id === reducerTemplateData._id
                                            })));
                                        }}
                                        selectedNodeCallback={categoryNode => {
                                            const template = categoryNode.category.template;
                                            if (!template || (template as ProductTemplate)._id == reducerTemplateData._id) {
                                                return categoryNode;
                                            }
                                            return {
                                                ...categoryNode,
                                                isSelected: true,
                                                isDisabled: true
                                            };
                                        }}
                                        labelCallback={categoryNode => {
                                            const template = categoryNode.category.template;
                                            const label = categoryNode.category.name.pl;
                                            if (!template || (template as ProductTemplate)._id == reducerTemplateData._id) {
                                                return label;
                                            }
                                            return `${label} (${(template as ProductTemplate).label})`;
                                        }}
                                    />}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <FatDivider/>
                            <Button disabled={isUpdating} variant={"outlined"} onClick={e => {
                                saveTemplate(reducerTemplateData);
                            }
                            }>{isUpdating && <AbsoluteSpinner/>} Zapisz zmiany</Button>
                        </>
                    }
                </Grid>

            </TemplateDataDispatch.Provider>
        </Grid>
    </PaddedPaper>
}
