import React from "react";
import {Dialog, DialogContent, Grid} from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as AddIcon} from "../../../../../Icons/add.svg";
import {withStyles} from "@material-ui/styles";
import {Product} from "mesmetric-v2-common/models";
import {connect} from "react-redux";
import {AppState} from "../../../../../Store";

interface ExternalProps {
    onClose: () => void,
    classes: any
}

interface StateProps {
    product: Product,
    onClose: () => void,
    classes: any
}

type ProductPreviewProps = StateProps;


function ProductPropertyBox(props: { productData: Product, propertyName: string, propertyLabel: string }) {
    const w = props.productData.legacyData;
    return <div>
        <div style={{fontSize: "1em", paddingBottom: "1em"}}>{props.propertyLabel.toUpperCase()}</div>
        <div>{props.productData.legacyData && props.productData.legacyData[props.propertyName as keyof typeof props.productData.legacyData]}</div>
        <hr/>
    </div>;
}


const ProductPreview: React.FC<ProductPreviewProps> = props => (
    <Dialog
        className={props.classes && props.classes.root}
        open
        maxWidth={"lg"}
        fullWidth={true}
        onClose={props.onClose}>
        <DialogContent>
            <Grid container spacing={4}>
                <Grid item xs={8}>
                    {props.product.photos.map(photo =>
                        <img key={photo.srcResolved}
                             style={{width: "100%", marginBottom: '0.5em'}}
                             src={`${photo.srcResolved}?w=801`}/>)}
                </Grid>
                <Grid item xs={4}>
                    <div style={{
                        fontFamily: 'Jenson',
                        fontSize: '2.5em',
                        marginBottom: '1em'
                    }}>
                        {props.product.name}
                    </div>
                    <div style={{display: 'flex'}}>
                        <SvgIcon
                            component={AddIcon}
                            viewBox={'0 0 60 60'}
                            style={{fontSize: '1.6em', position: 'relative', top: '10px'}}/>
                        <div style={{
                            paddingLeft: '12px',
                            top: '-20px'
                        }}>MARKA:<br/><b>{props.product.brand && props.product.brand.name}</b></div>
                    </div>
                    <div style={{display: 'flex', marginTop: '1em'}}>
                        <SvgIcon component={AddIcon} viewBox={'0 0 60 60'}
                                 style={{fontSize: '1.6em', position: 'relative', top: '10px'}}/>
                        <div style={{
                            paddingLeft: '12px',
                            top: '-20px'
                        }}>PROJEKTANT:<br/><b>{props.product.designers && props.product.designers.map(designer => designer.name).join(', ')}</b>
                        </div>
                    </div>
                    <div className={props.classes && props.classes.paddedDiv}><b>CENA
                        OD {props.product.price.calculated}</b></div>
                    <div className={props.classes && props.classes.paddedDiv}>zapytaj o cenę -></div>
                    <hr/>
                    {[
                        ['materials', 'materiały'],
                        ['colors', 'kolory'],
                        ['sizes', 'wymiary'],
                    ].map(([propertyName, propertyLabel]) => {
                        return <ProductPropertyBox key={`product-property-${propertyName}`}
                                                   productData={props.product} propertyLabel={propertyLabel}
                                                   propertyName={propertyName}/>
                    })}
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
);

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    product: state.ProductData.productData as Product,
    onClose: externalProps.onClose,
    classes: externalProps.classes
});

export default withStyles(theme => ({
    root: {
        '& hr': {
            margin: '1.5em 0 1.5em 0'
        },
    },
    paddedDiv: {
        margin: '1em 0 1em 0'
    }
}))(connect(mapStateToProps)(ProductPreview));