import update from "immutability-helper";

export const getObjectId = () => {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

export function buildUpdateSpec(_path: string[], operation: any): any {
    const path = [..._path];
    let currentPathToken = path.shift() as string;
    return {
        [currentPathToken]: (prop: any) => update(prop || {}, path.length === 0 ? operation : buildUpdateSpec(path, operation))
    }
}
