import React from "react"
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {NavLink, NavLinkProps} from "react-router-dom"
import ListItemText from "@material-ui/core/ListItemText";
import {ListItemAvatar} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

const NavLinkWithStyle = (props: NavLinkProps) => {
    return <NavLink {...props} activeStyle={{
        backgroundColor: '#eee'
    }}/>
};

interface LinkItem {
    label: string,
    path?: string,
    colour?: string,
    action?: () => void
}

const menuItems: LinkItem[] = [
    {
        label: 'Kategorie',
        path: '/categories',
        colour: '#26A69A'
    },
    {
        label: "Szablony",
        path: "/templates",
        colour: '#66BB6A'
    },
    {
        label: 'Produkty',
        path: '/products',
        colour: '#42A5F5'
    },
    {
        label: 'Słowniki',
        path: '/dictionaries',
        colour: '#7E57C2'
    },
    {
        label: 'Marki',
        path: '/brands',
        colour: '#ff9900'
    }
];

const adminMenuItems: LinkItem[] = [
    {
        label: 'Użytkownicy',
        path: '/users',
        colour: '#FF0000'
    },
];

const AdapterLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
    <NavLink innerRef={ref as any} {...props} activeStyle={{
        backgroundColor: '#ddd'
    }}/>
));
export default function SideMenu(props: { isAdmin: boolean, logout: () => void }) {

    const mapMenuItem = (linkItem: LinkItem) => linkItem.path ?
        <ListItem button key={linkItem.label} component={AdapterLink} to={linkItem.path}>
            <ListItemAvatar>
                <Avatar style={{
                    backgroundColor: linkItem.colour
                }}>{linkItem.label[0].toUpperCase()}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={linkItem.label}/>
        </ListItem> : null;


    return (
        <List>
            {menuItems.map(mapMenuItem)}
            {props.isAdmin && adminMenuItems.map(mapMenuItem)}
            <ListItem button key={"logut"} onClick={() => props.logout()}>
                <ListItemAvatar>
                    <Avatar style={{
                        backgroundColor: "#000000"
                    }}>{"Wyloguj się"[0].toUpperCase()}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"Wyloguj się"}/>
            </ListItem>
        </List>
    )
}
