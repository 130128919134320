import React, {Component} from "react";
import {Clear, FilterList} from "@material-ui/icons";
import {IconButton, Popover} from "@material-ui/core";
import {AppState} from "../../../Store";
import {connect} from "react-redux";
import styles from "./Tools.module.scss";
import {setFilter} from "../../../ActionCreators/ProductsFilters";
import {Column, COLUMNS} from "../Columns";
import {ProductsFilters} from "mesmetric-v2-common/models/ProductsFilters";

interface StateProps {
    filters: ProductsFilters
}

interface DispatchProps {
    setFilter: (field: keyof ProductsFilters, value?: string | number | boolean) => void
}

type Props = StateProps & DispatchProps;

interface State {
    anchor?: HTMLButtonElement
}

class FiltersCount extends Component<Props, State> {
    public state: State = {};

    private renderActiveFilter = (column: Column, filtersCount: number): JSX.Element | null => {
        if (this.props.filters[column.field] === undefined) {
            return null;
        }

        return <div
            className={styles.filter}
            key={column.field}
        >
            <span>{column.name}</span>
            <IconButton
                size={"small"}
                onClick={() => {
                    if (filtersCount === 1) {
                        this.setState({anchor: undefined});
                    }
                    this.props.setFilter(column.field, undefined)
                }}
            >
                <Clear/>
            </IconButton>
        </div>
    };

    public render = (): JSX.Element | null => {
        const {categories, ...rest} = this.props.filters;
        const filtersCount = Object.values(rest).filter(el => el !== undefined).length;

        return filtersCount ?
            <div className={styles.activeFilters}>
                <IconButton
                    className={styles.button}
                    color={this.state.anchor ? "primary" : undefined}
                    onClick={event => this.setState({anchor: this.state.anchor ? undefined : event.currentTarget})}>
                    <FilterList/>
                    {!!filtersCount &&
                    <div className={styles.buttonCounter}>{filtersCount}</div>}
                </IconButton>
                {!!filtersCount &&
                <IconButton
                    className={styles.button}
                    onClick={() => COLUMNS.forEach(column => this.props.setFilter(column.field, undefined))}>
                    <Clear/>
                </IconButton>}
                {!!this.state.anchor &&
                <Popover
                    open
                    anchorEl={this.state.anchor}
                    onClose={() => this.setState({anchor: undefined})}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={styles.activeFiltersPopup}>
                        <span className={styles.header}>Aktywne filtry</span>
                        {COLUMNS.map(column => this.renderActiveFilter(column, filtersCount))}
                    </div>
                </Popover>}
            </div> : null;
    }

}

const mapStateToProps = (state: AppState): StateProps => ({
    filters: state.ProductsFilters
});

const mapDispatchToProps: DispatchProps = ({
    setFilter
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersCount);