import {Product} from "mesmetric-v2-common/models";

export interface ProductsListState {
    isSearching: any;
    scrollY: number,
    products: Product[],
}

export const initialState: ProductsListState = {
    scrollY: 0,
    products: [],
    isSearching: false
}