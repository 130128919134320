import React, {Component} from "react";
import ProductFeatures from "./ProductFeatures/ProductFeatures";
import Pricing from "./Pricing/Pricing";
import Additional from "./Additional/Additional";
import Controls from "./Controls/Controls";
import "./productDetails.scss";
import FilesUpload from "./FilesUpload/FilesUpload";
import FilesUploaded from "./FilesUploaded/FilesUploaded";
import Categories from "./Categories/Categories";

interface ProductDetailsProps {
    productId: string,
    visible: boolean
}

class ProductDetails extends Component<ProductDetailsProps> {

    public render = (): JSX.Element =>
        <div className={"product-details"} hidden={!this.props.visible}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Controls productId={this.props.productId}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-6"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Categories/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <ProductFeatures/>
                        </div>
                        <div className={"col-6"}>
                            <Pricing/>
                            <Additional/>
                        </div>
                    </div>
                </div>
                <div className={"col-6"}>
                    <FilesUpload
                        productId={this.props.productId}
                        filesPath={"files"}
                        photosPath={"photos"}
                    />
                    <FilesUploaded
                        productId={this.props.productId}
                    />
                </div>
            </div>
        </div>

}

export default ProductDetails;