import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import ChangeItem, {Props as ChangeItemProps} from "../ChangeItem/ChangeItem";
import {BackendBrand} from "../../../ActionCreators/Brands";
import {ItemData} from "../ItemForm/ItemForm";

interface Props extends ChangeItemProps {
    item: BackendBrand,
    doAction: (userData: ItemData) => Promise<string | void>
}

class EditItem extends ChangeItem<Props> {
    constructor(props: Props) {
        super(props, "EDIT", {
            ...props.item
        });
    }

    protected getButton = (func: () => void): JSX.Element =>
        <Button
            size="small"
            variant="contained"
            className={this.props.className}
            onClick={() => {
                func();
                this.setState({
                    userData: {
                        ...this.props.item
                    }
                })
            }}
        >
            <EditIcon/>
        </Button>;

    protected buttonDisabled = (): boolean =>
        !this.state.userData?.name || !this.state.userData.code || this.state.userData.priceMultiplier === undefined || !!this.props.otherItems?.some(item => item.name === this.state.userData?.name || item.code === this.state.userData?.code);


    protected getSaveButtonLabel = (): string => "Zapisz";
}

export default EditItem;