import React, {Component} from "react";
import {ViewColumn, VisibilityOff} from "@material-ui/icons";
import {IconButton, Popover} from "@material-ui/core";
import {connect} from "react-redux";
import {AppState} from "../../../Store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {setHiddenColumns} from "../../../ActionCreators/Products";
import styles from "./Columns.module.scss";
import {COLUMNS} from "../Columns";
import {CheckboxNotConnected} from "../../ProductView/Common/Checkbox/Checkbox";
import commonStyles from "./Tools.module.scss";
import {ProductsFilters} from "mesmetric-v2-common/models/ProductsFilters";

interface StateProps {
    hiddenColumns: (keyof ProductsFilters)[]
}

interface DispatchProps {
    setHiddenColumns: (columns: (keyof ProductsFilters)[]) => void
}

type Props = StateProps & DispatchProps;

interface State {
    anchor?: HTMLButtonElement
}

class Columns extends Component<Props, State> {
    public state: State = {};

    public render = (): JSX.Element =>
        <>
            <IconButton
                title={"Kolumny"}
                className={`${commonStyles.button} ${styles.button}`}
                onClick={event => this.setState({
                    anchor: this.state.anchor ? undefined : event.currentTarget
                })}
            >
                <ViewColumn/>
                {!!this.props.hiddenColumns.length &&
                <div
                    className={commonStyles.buttonCounter}>{COLUMNS.filter(col => col.canBeHidden).length - this.props.hiddenColumns.length}</div>}
            </IconButton>
            {!!this.state.anchor &&
            <Popover
                open
                anchorEl={this.state.anchor}
                onClose={() => this.setState({anchor: undefined})}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={styles.body}>
                    <div className={styles.header}>
                        <span className={commonStyles.header}>Kolumny</span>
                        <IconButton
                            className={styles.removeAll}
                            size={"small"}
                            title={"Wyczyść"}
                            onClick={() => this.props.setHiddenColumns(COLUMNS.filter(col => col.canBeHidden).map(col => col.field))}
                        >
                            <VisibilityOff/>
                        </IconButton>
                    </div>
                    {COLUMNS.map(column =>
                        <CheckboxNotConnected
                            key={column.field}
                            label={column.name}
                            disabled={!column.canBeHidden}
                            onChange={value => this.props.setHiddenColumns(value ? this.props.hiddenColumns.filter(el => el !== column.field) : [...this.props.hiddenColumns, column.field])}
                            value={!column.canBeHidden || !this.props.hiddenColumns.includes(column.field)}
                        />
                    )}
                </div>
            </Popover>}
        </>;
}

const mapStateToProps = (state: AppState): StateProps => ({
    hiddenColumns: state.Products.hiddenColumns
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setHiddenColumns: columns => dispatch(setHiddenColumns(columns))
});

export default connect(mapStateToProps, mapDispatchToProps)(Columns);