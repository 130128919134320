import React, {Component, Dispatch} from "react";
import axios from "axios";
import {Product} from "mesmetric-v2-common/models/Product";
import {connect} from "react-redux";
import {Checkbox} from "@material-ui/core";
import {AllActions, updateProduct} from "../../ActionCreators/ProductsList";
import {getAxiosConfig} from "../../ActionCreators/User";
import {parseError} from "../../ActionCreators/Error";

interface OwnProps {
    productId: string,
    isActive: boolean,
    onChange?: (event: any) => void
    size?: 'small' | 'medium'
}

interface DispatchProps {
    updateProductInList: (product: Product) => void
}

type Props = OwnProps & DispatchProps

interface State {
    productSending: boolean
    checked: boolean
}

class ActiveProductCheckbox extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            productSending: false,
            checked: this.props.isActive
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (this.props.isActive !== prevProps.isActive) {
            this.setState({
                checked: this.props.isActive
            })
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        this.setState({
            checked: checked
        });

        axios.get<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.productId}`, getAxiosConfig())
            .then(result => {
                const product = result?.data;
                product.isActive = checked;
                axios.put<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.productId}`, product, getAxiosConfig())
                    .then((result) => {
                        this.props.updateProductInList(result.data);
                    })
                    .catch(parseError)
            })
            .catch(parseError);


    };

    render = (): JSX.Element => {
        return (
            <Checkbox
                size={this.props.size || 'small'}
                checked={this.state.checked}
                onChange={this.props.onChange || this.handleChange}/>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AllActions>) => ({
    updateProductInList: (product: Product) => {
        dispatch(updateProduct(product));
    }
});

export default connect(null, mapDispatchToProps)(ActiveProductCheckbox);