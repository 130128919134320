import React, {Component} from 'react'
import {ProductAttributeVariant} from "mesmetric-v2-common/models/Product";
import "./variant.scss"
import VariantCard from "./Card/VariantCard";
import _ from "lodash";
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {ProductAttribute} from "mesmetric-v2-common/models/ProductAttribute";
import Attributes from "../../../Attributes/Attributes";
import {AppState} from "../../../../../../Store";
import NameFields from "./NameFields/NameFields";
import {Dictionary} from "mesmetric-v2-common/models/Dictionary";
import {Action} from "redux";
import {updateValue} from "../../../../../../ActionCreators/ProductData";

interface ExternalProps {
    path: string
    attributesProvided: ProductAttribute.Any[]
    variantId: string
    onVariantRemove: () => void
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
    isNewlyAdded: boolean,
    onVariantClone: () => void
}

interface StateProps {
    path: string
    attributesProvided: ProductAttribute.Any[]
    variant: ProductAttributeVariant
    variantId: string
    onVariantRemove: () => void
    onVariantClone: () => void
    onDictionaryUpdated: (attributeId: number, value: Dictionary) => void
    isNewlyAdded: boolean
}

interface DispatchProps {
    onVariantUpdate: (value: ProductAttributeVariant) => void
}

type Props = StateProps & DispatchProps

class Variant extends Component<Props> {
    static defaultProps = {
        variant: {}
    };

    private onLabelChange = (value: any): any => {
        const updatedVariant = {...this.props.variant};
        const newVariant = {
            attributes: updatedVariant.attributes,
            label: value
        };
        this.props.onVariantUpdate(newVariant)
    };

    render = (): JSX.Element => {
        // @ts-ignore
        return <VariantCard
            key={this.props.variantId}
            title={this.props.variant?.label?.pl}
            variantId={this.props.variantId}
            onVariantRemove={this.props.onVariantRemove}
            onVariantClone={this.props.onVariantClone}
            newlyAdded={this.props.isNewlyAdded}
            attributesProvided={this.props.attributesProvided}
            path={`${this.props.path}.attributes`}>
            <NameFields
                onLabelChange={this.onLabelChange}
                path={`${this.props.path}.label`}/>
            <div className={"variant-elements"}>
                <Attributes
                    path={`${this.props.path}.attributes`}
                    attributesProvided={this.props.attributesProvided}
                    onDictionaryUpdated={this.props.onDictionaryUpdated}
                />
            </div>
        </VariantCard>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    variant: _.get(state.ProductData.productData, externalProps.path),
    attributesProvided: externalProps.attributesProvided,
    path: externalProps.path,
    onVariantRemove: externalProps.onVariantRemove,
    variantId: externalProps.variantId,
    onDictionaryUpdated: externalProps.onDictionaryUpdated,
    isNewlyAdded: externalProps.isNewlyAdded,
    onVariantClone: externalProps.onVariantClone
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onVariantUpdate: (value: ProductAttributeVariant) => dispatch(updateValue(`${externalProps.path}`, value)),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Variant);