import axios from 'axios'
import {getAxiosConfig} from "../ActionCreators/User";
import {parseError} from "../ActionCreators/Error";

const cachedData = {}

const apiEndpoint = process.env.REACT_APP_DATA_ENDPOINT;

const getCollectionData = async (collectionPath) => {
    if (cachedData[collectionPath]) {
        return cachedData[collectionPath];
    }
    return cachedData[collectionPath] = axios.get(`${apiEndpoint}/${collectionPath}`, getAxiosConfig())
        .then(result => {
            return cachedData[collectionPath] = result.data;
        }).catch(parseError)
};

export default {
    getBrands: async () => {
        return getCollectionData('brands');
    },
    getDesigners: async () => {
        return getCollectionData('designers');
    },
    getPriceTypes: async () => {
        return getCollectionData('priceTypes');
    },
    getCategories: async () => {
        return getCollectionData('categories');
    },
    getCollectionData: getCollectionData
}