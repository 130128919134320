import React, {Component} from "react";
import {connect} from "react-redux";
import {Product} from "mesmetric-v2-common/models/Product";
import {AppState} from "../../../Store";
import _ from "lodash";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {updateValue} from "../../../ActionCreators/ProductData";
import RelatedProduct from "./RelatedProduct/RelatedProduct";
import "./relatedProducts.scss";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios, {AxiosRequestConfig} from "axios";
import {debounce, Popper} from "@material-ui/core";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DragAndDropArray from "../../../Helpers/DragAndDropArray";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {PopperProps} from "@material-ui/core/Popper/Popper";
import {getAxiosConfig} from "../../../ActionCreators/User";
import {parseError} from "../../../ActionCreators/Error";

interface ExternalProps {
    path: string,
    visible: boolean
}

interface StateProps {
    products: Product[],
    visible: boolean
}

interface DispatchProps {
    onChange: (value: any) => void
}

type RelatedProductsProps = StateProps & DispatchProps;

interface RelatedProductsState {
    optionsLoading: boolean,
    options?: any,
    inputValue: string
}

class RelatedProductsPopper extends Component<PopperProps> {
    public render = (): JSX.Element => <Popper {...this.props} className={"related-products-popper"}/>
}

class RelatedProducts extends Component<RelatedProductsProps, RelatedProductsState> {
    static defaultProps = {
        products: []
    };

    private cancelRequest: any;

    constructor(props: RelatedProductsProps) {
        super(props);

        this.state = {
            optionsLoading: false,
            options: [],
            inputValue: ""
        }
    }

    private onProductRemove = (product: Product) => {
        this.props.onChange(this.props.products.filter(p => p._id !== product._id))
    };

    private fetchOptions = async (searchTerm?: string) => {
        const searchParams: AxiosRequestConfig = {
            ...getAxiosConfig(),
            params: {
                condition: {
                    $or: [
                        {
                            name: {
                                $regex: '\\b' + searchTerm
                            }
                        },
                        {
                            code: {
                                $regex: '^' + searchTerm
                            }
                        }
                    ],
                    _id: {
                        $nin: this.props.products.map(p => p._id)
                    }
                }
            },
            cancelToken: new axios.CancelToken((c) => {
                this.cancelRequest = c;
            })
        };
        try {
            const {data} = await axios.get(process.env.REACT_APP_DATA_ENDPOINT + '/products/searchOld/', searchParams);
            if (this.state.optionsLoading) {
                this.setState({options: data.products, optionsLoading: false})
            }
        } catch (e) {
            parseError(e);
        }

    };

    private debounce = debounce(this.fetchOptions, 500);

    private onInput = (event: React.FormEvent<HTMLInputElement>) => {
        const value = ((event.target as HTMLInputElement).value).trim();
        this.cancelRequest && this.cancelRequest();
        this.setState({
            inputValue: value,
            options: [],
            optionsLoading: false
        });
        if (value) {
            this.setState({optionsLoading: true});
            this.debounce(value);
        }
    };

    private onAddedProduct = (_: any, newValue: any) => {
        this.setState({inputValue: "", options: [], optionsLoading: false});
        if (newValue) {
            this.props.onChange([...this.props.products, newValue])

        }
    };

    public render = (): JSX.Element =>
        <div className={"related-products"} hidden={!this.props.visible}>
            <Autocomplete
                className={"search-products"}
                PopperComponent={RelatedProductsPopper}
                value={{name: this.state.inputValue}}
                options={this.state.options || []}
                onInput={this.onInput}
                onClose={() => this.setState({optionsLoading: false, options: [], inputValue: ""})}
                renderOption={(item: any) => <RelatedProduct product={item}/>}
                getOptionLabel={option => option.name || ""}
                loadingText={"Wyszukiwanie..."}
                noOptionsText={"Brak wyników"}
                loading={this.state.optionsLoading}
                onChange={this.onAddedProduct}
                renderInput={(params) =>
                    <TextField {...params}
                               label={"Wyszukaj produkty powiązane"}
                               variant="outlined"
                               size={"small"}
                    />
                }
            />
            {this.props.products.length ?
                <DragDropContext onDragEnd={(result) => {
                    if (!result.destination) {
                        return;
                    }
                    DragAndDropArray.moveItem(this.props.products, result.source.index, result.destination.index, this.props.onChange);
                }}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <List ref={provided.innerRef}>
                                {this.props.products.map((product, index) =>
                                    <Draggable key={product._id} draggableId={product._id} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItem disableGutters
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                            >
                                                <RelatedProduct
                                                    key={product._id}
                                                    onRemove={this.onProductRemove}
                                                    product={product}
                                                />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext> : null}
            {!this.props.products.length && "Brak produktów powiązanych"}
        </div>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    products: _.get(state.ProductData.productData, externalProps.path),
    visible: externalProps.visible
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (products: Product[]) => dispatch(updateValue(externalProps.path, products))
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProducts);