import React, {Component} from "react";
import CheckboxButton from "../../Common/CheckboxButton/CheckboxButton";
import DeleteProductPopper from "../../../../Components/Poppers/DeleteProductPopper/DeleteProductPopper";
import "./controls.scss";
import CloneProductPopper from "../../../../Components/Poppers/CloneProductPopper/CloneProductPopper";
import FileCopyIcon from '@material-ui/icons/FileCopy';

interface ControlProps {
    productId: string
}

class Controls extends Component<ControlProps> {
    public render = (): JSX.Element =>
        <div className={"controls"}>
            <CheckboxButton
                path={"isActive"}
                label={"Aktywny"}
            />
            <CloneProductPopper
                productId={this.props.productId}
                customIcon={<FileCopyIcon/>}
            />
            <DeleteProductPopper
                productId={this.props.productId}
                size={"small"}
            />
        </div>
}

export default Controls;