import {ActionTypes, AllActions} from "../ActionCreators/ProductFilter";
import {initialState, ProductFilterState} from "../State/ProductFilter";
import update from "immutability-helper"

export default function ProductFilterReducer(state = initialState, action: AllActions): ProductFilterState {
    switch (action.type) {
        case ActionTypes.updateFilterField: {
            return update(state, {
                [action.field]: {
                    $set: action.value
                }
            })
        }
        default:
            return state;
    }
}

