import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import {UserData} from "../UserForm/UserForm";
import ChangeUser from "../ChangeUser/ChangeUser";
import {BackendUser} from "../../../ActionCreators/User";

interface Props {
    user: BackendUser,
    doAction: (userData: UserData) => Promise<string | void>
}

class EditUser extends ChangeUser<Props> {
    constructor(props: Props) {
        super(props, "EDIT", {
            admin: props.user.permissions?.admin
        });
    }

    protected getButton = (func: () => void): JSX.Element =>
        <Button
            size="small"
            variant="contained"
            onClick={() => {
                func();
                this.setState({
                    userData: {
                        admin: this.props.user.permissions?.admin
                    }
                })
            }}
        >
            <EditIcon/>
        </Button>;

    protected getSaveButtonLabel = (): string => "Zapisz";
}

export default EditUser;