import {Category, default as models} from "mesmetric-v2-common/models";
import axios from "axios";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import {setFilter} from "./ProductsFilters";


export enum ActionTypes {
    setCategories = "setCategories",
    addCategories = "addCategories",
    removeCategories = "removeCategories"
}

export interface SetCategoriesAction {
    type: ActionTypes.setCategories
    categories: Category[]
}

export interface AddCategoriesAction {
    type: ActionTypes.addCategories
    categories: string[]
}

export interface RemoveCategoriesAction {
    type: ActionTypes.removeCategories
    categories: string[]
}

function setCategories(categories: Category[]): SetCategoriesAction {
    return {
        type: ActionTypes.setCategories,
        categories: categories
    };
}

export const addCategories = (categoriesToAdd: string[]): ThunkAction<void, AppState, {}, any> =>
    async (dispatch, getState) => {
        const categories: string[] = getState().ProductsFilters.categories || [];
        dispatch(setFilter("categories", [...new Set([...categories, ...categoriesToAdd])]))
    };

export const clearCategories = (): ThunkAction<void, AppState, {}, any> =>
    async (dispatch, getState) => {
        dispatch(setFilter("categories", undefined))
    };

export const removeCategories = (categoriesToRemove: string[]): ThunkAction<void, AppState, {}, any> =>
    async (dispatch, getState) => {
        const categories: string[] = getState().ProductsFilters.categories || [];
        dispatch(setFilter("categories", categories.filter(id => !categoriesToRemove.some(newId => newId === id))));
    };

export function fetchCategories(): any {
    return async (dispatch: any) => {
        try {
            const categories = (await axios.get<models.Category[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/categories/tree`, getAxiosConfig())).data;
            dispatch(setCategories(categories));
        } catch (e) {
            parseError(e)
        }
    }
}

export type AllActions = SetCategoriesAction | AddCategoriesAction | RemoveCategoriesAction;