import {Product} from "mesmetric-v2-common/models/Product";
import lodash from "lodash";
import {Clear, Star, StarBorder, StarHalf} from "@material-ui/icons";
import MesmetricTable, {ColumnDefinition} from "../../Components/MesmetricTable";
import * as models from "mesmetric-v2-common/models";
import {allProductFilters} from "./Filter";
import React from "react";
import {BadIcon, OKIcon} from "../../Common/Icons";
import {badColor, okColor} from "../../Common/Colors";
import ProductActions from "../ProductCard/ProductActions/ProductActions";
import ActiveProductCheckbox from "../ProductCard/ActiveProductCheckbox";
import {ProductTemplate} from "mesmetric-v2-common/models/ProductTemplate";
import {getCroppedSrc} from "../../Utils/PhotoUtils";

const isUndefinedTransform = (field: string) => (product: Product) => {
    return lodash.get(product, field, false) ? <OKIcon/> : <BadIcon/>
};

const isEmptyTransform = (field: string) => (product: Product) => {
    const value = lodash.get(product, field, undefined);
    return !lodash.isEmpty(value) ? <OKIcon/> : <BadIcon/>
};

enum AttributeNames {
    WYMIARY = "WYMIARY",
    KOLORY = "KOLORY",
    MATERIALY = "MATERIAŁY"
};


const hasNewAttributes = (attributeName: AttributeNames) => (product: Product) => {
    if (!product.attributes) {
        return <BadIcon/>
    }

    const attributeGroup = (product.primaryCategory.template as ProductTemplate)?.attributeGroups.find(attributeGroup => attributeGroup.label.pl.toUpperCase() === attributeName);
    return !!Object.keys(product.attributes).find(id => id === attributeGroup?._id) ? <OKIcon/> : <BadIcon/>
};

const columns: ColumnDefinition<models.Product>[] = [
    {
        label: 'Aktywny',
        name: 'isActive',
        accessPath: 'isActive',
        onCLick: (event) => {
            event.stopPropagation()
        },
        transform: (row: models.Product) => {
            return <ActiveProductCheckbox
                size={'medium'}
                productId={row._id}
                isActive={row.isActive}/>
        },
        filterDefinition: allProductFilters.isActive
    },
    {
        label: 'Zdjęcie',
        name: 'photo',
        align: 'center',
        transform: (row: models.Product) => {
            if (row.photos.length === 0) {
                return <div style={{display: 'inline-flex', height: '100px', alignItems: 'center'}}><span
                    style={{marginRight: '5px'}}>Brak zdjęcia</span><Clear/></div>
            }
            return <img src={getCroppedSrc(row.photos[0], {h: 100})}/>
        },
        filterDefinition: allProductFilters.photos
    },
    {
        label: 'Nazwa',
        accessPath: 'name',
        name: 'name',
        filterDefinition: allProductFilters.name
    },
    {
        label: 'Producent',
        name: 'brands',
        accessPath: 'brand.name',
        filterDefinition: allProductFilters.brand
    },
    {
        label: 'Kod',
        name: 'code',
        accessPath: 'code',
        filterDefinition: allProductFilters.code

    },
    {
        label: 'Promo.',
        name: 'isPromoted',
        accessPath: 'isPromoted',

    },
    {
        label: 'Kolejność',
        name: 'order',
        accessPath: 'order'
    },
    {
        label: 'Nowość',
        name: 'isNew',
        accessPath: 'isNew',
    },
    {
        label: 'Nowe wymiary',
        name: 'hasSize',
        transform: hasNewAttributes(AttributeNames.WYMIARY),
        filterDefinition: allProductFilters.hasSize
    },
    {
        label: 'Nowe materiały',
        name: 'hasMaterial',
        transform: hasNewAttributes(AttributeNames.MATERIALY),
        filterDefinition: allProductFilters.hasMaterial

    },
    {
        label: 'Nowe kolory',
        name: 'hasColour',
        transform: hasNewAttributes(AttributeNames.KOLORY),
        filterDefinition: allProductFilters.hasColour
    },
    {
        label: 'Plik 2D',
        name: 'has2D',
        transform: isUndefinedTransform('files.2d'),         // filterDefinition: {
        filterDefinition: allProductFilters.has2D
    },
    {
        label: 'Plik 3D',
        name: 'has3D',
        transform: isUndefinedTransform('files.3d'),
        filterDefinition: allProductFilters.has3D
    },
    {
        label: 'Plik PDF',
        name: 'hasBrochure',
        transform: isUndefinedTransform('files.brochure'),
        filterDefinition: allProductFilters.hasBrochure
    },
    {
        label: 'Wartości',
        name: 'hasValues',
        transform: isEmptyTransform('values'),
        filterDefinition: allProductFilters.hasValues
    },
    {
        label: 'Style',
        name: 'hasStyles',
        accessPath: 'styles',
        transform: isEmptyTransform('styles'),
        filterDefinition: allProductFilters.hasStyles
    },
    {
        label: 'Angielski opis',
        name: 'englishDescription',
        accessPath: 'description.en',
        transform: isEmptyTransform('description.en'),
        filterDefinition: allProductFilters.englishDescription
    },
    {
        label: 'Kategoria',
        name: 'primaryCategory',
        accessPath: 'primaryCategory.name.pl'
    },
    {
        label: 'Status zdjęć',
        name: 'photosStatus',
        accessPath: 'photosStatus',
        transform: (row: models.Product) => {
            if (!row.photos) {
                return '-';
            }
            const oldPhotosCount = row.photos.filter(photo => photo.meta && photo.meta.isLegacy).length;
            if (oldPhotosCount === row.photos.length) {
                return <StarBorder style={{color: badColor}}/>
            }
            if (oldPhotosCount === 0) {
                return <Star style={{color: okColor}}/>;
            }
            const halfStarColor = row.photos[0].meta.isLegacy ? badColor : okColor;
            return <StarHalf style={{color: halfStarColor}}/>
        },
        filterDefinition: allProductFilters.photos
    },
    {
        label: 'Ilość zdjęć',
        name: 'photosCount',
        accessPath: 'photosStatus',
        transform: (row: models.Product) => {
            if (!row.photos) {
                return '-';
            }
            return row.photos.length
        },
        filterDefinition: allProductFilters.photoCount
    },
    {
        label: 'Akcje',
        name: 'actions',
        transform: (row: models.Product) => {
            return <ProductActions productId={row._id} displayIcon/>
        },
        onCLick: (event) => {
            event.stopPropagation()
        }
    }
]

const getRowId = (row: models.Product) => {
    return row._id;
}

const ProductsTable = (props: any) => {
    return <MesmetricTable showFilter={true} rowOnClick={props.rowOnClick} rows={props.productsList}
                           columns={columns} rowKeyCallback={getRowId}/>
}

export default ProductsTable;