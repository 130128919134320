import {ProductAttribute, ProductAttributeGroup, ProductTemplate} from "mesmetric-v2-common/models";
import * as actions from "./actions";
import {actionTypes} from "./actions";
import {Category} from "mesmetric-v2-common/models/Category";
import {ProductFilterGroup} from "mesmetric-v2-common/models/ProductFilterGroup";
import {ProductFilter} from "mesmetric-v2-common/models/ProductFilter";
//import {actions} from "./actions";


export const setTemplateData = (productTemplate: ProductTemplate): actions.setDataAction => ({
    type: actions.actionTypes.SetData,
    data: productTemplate
});

export const setCategories = (categories: Category[]): actions.setCategoriesAction => ({
    type: actions.actionTypes.SetCategories,
    data: categories
});


export const updateGroup = (attributeGroup: ProductAttributeGroup): actions.updateGroupAction => ({
    type: actionTypes.UpdateGroup,
    data: attributeGroup
});

export const removeGroup = (attributeGroup: ProductAttributeGroup): actions.removeGroupAction => ({
    type: actionTypes.RemoveGroup,
    data: {
        groupId: attributeGroup._id
    }
});

export const updateAttribute = (attribute: ProductAttribute.Any, path: string): actions.updateAttributeAction => ({
    type: actionTypes.UpdateAttribute,
    data: {
        path: path,
        attribute: attribute
    }
});

export const removeAttribute = (attribute: ProductAttribute.Any, attributeGroupId: string): actions.removeAttributeAction => ({
    type: actionTypes.RemoveAttribute,
    data: {
        groupId: attributeGroupId,
        attributeId: attribute._id
    }
});

export const updateFilter = (filter: ProductFilter,path: string): actions.updateFilterAction => ({
    type: actionTypes.UpdateFilter,
    data: {
        path: path,
        filter: filter
    }
});

export const removeFilter = (filter: ProductFilter): actions.removeFilterAction => ({
    type: actionTypes.RemoveFilter,
    data: {
        filterId: filter._id
    }
});

// export type updateAttributeAction = {
//     type: actions.UpdateAttribute,
//     data: {
//         groupId: string,
//         attribute: ProductAttribute.Any
//     }
// }
//
// export type addGroupAction = {
//     type: actions.AddGroup,
//     data: ProductAttributeGroup
// }
//
// export type addAttributeAction = {
//     type: actions.AddAttribute,
//     data: {
//         groupId: string,
//         attribute: ProductAttribute.Any
//     }
// }
//
// export type removeAttributeAction = {
//     type: actions.RemoveAttribute,
//     data: {
//         groupId: string,
//         attributeId: string
//     }
// }