import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import "./title.scss";
import {connect} from "react-redux";
import {AppState} from "../../Store";

interface ExternalProps {
    openMenu: () => void,
    subTitle: string,
    menuOpened: boolean
}

interface StateProps extends ExternalProps {
    productOpened: boolean,
    productDetails?: string
}

type Props = StateProps;

class Title extends Component<Props> {
    public render = (): JSX.Element =>
        <div className={"menu-button-title"}>
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.props.openMenu}
                edge="start"
                className={this.props.menuOpened ? "hide" : undefined}
            >
                <MenuIcon/>
            </IconButton>
            <Typography variant="h6" noWrap>
                {this.props.productOpened ?
                    this.props.productDetails :
                    `Mesmetric - Panel administracyjny${this.props.subTitle !== '' ? ' - ' + this.props.subTitle : ''}`
                }
            </Typography>
        </div>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps) => {
    const productOpened = state.ProductData.productData !== undefined;

    return {
        ...externalProps,
        productOpened,
        productDetails: productOpened ? `${state.ProductData.productData?.brand?.name || ""} | ${state.ProductData.productData?.name || ""}` : undefined
    }
};

export default connect(mapStateToProps)(Title);