import React, {useCallback, useState} from "react";
import {
    DictionaryItem as DictionaryItemModel,
    ProductAttribute,
    ProductAttributeGroup,
    ProductAttributeGroupData,
    ProductAttributeGroupWithVariantsData,
    ProductAttributeType,
    ProductAttributeVariant,
    ProductTemplate
} from "mesmetric-v2-common/models";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import Creatable from 'react-select/creatable';
import update from "immutability-helper";
import Button from "@material-ui/core/Button";
import FormDialog from "../../Components/UI/FormDialog";
import {buildUpdateSpec, getObjectId} from "../../Common/Utility";
import {Label} from "mesmetric-v2-common/models/Label";
import {cloneDeep, isEmpty} from "lodash";
import MultilanguageTextField from "../../Components/Inputs/MultilanguageTextField";
import axios from "axios";
import AbsoluteSpinner from "../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import {getAxiosConfig} from "../../ActionCreators/User";
import {parseError} from "../../ActionCreators/Error";

interface AttributesProps {
    product?: any,
    setProductData?: any,
    setProduct?: any,
    refreshProduct: () => Promise<void>
}

export const Attributes = React.memo<AttributesProps>((props) => {
    const product = props.product;
    const onAttributeChange = useCallback((newValue, groupId) => {
        if (isEmpty(newValue)) {
            props.setProductData((product: any) => {
                return update(product, buildUpdateSpec(['attributes'], {
                    $unset: [groupId]
                }));
            })
        } else {
            props.setProductData((product: any) => {
                return update(product, buildUpdateSpec(['attributes', groupId], {
                    $set: newValue
                }));
            })
        }
    }, [props.setProduct]);
    if (product === undefined) {
        return <div>Wczytuję produkt...</div>
    }
    if (product.primaryCategory.template === undefined) {
        return <div>Brak przypisanego szablonu</div>
    }
    return <Grid container
                 spacing={2}>{((product.primaryCategory.template as ProductTemplate).attributeGroups as ProductAttributeGroup[]).map(attributeGroup => {
        const value = (product.attributes !== undefined && product.attributes.hasOwnProperty(attributeGroup._id)) ? product.attributes[attributeGroup._id] : undefined;
        return <Grid item xs={4} key={`attribute-group-${attributeGroup._id}`}>
            <AttributeGroup
                onChange={onAttributeChange}
                group={attributeGroup}
                refreshProduct={props.refreshProduct}
                values={value}/>
        </Grid>
    })}</Grid>
});

type attributeValue = string[] | string | undefined;

interface AttributeGroupProps {
    group: ProductAttributeGroup,
    values: ProductAttributeGroupData | ProductAttributeGroupWithVariantsData,
    onChange: (newValue: any, id: string) => void,
    refreshProduct: () => Promise<void>
}

interface AttributeGroupItemsProps {
    group: ProductAttributeGroup,
    values: ProductAttributeGroupData,
    onChange: (newValue: any) => void,
    refreshProduct: () => Promise<void>
}

const AttributeGroupItems: React.FC<AttributeGroupItemsProps> = (props => {
    return <>{props.group.attributes.map(attribute => {
        const value = (props.values !== undefined && props.values.hasOwnProperty(attribute._id)) ? props.values[attribute._id] : undefined;
        return <AttributeItem
            onChange={newValue => {
                if (newValue === null) {
                    props.onChange(update(props.values || {}, {
                        $unset: [
                            attribute._id
                        ]
                    }));
                } else {
                    props.onChange(update(props.values || {}, buildUpdateSpec([attribute._id], {
                        $set: newValue
                    })));
                }
            }}
            onCreate={(item) => new Promise<string>(resolve => {
                if (attribute.type === ProductAttributeType.Dictionary) {
                    const itemId = getObjectId();
                    axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/dictionaries/add/', {
                        dictionaryId: attribute.dictionary._id,
                        label: item,
                        itemId
                    }, getAxiosConfig()).then(() => props.refreshProduct().then(() => resolve(itemId)).catch(parseError)).catch(parseError);
                }
            })
            }
            attributeId={attribute._id}
            key={`attribute-${attribute._id}`}
            item={attribute}
            value={value}/>
    })}
    </>
})

interface AddNewVariantModalProps {
    open: boolean,
    onClose: () => void,
    onSave: (newVariantData: ProductAttributeVariant) => void
}

const AddNewVariantModal: React.FC<AddNewVariantModalProps> = (props) => {
    const defaultLabel: Label = {
        pl: 'Nowy wariant',
        en: 'New variant'
    };
    const emptyVariant = {
        label: defaultLabel,
        attributes: {}
    }
    const [variantData, setVariantData] = useState<ProductAttributeVariant>(cloneDeep(emptyVariant));
    return <FormDialog canSave={true} saveLabel={"Dodaj"} onClose={props.onClose} open={props.open} onSave={() => {
        props.onSave(variantData);
    }} onExited={() => {
        setVariantData(cloneDeep(emptyVariant))
    }}>
        <MultilanguageTextField value={variantData.label} onLabelChange={updatedLabel => {
            setVariantData(variantData => {
                return update(variantData, {
                    label: {
                        $set: updatedLabel
                    }
                })
            });
        }
        }/>
    </FormDialog>
}

const GroupVariants = (props: {
    variants?: { [variantId: string]: ProductAttributeVariant },
    group: ProductAttributeGroup,
    onChange: (variants: any) => void,
    refreshProduct: () => Promise<void>
}) => {
    const [newModalVisible, setNewModalVisible] = useState(false);
    const [currentVariant, setCurrentVariant] = useState<string>(props.variants ? Object.keys(props.variants)[0] : '');
    const [removeConfirmationVisible, setRemoveConfirmationVisible] = useState(false);
    return <div>
        <AddNewVariantModal open={newModalVisible} onClose={() => setNewModalVisible(false)} onSave={newVariantData => {
            const id = getObjectId();
            setCurrentVariant(id);
            setNewModalVisible(false);
            props.onChange(update(props.variants || {}, {
                [id]: {
                    $set: newVariantData
                }
            }));
        }}
        />
        <Button size={"small"} variant={"outlined"} onClick={() => setNewModalVisible(true)}>Dodaj nowy wariant</Button>
        {props.variants && !isEmpty(props.variants) ?
            <>
                <FormDialog canSave={true} saveLabel={"Usuń"} onClose={() => {
                    setRemoveConfirmationVisible(false);
                }} open={removeConfirmationVisible} onSave={() => {
                    if (props.variants && !isEmpty(props.variants) && Object.keys(props.variants).length > 1) {
                        const variantIds = Object.keys(props.variants);
                        const nextAvailableVariant = variantIds.find(variantId => variantId !== currentVariant)
                        if (nextAvailableVariant) {
                            setCurrentVariant(nextAvailableVariant);
                        } else {
                            setCurrentVariant('');
                        }
                    } else {
                        setCurrentVariant('');
                    }
                    props.onChange(update(props.variants, {
                        $unset: [currentVariant]
                    }));
                    setRemoveConfirmationVisible(false);
                }}>
                    Czy na pewno chcesz usunąć wariant?
                </FormDialog>
                <Button size={"small"} variant={"outlined"} onClick={() => {
                    setRemoveConfirmationVisible(true);
                }}>Usuń wariant</Button>
                <Select onChange={(newValue) => {
                    if (!Array.isArray(newValue) && newValue) {
                        setCurrentVariant((newValue as { value: string }).value)
                    }
                }} value={currentVariant ? {
                    value: currentVariant,
                    label: props.variants[currentVariant].label.pl
                } : undefined} options={Object.entries(props.variants).map(([variantId, variantData]) => ({
                    label: variantData.label.pl,
                    value: variantId
                }))}/>
                {Object.entries(props.variants).map(([variantId, variantData]) => {
                    return <div key={`variant-${variantId}`} hidden={currentVariant !== variantId}>
                        <MultilanguageTextField value={variantData.label} onLabelChange={newLabel => {
                            props.onChange(update(props.variants, {
                                [variantId]: {
                                    label: {
                                        $set: newLabel
                                    }
                                }
                            }));
                        }
                        }/>
                        <hr/>
                        <AttributeGroupItems
                            onChange={(newValue =>
                                    props.onChange(update(props.variants, {
                                        [variantId]: {
                                            attributes: attributes => update(attributes || {}, {
                                                $set: newValue
                                            })
                                        }
                                    }))
                            )}
                            refreshProduct={props.refreshProduct}
                            group={props.group}
                            values={variantData.attributes}/>
                    </div>
                })}
            </> :
            <div>Brak wariantów</div>
        }
    </div>
}

const AttributeGroup = React.memo<AttributeGroupProps>((props) => {
    const values = props.values;
    return <div>
        <Typography>{props.group.label.pl}</Typography>
        {props.group.withVariants ?
            <GroupVariants group={props.group}
                           onChange={variantData => {
                               if (!isEmpty(variantData)) {
                                   props.onChange({variants: variantData}, props.group._id);
                               } else {
                                   props.onChange({}, props.group._id);
                               }
                           }}
                           refreshProduct={props.refreshProduct}
                           variants={values && (values as ProductAttributeGroupWithVariantsData).variants}/>
            :
            <AttributeGroupItems
                onChange={newValue =>
                    props.onChange(newValue, props.group._id)
                }
                group={props.group}
                refreshProduct={props.refreshProduct}
                values={values as ProductAttributeGroupData}/>
        }

    </div>
})
AttributeGroup.displayName = 'AttributeGroup';

interface AttributeItemProps {
    item: ProductAttribute.Any
    value?: attributeValue
    attributeId: string,
    onChange: (newValue: any) => void,
    onCreate: (newItem: string) => Promise<string>
}

interface SizeItemProps {
    item: ProductAttribute.Size,
    value?: string,
    onChange: (newValue: string) => void
}

const SizeItem = React.memo<SizeItemProps>(props => {
    return <div><TextField fullWidth value={props.value || ''} onChange={event => {
        props.onChange(event.target.value)
    }}/></div>
})
SizeItem.displayName = 'SizeItem';

interface DictionaryItemProps {
    item: ProductAttribute.Dictionary,
    values?: any,
    onChange: (newValue: any) => void,
    onCreate: (newItem: string) => Promise<string>
}

const DictionaryItem = React.memo<DictionaryItemProps>(props => {
    const [isAdding, setIdAdding] = useState(false);
    const getItems = () => props.item.dictionary.items.map(item => {
        return {
            label: item.label.pl,
            value: item._id
        }
    }).sort((a, b) => a.label.localeCompare(b.label));

    return <div style={{position: "relative"}}>
        {isAdding && <AbsoluteSpinner/>}
        <Creatable
            placeholder={"Wybierz słowa"}
            value={props.values || ''}
            onChange={props.onChange}
            isMulti
            options={getItems()}
            onCreateOption={newItem => {
                setIdAdding(true);
                props.onCreate(newItem).then(id => {
                    setIdAdding(false);
                    props.onChange((props.values || []).concat({label: newItem, value: id}))
                })
            }}
            formatCreateLabel={(newItem) => `Dodaj "${newItem}"`}
            closeMenuOnSelect={false}/>
    </div>
});
DictionaryItem.displayName = 'DictionaryItem';

const attributeComponents = {
    [ProductAttributeType.Dictionary]: DictionaryItem,
    [ProductAttributeType.Size]: SizeItem
}

function mapValuesToOptions(items: DictionaryItemModel[], selectedValues: string[]) {
    return selectedValues.map(valueId => {
        const foundItem = items.find(item => item._id === valueId);
        return {
            label: foundItem && foundItem.label.pl,
            value: valueId
        }
    })
}

const AttributeItem: React.FC<AttributeItemProps> = (props) => {
    let attributeComponent: any;
    switch (props.item.type) {
        case ProductAttributeType.Dictionary:
            attributeComponent =
                <>
                    <DictionaryItem
                        onChange={newValue => {
                            if (newValue !== null) {
                                props.onChange(newValue.sort((a: any, b: any) => a.label.localeCompare(b.label)).map((item: any) => item.value));
                            } else {
                                props.onChange(null);
                            }
                        }}
                        onCreate={props.onCreate}
                        item={props.item}
                        values={props.value && mapValuesToOptions(props.item.dictionary.items, props.value as string[])}/>
                </>;
            break;
        case ProductAttributeType.Size:
            attributeComponent = <SizeItem onChange={newValue => {
                props.onChange(newValue);
            }
            } item={props.item} value={props.value as string}/>;
            break;
    }
    return <>
        <div>{props.item.label.pl}</div>
        {attributeComponent}
    </>
}

