import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import {Photo} from "mesmetric-v2-common/models/Product";
import {getObjectId} from "../../../../../../Common/Utility";
import "./photoCrop.scss";
import Spinner from "../../../../Common/Spinner/Spinner";
import Slider from "./Slider/Slider";
import WarningIcon from "@material-ui/icons/Warning";
import Cropper, {PhotoCropDetails} from "./Cropper/Cropper";
import Guidelines from "./Guidelines/Guidelines";
import {TemplateGuideLine} from "mesmetric-v2-common/models/ProductTemplate";
import CirclePicker from "react-color/lib/components/circle/Circle";
import {RGBColor} from "react-color";
import {BorderHorizontal, BorderVertical, SvgIconComponent} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {CheckboxButtonNotConnected} from "../../../../Common/CheckboxButton/CheckboxButton";
import DialogActions from '@material-ui/core/DialogActions';

interface PhotoCropProps {
    onCancel: () => void,
    photo: Photo.Model,
    aspectName: "1:1" | "free",
    guidelines: TemplateGuideLine[],
    onChange: (photo: Photo.Model, guidelines: TemplateGuideLine[]) => void
}

export interface PhotoPaddingDetails {
    padLeft: number
    padRight: number
    padTop: number
    padBottom: number
}

interface PhotoCropState {
    photoPaddingDetails: PhotoPaddingDetails,
    photoCropDetails: PhotoCropDetails,
    cropId: string,
    aspect: string,
    isReady: boolean,
    loading: boolean,
    guidelines: TemplateGuideLine[],
    color: RGBColor,
    orientation: 'horizontal' | 'vertical',
    showGuidelines: boolean
}

class PhotoCrop extends Component<PhotoCropProps, PhotoCropState> {
    private cropWrapper: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: PhotoCropProps) {
        super(props);
        const foundCrop = this.props.photo.crops?.find(crop => crop.aspect === this.props.aspectName);
        this.state = {
            photoPaddingDetails: this.getPhotoPadding(foundCrop),
            photoCropDetails: this.getPhotoCrop(foundCrop),
            guidelines: this.props.guidelines,
            aspect: foundCrop?.aspect || this.props.aspectName,
            cropId: foundCrop?._id || getObjectId(),
            isReady: false,
            loading: true,
            color: {
                r: 244,
                g: 67,
                b: 54
            },
            orientation: "horizontal",
            showGuidelines: true
        }
    }

    private getPhotoPadding = (crop?: Photo.Crop): PhotoPaddingDetails => ({
        padLeft: crop?.padLeft || 0,
        padRight: crop?.padRight || 0,
        padTop: crop?.padTop || 0,
        padBottom: crop?.padBottom || 0
    });

    private getPhotoCrop = (crop?: Photo.Crop): PhotoCropDetails => ({
        h: crop?.h || 0,
        w: crop?.w || 0,
        x: crop?.x || 0,
        y: crop?.y || 0
    });

    private samePadding = (): boolean => new Set(Object.values(this.state.photoPaddingDetails)).size === 1

    private photoLoaded = () => this.setState({loading: false});

    private renderSlider = (label: string, field: keyof PhotoPaddingDetails) =>
        <Slider
            label={label}
            value={this.state.photoPaddingDetails[field]}
            max={1000}
            onChange={value => {
                this.setState({
                    photoPaddingDetails: {
                        ...this.state.photoPaddingDetails,
                        [field]: value
                    }
                });
            }}
        />;

    private onCropChange = (cropDetails: PhotoCropDetails) => {
        this.setState({photoCropDetails: cropDetails});
    };

    private onGuidelinesChange = (guidelines: TemplateGuideLine[]) => {
        this.setState({guidelines});
    };

    private onSave = () => {
        const photo = this.props.photo;
        let crops: Photo.Crop[] = photo.crops.filter(crop => Object.getOwnPropertyNames(crop).length);
        crops.forEach(crop => {
            crop.active = undefined;
        });
        let crop: Photo.Crop | undefined = crops.find(crop => crop.aspect === this.state.aspect);
        if (crop) {
            crops = crops.filter(c => crop && c.aspect !== crop.aspect);
            crop = {
                ...crop,
                ...this.state.photoCropDetails,
                ...this.state.photoPaddingDetails,
                active: true
            }
        } else {
            crop = {
                aspect: this.state.aspect,
                _id: getObjectId(),
                ...this.state.photoCropDetails,
                ...this.state.photoPaddingDetails,
                active: true
            }
        }
        crops = [...crops, crop];
        photo.crops = crops;
        this.props.onChange(photo, this.state.guidelines);
    };

    private renderOrientationButton = (orientation: "horizontal" | "vertical", Component: SvgIconComponent) =>
        <Button
            variant="contained"
            color={this.state.orientation === orientation ? "primary" : undefined}
            size={"small"}
            onClick={() => this.state.orientation !== orientation && this.setState({orientation: orientation})}
        >
            <Component/>
        </Button>;

    private renderPhotoControls = () =>
        <div className={"photos-controls"}>
            <div className={"left"}>
                <div className={"title"}>Odstępy</div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        {this.renderSlider("Lewy", "padLeft")}
                    </div>
                    <div className={"col-6"}>
                        {this.renderSlider("Górny", "padTop")}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        {this.renderSlider("Prawy", "padRight")}
                    </div>
                    <div className={"col-6"}>
                        {this.renderSlider("Dolny", "padBottom")}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <Slider
                            label={"Wszystkie"}
                            value={this.samePadding() ? this.state.photoPaddingDetails.padLeft : 0}
                            max={1000}
                            onChange={value => {
                                this.setState({
                                    photoPaddingDetails: {
                                        padLeft: value,
                                        padTop: value,
                                        padRight: value,
                                        padBottom: value
                                    }
                                });
                            }}
                        />
                    </div>
                    <div className={"col-6 dimensions"}>
                        Rozmiar: {Math.floor(this.state.photoCropDetails.w)} x {Math.floor(this.state.photoCropDetails.h)} {(this.state.photoCropDetails.w < 398 || this.state.photoCropDetails.h < 398) ?
                        <WarningIcon style={{fontSize: 16, color: '#ffb710'}}/> : undefined}
                    </div>
                </div>
            </div>
            <div className={"right"}>
                <div className={"colors"}>
                    <div className={"title"}>Wybierz kolor</div>
                    <CirclePicker width={"170px"}
                                  color={this.state.color}
                                  circleSize={20}
                                  onChange={color => this.setState({color: color.rgb})}
                                  circleSpacing={8}
                    />
                </div>
                <div className={"orientation"}>
                    <div className={"title"}>Wybierz kierunek</div>
                    {this.renderOrientationButton("horizontal", BorderHorizontal)}
                    {this.renderOrientationButton("vertical", BorderVertical)}
                </div>
                <div className={"options"}>
                    <div className={"title"}>Dostępne akcje</div>
                    <div className={"option"}>
                        <div>Dodawanie linii:</div>
                        <div>Alt + click</div>
                    </div>
                    <div className={"option"}>
                        <div>Usuwanie linii:</div>
                        <div>Shift + click</div>
                    </div>
                    <div className={"option"}>
                        <div>Zmiana koloru linii:</div>
                        <div>Control + click</div>
                    </div>
                </div>
                <CheckboxButtonNotConnected
                    value={this.state.showGuidelines}
                    onChange={() => this.setState({showGuidelines: !this.state.showGuidelines})}
                    label={"Linie widoczne"}
                />
            </div>
        </div>;

    public render = (): JSX.Element => {
        return <Dialog
            open={true}
            fullScreen
            className={"photo-crop"}
            onClose={this.props.onCancel}
        >
            <div className={"photos"}>
                <div className={"left"} ref={this.cropWrapper}>
                    <Cropper
                        loaded={this.photoLoaded}
                        loading={this.state.loading}
                        photoSrc={this.props.photo.srcResolved + `?pl=${this.state.photoPaddingDetails.padLeft}&pr=${this.state.photoPaddingDetails.padRight}&pt=${this.state.photoPaddingDetails.padTop}&pb=${this.state.photoPaddingDetails.padBottom}&temporary=1`}
                        cropDetails={this.state.photoCropDetails}
                        initialPadding={this.state.photoPaddingDetails}
                        actualPadding={this.state.photoPaddingDetails}
                        aspect={this.state.aspect}
                        parentRef={this.cropWrapper}
                        onChange={this.onCropChange}
                    />
                </div>
                <div className={"right"}>
                    {this.props.photo.srcResolved &&
                    <Guidelines
                        guidelines={this.state.guidelines}
                        photoCrop={this.state.photoCropDetails}
                        photoPadding={this.state.photoPaddingDetails}
                        photoSrc={this.props.photo.srcResolved}
                        aspect={this.state.aspect}
                        selectedColor={this.state.color}
                        onChange={this.onGuidelinesChange}
                        selectedOrientation={this.state.orientation}
                        showGuidelines={this.state.showGuidelines}
                    />}
                </div>
            </div>
            {this.renderPhotoControls()}
            <DialogActions className={"actions"}>
                <Button color="primary"
                        variant={"contained"}
                        onClick={this.onSave}
                >
                    Zapisz
                </Button>
                <Button color="primary" onClick={this.props.onCancel}>
                    Odrzuć
                </Button>
            </DialogActions>
            {this.state.loading && <Spinner withBackground/>}
        </Dialog>
    }

}

export default PhotoCrop;