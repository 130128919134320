import React, {Component} from "react";
import {connect} from "react-redux";
import {AppState} from "../../../../Store";
import _ from "lodash";
import {updateValue} from "../../../../ActionCreators/ProductData";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import "./autocompleteSelectField.scss";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface ExternalProps {
    path: string,
    label: string,
    optionsProvider: Promise<any>,
    optionsMapper: (item: any, index?: number, array?: any) => unknown,
    disableRemove?: boolean,
    multi?: boolean
}

interface StateProps {
    value: any,
    label: string,
    optionsProvider: Promise<any>,
    optionsMapper: (item: any, index?: number, array?: any) => any,
    disableRemove?: boolean,
    multi?: boolean
}

interface DispatchProps {
    onChange: (value: any) => void
}

type AutocompleteSelectFieldProps = StateProps & DispatchProps;

type OptionType = {
    [key: string]: any
}

interface AutocompleteSelectFieldState {
    loaded: boolean,
    options: OptionType[]
}

class AutocompleteSelectField extends Component<AutocompleteSelectFieldProps, AutocompleteSelectFieldState> {
    constructor(props: AutocompleteSelectFieldProps) {
        super(props);

        this.state = {
            loaded: false,
            options: (this.props.multi ? this.props.value || [] : [this.props.value]).filter(Boolean).map(this.props.optionsMapper)
        };
    }

    private fetchOptions = () =>
        this.props.optionsProvider.then((result: Array<any>) => {
            const options = result.map(this.props.optionsMapper);
            this.setState({
                options,
                loaded: true
            });
        });

    public render = () => {
        let props;
        if (this.props.multi) {
            props = {
                multiple: true,
                value: this.state.options.filter(option => (this.props.value || []).find((selected: any) => selected._id === option.value?._id)),
                onChange: (event: any, newValue: any) => this.props.onChange(newValue?.map((newItems: any) => newItems.value))
            }
        } else {
            props = {
                value: this.state.options.find(option => option.value?._id === this.props.value?._id) || {},
                onChange: (event: any, newValue: any) => this.props.onChange(newValue?.value)
            }
        }
        return <Autocomplete
            className={"autocomplete"}
            options={this.state.options}
            onOpen={() => !this.state.loaded && this.fetchOptions()}
            getOptionLabel={option => option.label || ""}
            noOptionsText={"Brak opcji"}
            disableClearable={this.props.disableRemove}
            loading={!this.state.loaded}
            renderInput={(params) =>
                <TextField {...params}
                           label={this.props.label}
                           variant="outlined"
                           size={"small"}
                />
            }
            {...props}
        />
    }


}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path),
    label: externalProps.label,
    optionsMapper: externalProps.optionsMapper,
    optionsProvider: externalProps.optionsProvider,
    disableRemove: externalProps.disableRemove,
    multi: externalProps.multi
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch(updateValue(externalProps.path, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteSelectField)