import {Product} from "mesmetric-v2-common/models";

export enum ActionTypes {
    setWorkingCopy="setWorkingCopy"
}

export interface SetWorkingCopyAction {
    type: ActionTypes.setWorkingCopy
    product: Product
}


export type AllActions = SetWorkingCopyAction;