import React, {Component} from 'react'
import "./attributeDictionary.scss";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {DictionaryItem} from "mesmetric-v2-common/models/DictionaryItem";
import axios from "axios";
import {ProductAttribute, ProductAttributeType} from "mesmetric-v2-common/models/ProductAttribute";
import {Dictionary} from "mesmetric-v2-common/models/Dictionary";
import {getObjectId} from "../../../../Common/Utility";
import _ from "lodash";
import {connect} from "react-redux";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import {Action} from "redux";
import {getAxiosConfig} from "../../../../ActionCreators/User";
import {parseError} from "../../../../ActionCreators/Error";

interface ExternalProps {
    index: number
    providedAttribute: ProductAttribute.Dictionary
    path: string
    onDictionaryItemAdded: (attributeIndex: number, value: Dictionary) => void,
    markWhenIncomplete?: boolean
}

interface StateProps {
    index: number
    providedAttribute: ProductAttribute.Dictionary
    productAttribute: string[]
    onDictionaryItemAdded: (attributeIndex: number, value: Dictionary) => void,
    markWhenIncomplete?: boolean
}

interface DispatchProps {
    onAttributeUpdate: (value: string[]) => void
}

interface AttributeOption {
    value: string,
    refId: string,
    selected: boolean
    inputValue?: string,
    incomplete: boolean
}

interface State {
    options: AttributeOption[]
    attributeId: string
}

type Props = StateProps & DispatchProps;

const getSelectedFilterOptionsByIds = (ids: string[], availableOptions: DictionaryItem[]): DictionaryItem[] => {
    let selectedOptions: DictionaryItem[] = [];
    ids?.forEach(id => {
        const foundItem = availableOptions.find(option => option._id === id);
        if (foundItem) {
            selectedOptions.push(foundItem);
        }
    });
    return selectedOptions;
};

class AttributeDictionary extends Component<Props, State> {
    static defaultProps = {
        productAttribute: []
    };

    constructor(props: Props) {
        super(props);
        const availableOptions = this.props.providedAttribute?.dictionary?.items;
        if (!availableOptions || availableOptions.length < 1) {
            this.state = {
                attributeId: this.props.providedAttribute._id,
                options: []
            };
            return
        }
        const selectedOptions = getSelectedFilterOptionsByIds(this.props.productAttribute, availableOptions);
        this.state = {
            attributeId: this.props.providedAttribute._id,
            options: this.getOptions(availableOptions, selectedOptions),
        };
    }

    private getOptions = (availableOptions: DictionaryItem[], selectedOptions: DictionaryItem[]): AttributeOption[] =>
        availableOptions?.map(option => {
            return {
                refId: option._id,
                value: option.label?.pl,
                incomplete: !option.label?.en,
                selected: !!selectedOptions.find(selectedOption => selectedOption?._id === option._id)
            }
        });

    componentDidUpdate(prevProps: Readonly<StateProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps !== this.props) {
            const availableOptions = this.props.providedAttribute?.dictionary?.items;
            if (!availableOptions || availableOptions.length < 1) {
                this.setState({
                    attributeId: this.props.providedAttribute._id,
                    options: []
                });
                return
            }
            const selectedOptions = getSelectedFilterOptionsByIds(this.props.productAttribute, availableOptions);
            this.setState({
                attributeId: this.props.providedAttribute._id,
                options: this.getOptions(availableOptions, selectedOptions),
            });
        }
    }

    private onCreateOption = (newValues: AttributeOption[], value: string): void => {
        const itemId = getObjectId();
        axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/dictionaries/add/', {
            dictionaryId: this.props.providedAttribute.dictionary._id,
            label: value,
            type: ProductAttributeType.Dictionary,
            itemId
        }, getAxiosConfig()).then((result): AttributeOption[] => {
            this.props.onDictionaryItemAdded(this.props.index, result.data);
            const updatedOptions = result.data?.items;
            const selectedOptions = getSelectedFilterOptionsByIds(this.props.productAttribute, updatedOptions);
            return this.getOptions(updatedOptions, selectedOptions);
        }).then((updatedOptions: AttributeOption[]) => this.onUpdateOptions(newValues, updatedOptions)).catch(parseError);
    };

    private onUpdateOptions = (newValues: AttributeOption[], updatedOptions: AttributeOption[]): any => {
        updatedOptions.forEach(option =>
            option.selected = !!newValues.find(
                (newValue: AttributeOption) => (!newValue.refId && newValue.inputValue && newValue.inputValue === option.value) || newValue.refId === option.refId
            ));
        this.setState({
            options: updatedOptions
        });
        this.props.onAttributeUpdate(updatedOptions.filter(option => option.selected).map(option => option.refId))
    };

    private onChange = (newValues: AttributeOption[]): void => {
        newValues.forEach(newOption => {
            if (newOption?.inputValue) {
                this.onCreateOption(newValues, newOption.inputValue);
                return;
            }
        });

        this.onUpdateOptions(newValues, [...this.state.options]);
    };

    private attributeOptions = createFilterOptions<AttributeOption>();

    private getOptionLabel = (option: AttributeOption | string): string => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }
        return option.value;
    };

    private getAttributeOptions = (options: AttributeOption[], params: any): AttributeOption[] => {
        const filtered = this.attributeOptions(options, params) as AttributeOption[];
        if (params.inputValue !== '') {
            if (!this.props.providedAttribute.dictionary.items.find(item => item.label.pl === params.inputValue)) {
                filtered.push({
                    inputValue: params.inputValue,
                    value: `Dodaj "${params.inputValue}"`,
                    refId: '',
                    selected: false,
                    incomplete: false
                });
            }
        }

        return filtered;
    };

    private getAutocompleteClassname = (incomplete: boolean): string => {
        const classNames = ["autocomplete-dropdown"];
        incomplete && classNames.push("incomplete");
        return classNames.join(" ");
    };

    private renderOption = (option: AttributeOption): string | JSX.Element => {
        if (option.incomplete) {
            return <>{option.value}<span className={"incomplete-option"}>(Brak tłumaczenia)</span></>;
        }
        return option.value;
    };

    render = (): JSX.Element => {
        let incomplete: boolean = !!this.props.markWhenIncomplete && this.state.options.some(option => option.incomplete);
        const getLabel = incomplete ? this.renderOption : (option: AttributeOption) => option.value;

        return <div className={"attribute"}>
            <Autocomplete
                className={this.getAutocompleteClassname(incomplete)}
                noOptionsText={"Brak opcji"}
                options={this.state.options}
                value={this.state.options.filter(option => option.selected)}
                getOptionLabel={(option: AttributeOption | string) => this.getOptionLabel(option)}
                renderOption={getLabel}
                onChange={(event: any, newValues: any) => this.onChange(newValues)}
                filterOptions={(options, params) => this.getAttributeOptions(options, params)}
                selectOnFocus
                multiple
                renderInput={(params) =>
                    <TextField {...params}
                               label={this.props.providedAttribute.label.pl}
                               variant="outlined"
                               size={"medium"}/>
                }
            />
            {incomplete && <span className={"no-translations"}>Brak niektórych tłumaczeń</span>}
        </div>
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    index: externalProps.index,
    providedAttribute: externalProps.providedAttribute,
    productAttribute: _.get(state.ProductData.productData, externalProps.path),
    onDictionaryItemAdded: externalProps.onDictionaryItemAdded,
    markWhenIncomplete: externalProps.markWhenIncomplete
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onAttributeUpdate: (value: string[]) => dispatch(updateValue(externalProps.path, value)),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AttributeDictionary);