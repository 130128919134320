import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import CardContent from "@material-ui/core/CardContent";
import './variantCard.scss'
import DeleteIcon from "@material-ui/icons/Delete";
import {red} from "@material-ui/core/colors";
import {AppState} from "../../../../../../../Store";
import _ from "lodash";
import {connect} from "react-redux";
import {ProductAttribute, ProductAttributeType} from "mesmetric-v2-common/models/ProductAttribute";
import {FileCopy} from "@material-ui/icons";

interface ExternalProps {
    path: string
    title: string
    onVariantRemove: () => void,
    newlyAdded: boolean
    variantId?: string
    attributesProvided: ProductAttribute.Any[],
    onVariantClone: () => void
}

interface StateProps {
    title: string
    onVariantRemove: () => void,
    onVariantClone: () => void,
    newlyAdded: boolean
    variantId?: string
    variantAttributes: any
    attributesProvided: ProductAttribute.Any[]
}

type Props = StateProps

interface State {
    contentExpanded: boolean
    newlyAdded: boolean
}

class VariantCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            contentExpanded: this.props.newlyAdded,
            newlyAdded: this.props.newlyAdded
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps !== this.props) {
            this.setState({
                contentExpanded: this.state.contentExpanded || this.props.newlyAdded,
                newlyAdded: this.props.newlyAdded
            })
        }
    }

    private handleExpandClick = () => {
        this.setState({
            contentExpanded: !this.state.contentExpanded,
            newlyAdded: false
        });
    };

    private getDescription = (): JSX.Element => {
        return (<> {this.props.attributesProvided.map((attributeProvided, index) => {
                const id = Object.keys(this.props.variantAttributes).find(id => id === attributeProvided._id);
                if (attributeProvided.type === ProductAttributeType.Dictionary) {
                    const values = id ? attributeProvided.dictionary.items
                        .filter(item => this.props.variantAttributes[id]
                            .includes(item._id)).map(item => item.label.pl) : [];
                    return (values?.length > 0 ?
                        <p className={"description"}
                           key={index}>{attributeProvided.label.pl}: <span>{values.toString()}</span>
                        </p> : undefined);
                } else {
                    const value = id ? this.props.variantAttributes[id] : undefined;
                    return (value ?
                        <p className={"description"} key={index}>{attributeProvided.label.pl} <span>{value} </span>
                        </p> : undefined);
                }
            })
            }</>
        );
    };

    private createVariantDescription = (): JSX.Element => {
        return <>
            {!this.props.variantAttributes || Object.keys(this.props.variantAttributes).filter(key => Object.keys(this.props.variantAttributes[key]).length !== 0).length < 1 ?
                <span>Brak przypisanych wartości do atrybutów</span> : this.getDescription()
            }
        </>
    };

    render = (): JSX.Element => {
        const prettyPrint = (value: string): string => `${value.toLowerCase().charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`;
        return (
            <Card className={"variant-card"}>
                <CardHeader
                    className={"card-header"}
                    action={
                        <>
                            <IconButton
                                className={"remove"}
                                onClick={() => this.props.onVariantRemove()}
                                aria-label="remove">
                                <DeleteIcon style={{color: red[500], fontSize: 25}}/>
                            </IconButton>
                            <IconButton
                                className={"clone"}
                                onClick={() => this.props.onVariantClone()}
                                aria-label="copy">
                                <FileCopy style={{fontSize: 25}}/>
                            </IconButton>
                            <IconButton
                                className={this.state.contentExpanded ? "expand" : "expandOpen"}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.contentExpanded}
                                aria-label="show more">
                                <ExpandMoreIcon style={{fontSize: 25}}/>
                            </IconButton>
                        </>
                    }
                    title={this.props.title ? prettyPrint(this.props.title) : "Brak nazwy"}/>
                <Collapse in={!this.state.contentExpanded} timeout="auto" unmountOnExit>
                    <CardContent className={"card-description"}>
                        {this.createVariantDescription()}
                        <div className={"overlay"}>
                            <IconButton
                                className={"icon"}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.contentExpanded}
                                aria-label="show more">
                                <ExpandMoreIcon style={{fontSize: 30}}
                                                className={this.state.contentExpanded ? "expand" : "expandOpen"}/>
                            </IconButton>
                        </div>
                    </CardContent>
                </Collapse>
                <Collapse in={this.state.contentExpanded} timeout="auto" unmountOnExit>
                    <CardContent className={"card-content"}>
                        {this.props.children}
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => {
    return ({
        title: externalProps.title,
        onVariantRemove: externalProps.onVariantRemove,
        onVariantClone: externalProps.onVariantClone,
        newlyAdded: externalProps.newlyAdded,
        variantId: externalProps.variantId,
        attributesProvided: externalProps.attributesProvided,
        variantAttributes: _.get(state.ProductData.productData, `${externalProps.path}`),
    });
};

export default connect(mapStateToProps, {})(VariantCard);