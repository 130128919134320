import 'react-image-crop/dist/ReactCrop.css';
import axios from "axios";
import {diff} from "deep-diff"

import React, {Component, Dispatch} from 'react';
import {
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    StyledComponentProps,
    Tab,
    Tabs,
    TextField as MaterialTextField,
    Typography
} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import _, {debounce, get, set} from 'lodash'
import Dictionary from "../DataProviders/Dictionary";
import AutoCompleteSelect from "../Components/Inputs/Select";
import RelatedProducts from "../Components/Inputs/RelatedProducts";
import Photos from "../Components/ProductCard/Photos";

import {Product} from "mesmetric-v2-common/models";
import SvgIcon from "@material-ui/core/SvgIcon";

import update from "immutability-helper";

import {ReactComponent as AddIcon} from '../Icons/add.svg'
import {Attributes} from "./ProductCard/Attributes";
import {connect} from "react-redux";
import {AllActions, updateProduct} from "../ActionCreators/ProductsList";
import {ProductTemplate, TemplateGuideLine} from "mesmetric-v2-common/models/ProductTemplate";
import LegacyData from "./ProductCard/LegacyData";
import MultilanguageTextField from "../Components/Inputs/MultilanguageTextField";
import TextField from "../Components/Inputs/TextField";

import {RouteComponentProps, withRouter} from "react-router";
import PhotosAndFilesDropzone from "./ProductCard/PhotosAndFilesDropzone/PhotosAndFilesDropzone";
import FilesUploaded from "./ProductCard/FilesUploaded/FilesUploaded";
import "./productCard.scss";
import ProductActions from "./ProductCard/ProductActions/ProductActions";
import ActiveProductCheckbox from "./ProductCard/ActiveProductCheckbox";
import {Features} from "mesmetric-v2-common/models/Product";
import PaddedPaper from "../Components/PaddedPaper/PaddedPaper";
import {Filters} from "./ProductCard/Filters";
import {getAxiosConfig} from "../ActionCreators/User";
import {parseError} from "../ActionCreators/Error";

export const CurrentProduct = React.createContext<Product | undefined>(undefined);
export const CurrentTemplate = React.createContext<ProductTemplate | undefined>(undefined);

const AUTO_SAVE_DISABLED_FOR_PRODUCTS = "disable-auto-save-for-products";

const MesmetricTextField = (props: any) => {
    const properties = {
        margin: 'normal',
        ...props
    };
    return (<MaterialTextField {...properties} />);
};

export const basicOptionsMapper = (item: any) => {
    return {
        label: item.name,
        value: item
    };
};

export const multiLanguageLabelMapper = (item: any) => {
    return {
        label: item.label.pl,
        value: item
    };
}

type SetProductDataFunction = (productData: Product) => void

const getFieldDefinitions = (productData: Product, setProductData: SetProductDataFunction) => ([
    {
        label: 'Nazwa',
        property: 'name',
        component: MesmetricTextField,
    },
    {
        label: 'Kolejność',
        property: 'order',
        component: MesmetricTextField,
        outputType: 'number',
        componentProps: {
            type: 'number'
        }
    },
    {
        label: 'Kod produktu',
        property: 'code',
        component: MesmetricTextField,
        componentProps: {
            InputProps: {
                endAdornment: <InputAdornment position="end"><Button onClick={(e) => {
                    const productCode = [productData.brand ? productData.brand.code : false, productData.primaryCategory.code, productData.name.replace(/[\s-]/g, '_').toLowerCase()].filter(t => t).join('_');
                    setProductData({
                        ...productData,
                        code: productCode
                    })

                }
                }>Wygeneruj</Button></InputAdornment>
            }
        }
    },
    {
        label: 'Cena',
        property: 'price.value',
        component: MesmetricTextField,
        outputType: 'number',
        componentProps: {
            type: 'number'
        }
    },
    {
        label: 'Typ ceny',
        property: 'price.type',
        component: AutoCompleteSelect,
        componentProps: {
            placeholder: 'Wybierz rodzaj ceny',
            noOptionsMessage: 'Nie można odnaleźć rodzaju ceny',
            optionsProvider: Dictionary.getCollectionData('priceTypes'),
            optionsMapper: ((item: any) => ({
                label: `${item.name} (${item.multiplier})`,
                value: item
            })),
            isMulti: false
        }
    },
    {
        label: 'Waluta',
        property: 'price.currency',
        component: AutoCompleteSelect,
        componentProps: {
            placeholder: 'Wybierz walutę',
            noOptionsMessage: 'Nie można odnaleźć waluty',
            optionsProvider: Dictionary.getCollectionData('currencies'),
            optionsMapper: ((item: any) => ({
                label: `${item.code} (${item.rate})`,
                value: item
            })),
            isMulti: false
        }
    },
    {
        label: 'Marka',
        property: 'brand',
        component: AutoCompleteSelect,
        componentProps: {
            placeholder: 'Wybierz markę',
            noOptionsMessage: 'Nie można odnaleźć marki',
            optionsProvider: Dictionary.getCollectionData('brands'),
            optionsMapper: basicOptionsMapper,
            isMulti: false
        }
    },
    {
        label: 'Projektanci',
        property: 'designers',
        component: AutoCompleteSelect,
        componentProps: {
            placeholder: 'Wybierz projektanta/projektantów',
            noOptionsMessage: 'Nie można odnaleźć projektanta',
            optionsProvider: Dictionary.getCollectionData('designers'),
            optionsMapper: basicOptionsMapper,
            isMulti: true
        }
    },
    {
        label: 'Style',
        property: 'styles',
        component: AutoCompleteSelect,
        componentProps: {
            placeholder: 'Wybierz style',
            noOptionsMessage: 'Nie można odnaleźć stylu',
            optionsProvider: Dictionary.getCollectionData('productStyles'),
            optionsMapper: multiLanguageLabelMapper,
            isMulti: true
        }
    },
    {
        label: 'Wartości',
        property: 'values',
        component: AutoCompleteSelect,
        componentProps: {
            placeholder: 'Wybierz wartości',
            noOptionsMessage: 'Nie można odnaleźć wartości',
            optionsProvider: Dictionary.getCollectionData('productValues'),
            optionsMapper: multiLanguageLabelMapper,
            isMulti: true
        }
    }
]);

function getProductDataFromLocalStorage(id: string) {
    const jsonData = localStorage.getItem(`product-${id}`);
    if (jsonData !== null) {
        return JSON.parse(jsonData) as Product;
    }
    return undefined;
}

function ProductPropertyBox(props: { productData: Product, propertyName: string, propertyLabel: string }) {
    const w = props.productData.legacyData;
    return <div>
        <div style={{fontSize: "1em", paddingBottom: "1em"}}>{props.propertyLabel.toUpperCase()}</div>
        <div>{props.productData.legacyData && props.productData.legacyData[props.propertyName as keyof typeof props.productData.legacyData]}</div>
        <hr/>
    </div>;
}

interface ProductPreviewProps extends StyledComponentProps {
    productData: Product
    isOpen: boolean
    onClose: () => void
}

const ProductPreview: React.FC<ProductPreviewProps> = props => (
    <Dialog className={props.classes && props.classes.root} open={props.isOpen} maxWidth={"lg"} fullWidth={true}
            onClose={() => {
                props.onClose();
            }}>
        <DialogContent>
            <Grid container spacing={4}>
                <Grid item xs={8}>
                    {props.productData.photos.map(photo => <img key={photo.srcResolved}
                                                                style={{width: "100%", marginBottom: '0.5em'}}
                                                                src={`${photo.srcResolved}?w=801`}/>)}
                </Grid>
                <Grid item xs={4}>
                    <div style={{
                        fontFamily: 'Jenson',
                        fontSize: '2.5em',
                        marginBottom: '1em'

                    }}>{props.productData.name}</div>
                    <div style={{display: 'flex'}}>
                        <SvgIcon component={AddIcon} viewBox={'0 0 60 60'}
                                 style={{fontSize: '1.6em', position: 'relative', top: '10px'}}/>
                        <div style={{
                            paddingLeft: '12px',
                            top: '-20px'
                        }}>MARKA:<br/><b>{props.productData.brand && props.productData.brand.name}</b></div>
                    </div>
                    <div style={{display: 'flex', marginTop: '1em'}}>
                        <SvgIcon component={AddIcon} viewBox={'0 0 60 60'}
                                 style={{fontSize: '1.6em', position: 'relative', top: '10px'}}/>
                        <div style={{
                            paddingLeft: '12px',
                            top: '-20px'
                        }}>PROJEKTANT:<br/><b>{props.productData.designers && props.productData.designers.map(designer => designer.name).join(', ')}</b>
                        </div>
                    </div>
                    <div className={props.classes && props.classes.paddedDiv}><b>CENA
                        OD {props.productData.price.calculated}</b></div>
                    <div className={props.classes && props.classes.paddedDiv}>zapytaj o cenę -></div>
                    <hr/>
                    {[
                        ['materials', 'materiały'],
                        ['colors', 'kolory'],
                        ['sizes', 'wymiary'],
                    ].map(([propertyName, propertyLabel]) => {
                        return <ProductPropertyBox key={`product-property-${propertyName}`}
                                                   productData={props.productData} propertyLabel={propertyLabel}
                                                   propertyName={propertyName}/>
                    })}
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
);

export const StyledProductPreview = withStyles(theme => ({
    root: {
        '& hr': {
            margin: '1.5em 0 1.5em 0'
        },
    },
    paddedDiv: {
        margin: '1em 0 1em 0'
    }
}))(ProductPreview);

const TabPanel: React.FC<any> = (props: any) => {
    return <div hidden={props.currentTab !== props.index}
                className={props.className}><Grid container spacing={2}>
        {props.children}
    </Grid>
    </div>
};

const getTemplateIdFromProduct = (product?: Product): string | undefined => {
    if (product === undefined) {
        return undefined;
    }
    if (!product.primaryCategory.template) {
        return undefined;
    }
    return (product.primaryCategory.template as ProductTemplate)._id || undefined;
};

interface ProductCardProps extends RouteComponentProps<{ id: string }> {
    updateProductInList: (product: Product) => void
}

let interval: any = undefined;

const ADDITIONAL_CHECKBOXES: { key: keyof Features, label: string }[] = [
    {
        key: "moreDetailsInPdf",
        label: "Więcej szczegółów w broszurze pdf"
    },
    {
        key: "moreDetailsSoon",
        label: "Więcej szczegółów wkrótce"
    },
    {
        key: "showroomWarszawa",
        label: "Zapraszamy do showroomu w Warszawie"
    },
    {
        key: "showroomGdynia",
        label: "Zapraszamy do showroomu w Gdyni"
    },
    {
        key: "dimensionsAdjustable",
        label: "Wymiary mogą zostać dopasowane do potrzeb klienta"
    },
    {
        key: "colorsAdjustable",
        label: "Kolory mogą zostać dostosowane do potrzeb klienta"
    }
];

interface ProductCardState {
    productIsLoaded: boolean,
    originalData: Product | null,
    productData: Product,
    productPreviewIsOpen: boolean,
    currentTab: number,
    isSaving: boolean,
    isModified: boolean
}

class ProductCard extends Component<ProductCardProps, ProductCardState> {
    private templateId?: string;
    private interval: any;

    constructor(props: ProductCardProps) {
        super(props);

        this.state = {
            productIsLoaded: false,
            originalData: null,
            productPreviewIsOpen: false,
            currentTab: 0,
            isModified: false,
            isSaving: false,
            productData: {
                _id: "",
                code: "",
                description: {
                    pl: ''
                },
                isActive: false,
                files: {},
                name: "",
                order: 0,
                photos: [],
                price: {
                    value: 0,
                    calculated: 0,

                    currency: {
                        code: 'PLN',
                        rate: 1
                    },
                    type: {
                        label: '-',
                        multiplier: 1
                    }
                }, brand: undefined, categories: [], designers: [], primaryCategory: {
                    slug: '',
                    path: '',
                    code: '',
                    name: {
                        pl: 'Kategoria'
                    },
                    products: []
                },
                values: [],
                styles: [],
                related: [],
                attributes: {},
                updateInfo: {
                    hasColour: false,
                    hasSize: false,
                    hasMaterial: false,
                },
                features: {
                    moreDetailsInPdf: false,
                    moreDetailsSoon: false,
                    showroomWarszawa: false,
                    showroomGdynia: false,
                    dimensionsAdjustable: false,
                    colorsAdjustable: false
                }
            }
        }
    }

    public componentDidMount(): void {
        this.getProduct().then();
    }

    public componentDidUpdate(prevProps: Readonly<ProductCardProps>, prevState: Readonly<ProductCardState>, snapshot?: any): void {
        if (!prevState.productIsLoaded && this.state.productIsLoaded && !this.autoSaveEnabled() && this.changeDetected()) {
            this.setState({isModified: true});
        }
        if (prevState.productIsLoaded && this.state.productIsLoaded) {
            const storageKey = `product-${this.state.productData._id}`;
            if (this.changeDetected()) {
                if (this.autoSaveEnabled()) {
                    this.autoSave();
                } else {
                    !this.state.isModified && this.setState({isModified: true});
                    localStorage.setItem(storageKey, JSON.stringify(this.state.productData));
                }
            } else {
                !this.autoSaveEnabled() && this.state.isModified && this.setState({isModified: false});
                localStorage.removeItem(storageKey);
            }
        }

        if (!this.autoSaveEnabled()) {
            interval && clearInterval(interval);
            interval = undefined;
        }
    }

    public componentWillUnmount(): void {
        interval && clearInterval(interval);
        interval = undefined;
    }

    private getProduct = (): Promise<void> => {
        return axios.get<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.match.params.id}`, getAxiosConfig())
            .then(result => {
                if (result.data._id === undefined) {
                    return
                }
                const productData: any = _.omit(result.data, ["createdAt", "updatedAt", "__v"]);
                this.setState({
                    originalData: productData,
                    productData: productData,
                    productIsLoaded: true
                });
            }).catch(parseError)
    };

    private changeDetected = () => diff(this.state.productData, this.state.originalData);

    private autoSaveEnabled = () => !!this.state.productData._id && !(JSON.parse(localStorage.getItem(AUTO_SAVE_DISABLED_FOR_PRODUCTS) || "[]") || []).some((productId: string) => productId === this.state.productData._id);

    private getChangeHandler = (fieldName: any, fieldType?: any) => (event: any) => {
        let value = event.target.value;
        if (fieldType && fieldType === 'number') {
            value = parseInt(value);
        }
        const updatedProduct = update(this.state.productData, set({}, fieldName, {$set: value}));
        this.setState({productData: updatedProduct});
    };

    private onRelatedProductsChange = (updatedRelatedProducts: any) => {
        this.setState({productData: {...this.state.productData, related: updatedRelatedProducts}});
    };

    private onGuidesUpdated = (guides: TemplateGuideLine[]) => {
        if (!this.templateId) {
            return;
        }
        this.patchTemplateGuidesCallback(guides, this.templateId);
        const updatedProduct = {...this.state.productData};
        _.set(updatedProduct, "primaryCategory.template.guidelines", guides);
        this.setState({
            productData: updatedProduct
        });
    };

    private patchTemplateGuidesCallback = debounce((guides: TemplateGuideLine[], templateId: string) => {
        axios.patch(process.env.REACT_APP_DATA_ENDPOINT + `/templates/${templateId}/guidelines`, guides, getAxiosConfig()).catch(parseError);
    }, 200);

    private clearEmpties = <T extends any, >(o: T) => {
        Object.getOwnPropertyNames(o).forEach(k => {
            if (!o[k] || typeof o[k] !== "object") {
                return;
            }

            if (Array.isArray(o[k])) {
                o[k].forEach(this.clearEmpties);
                return;
            }
            this.clearEmpties(o[k]);
            if (Object.keys(o[k]).length === 0) {
                delete o[k];
            }
        });

        return o
    };

    private save = () => {
        this.setState({isSaving: true});
        axios.put<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.match.params.id}`, this.state.productData, getAxiosConfig())
            .then(result => {
                const productData: any = _.omit(result.data, ["createdAt", "updatedAt", "__v"]);
                this.setState({
                    originalData: productData,
                    productData: this.clearEmpties(this.state.productData)
                });
                this.props.updateProductInList(result.data);
            }).catch(parseError)
            .finally(() => {
                this.setState({isSaving: false});
            })
    };

    private autoSave = () => {
        if (interval) {
            clearInterval(interval);
            interval = undefined;
        }

        if (!interval) {
            interval = setInterval(() => {
                !this.state.isSaving && this.changeDetected() && this.save();
            }, 2000);
        }
    };

    public render = (): JSX.Element => {
        this.templateId = getTemplateIdFromProduct(this.state.productData);
        return (
            <PaddedPaper className={"main-content"}>
                <Collapse in={!this.state.productIsLoaded}>
                    Wczytuję produkt
                </Collapse>
                <Collapse in={this.state.productIsLoaded} className={"product-card"}>
                    {this.state.productIsLoaded &&
                    <CurrentProduct.Provider value={this.state.productData}>
                        <StyledProductPreview productData={this.state.productData}
                                              isOpen={this.state.productPreviewIsOpen}
                                              onClose={() => this.setState({productPreviewIsOpen: false})
                                              }/>
                        <Grid container alignItems={"center"} className={"tabs"}>
                            <Tabs value={this.state.currentTab}
                                  onChange={(e, newTab) => this.setState({currentTab: newTab})}>
                                <Tab label={"Główne"}/>
                                <Tab label={"Atrybuty szablonu"}/>
                                <Tab label={"Filtry"}/>
                                <Tab label={"Opis i notatki"}/>
                            </Tabs>
                            {!this.autoSaveEnabled() &&
                            <Button
                                variant="contained"
                                disabled={this.state.isSaving || !this.state.isModified}
                                onClick={this.save}>
                                {this.state.isSaving ? "Zapisuję..." : "Zapisz zmiany"}
                            </Button>}
                            {!this.autoSaveEnabled() &&
                            <Button
                                disabled={this.state.isSaving || !this.state.isModified}
                                variant="contained"
                                onClick={() => {
                                    if (this.state.originalData != null) {
                                        const originalData: Product = {...this.state.originalData};
                                        _.set(originalData, "primaryCategory.template", this.state.productData.primaryCategory.template);
                                        this.setState({productData: originalData});
                                    }
                                }}>
                                Cofnij zmiany
                            </Button>}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={this.state.isSaving}
                                        checked={this.autoSaveEnabled()}
                                        onChange={event => {
                                            const autoSaveDisabledForProducts: string[] = JSON.parse(localStorage.getItem(AUTO_SAVE_DISABLED_FOR_PRODUCTS) || "[]") || [];
                                            if (event.target.checked) {
                                                localStorage.setItem(AUTO_SAVE_DISABLED_FOR_PRODUCTS, JSON.stringify(autoSaveDisabledForProducts.filter(productId => productId !== this.state.productData._id)));
                                            } else {
                                                localStorage.setItem(AUTO_SAVE_DISABLED_FOR_PRODUCTS, JSON.stringify(autoSaveDisabledForProducts.concat(this.state.productData._id)));
                                            }
                                            this.setState({});
                                        }}
                                    />}
                                label={this.autoSaveEnabled() && this.state.isSaving ? "Zapisuję..." : "Automatyczny zapis"}/>
                        </Grid>
                        <TabPanel currentTab={this.state.currentTab} index={0} className={"product-content"}>
                            <Grid item xs={6}>
                                <FormControl component='fieldset' fullWidth={true}>
                                    {getFieldDefinitions(this.state.productData, (productData: Product) => this.setState({productData})).map(fieldDefinition => {
                                        const Component = fieldDefinition.component;
                                        const componentProps = fieldDefinition.componentProps || {};
                                        const props = {
                                            ...componentProps,
                                            key: fieldDefinition.label + fieldDefinition.property,
                                            label: fieldDefinition.label,
                                            onChange: this.getChangeHandler(fieldDefinition.property, fieldDefinition.outputType),
                                            value: get(this.state.productData, fieldDefinition.property)
                                        };
                                        return <Component {...props}/>
                                    })}
                                    <Grid container spacing={1} className={"additional-checkboxes"}>
                                        {ADDITIONAL_CHECKBOXES.map((checkbox, index) =>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            size={"small"}
                                                            checked={this.state.productData?.features?.[checkbox.key]}
                                                            onChange={event => {
                                                                this.setState({
                                                                    productData: {
                                                                        ...this.state.productData,
                                                                        features: {
                                                                            ...(this.state.productData.features || {}),
                                                                            [checkbox.key]: event.target.checked
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                        />}
                                                    label={checkbox.label}/>
                                            </Grid>
                                        )}
                                    </Grid>

                                    <RelatedProducts
                                        // @ts-ignore
                                        relatedProducts={this.state.productData.related}
                                        currentId={this.state.productData._id}
                                        onChange={this.onRelatedProductsChange}
                                    />
                                    <ProductActions
                                        productId={this.state.productData._id}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={
                                    <ActiveProductCheckbox
                                        productId={this.state.productData?._id}
                                        isActive={this.state.productData?.isActive}
                                        onChange={event => {
                                            this.setState({
                                                productData: {
                                                    ...this.state.productData,
                                                    isActive: event.target.checked
                                                }
                                            })
                                        }}/>}
                                                  label={"Aktywny"}/>
                                <Grid item xs={12}>
                                    <PhotosAndFilesDropzone
                                        showTitle={true}
                                        onPictureAdded={(photo) => this.setState({
                                            productData: {
                                                ...this.state.productData,
                                                photos: [...this.state.productData.photos, photo]
                                            }
                                        })}
                                        onFileAdded={(file) => this.setState({
                                            productData: {
                                                ...this.state.productData,
                                                files: {...this.state.productData.files, ...file}
                                            }
                                        })}
                                        productId={this.state.productData._id}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Photos onGuidesUpdated={this.onGuidesUpdated}
                                                    photos={this.state.productData.photos}
                                                    onChange={(value: any) => {
                                                        this.setState({
                                                            productData: update(this.state.productData, {
                                                                photos: {
                                                                    $set: value instanceof Function ? value(this.state.productData.photos) : value
                                                                }
                                                            })
                                                        });
                                                    }}/>
                                            <Button variant={"contained"} onClick={() =>
                                                this.setState({productPreviewIsOpen: true})
                                            }>Otwórz podgląd</Button>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <FilesUploaded
                                                onFilesChanged={updatedFiles => {
                                                    this.setState({
                                                        productData: update(this.state.productData, {
                                                            files: files => update(files || {}, {
                                                                $set: updatedFiles
                                                            })
                                                        })
                                                    })
                                                }}
                                                productId={this.state.productData._id}
                                                files={this.state.productData.files}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </TabPanel>
                        <TabPanel currentTab={this.state.currentTab} index={1} className={"product-content"}>
                            <Grid item xs={12} className={"filters-tab"}>
                                {this.state.productData.legacyData && <LegacyData
                                    colors={this.state.productData.legacyData.colors}
                                    materials={this.state.productData.legacyData.materials}
                                    sizes={this.state.productData.legacyData.sizes}/>}
                                <hr/>
                                <Attributes product={this.state.productData}
                                            refreshProduct={() => new Promise<void>(resolve => this.getProduct().then(resolve))}
                                            setProductData={(setProductData: any) => {
                                                this.setState({productData: setProductData(this.state.productData)});
                                            }}/>
                            </Grid>
                        </TabPanel>
                        <TabPanel currentTab={this.state.currentTab} index={2} className={"product-content"}>
                            <Grid item xs={12} className={"filters-tab"}>
                                <Filters product={this.state.productData}
                                         refreshProduct={() => new Promise<void>(resolve => this.getProduct().then(resolve))}
                                         setProductData={(setProductData: any) => {
                                             this.setState({productData: setProductData(this.state.productData)});
                                         }}/>

                            </Grid>
                        </TabPanel>
                        <TabPanel currentTab={this.state.currentTab} index={3} className={"product-content"}>
                            <Grid item xs={12}>
                                <Typography>Opis i notatki</Typography>
                                <MultilanguageTextField value={this.state.productData.description}
                                                        onLabelChange={(updatedLabel =>
                                                                this.setState({productData: update(this.state.productData, {description: {$set: updatedLabel}})})
                                                        )} textFieldProps={{multiline: true, fullWidth: true}}
                                                        emitChangeOnBlur/>
                                <TextField value={this.state.productData.notes}
                                           onValueChange={(changedValue =>
                                                   this.setState({
                                                       productData: {
                                                           ...this.state.productData,
                                                           notes: changedValue
                                                       }
                                                   })
                                           )}
                                           textFieldProps={{multiline: true, fullWidth: true}}
                                           emitChangeOnBlur
                                           label={"Notatki"}/>
                            </Grid>
                        </TabPanel>
                    </CurrentProduct.Provider>
                    }</Collapse>
            </PaddedPaper>
        );
    }
}

export default ProductCard

const mapDispatchToProps = (dispatch: Dispatch<AllActions>) => ({
    updateProductInList: (product: Product) => {
        dispatch(updateProduct(product));
    }
});
export const ConnectedProductCard = withRouter(connect(null, mapDispatchToProps)(ProductCard));