import React from "react";
import ChangeItem from "../ChangeItem/ChangeItem";
import IconButton from "@material-ui/core/IconButton";
import {green} from "@material-ui/core/colors";
import AddCircleRounded from "@material-ui/icons/AddCircleRounded";

class AddItem extends ChangeItem {
    constructor(props: any) {
        super(props, "ADD", {
            priceMultiplier: 1,
            isActive: true
        });
    }

    protected getButton = (func: () => void): JSX.Element =>
        <IconButton
            color={"primary"}
            onClick={func}
            className={this.props.className}
        >
            <AddCircleRounded style={{color: green[500], fontSize: 25}}/>
        </IconButton>;

    protected buttonDisabled = (): boolean =>
        !this.state.userData?.name || !this.state.userData.code || this.state.userData.priceMultiplier === undefined || !!this.props.otherItems?.some(item => item.name === this.state.userData?.name || item.code === this.state.userData?.code);

    protected getSaveButtonLabel = (): string => "Dodaj";
}

export default AddItem;