import React from "react";
import {Files as FilesModel} from "mesmetric-v2-common/models/Product";
import {Product} from "mesmetric-v2-common/models";
import {Grid, Paper, Typography} from "@material-ui/core";
import "./filesUploaded.scss";
import ImageOutlined from "@material-ui/icons/ImageOutlined";
import Filter2Outlined from "@material-ui/icons/Filter2Outlined";
import Filter3Outlined from "@material-ui/icons/Filter3Outlined";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import Button from "@material-ui/core/Button";
import update from "immutability-helper";

const fileTypes: {
    [index in keyof FilesModel]: string
} = {
    '2d': "2D",
    '3d': "3D",
    'brochure': "Broszura"
};

const files: { type: keyof FilesModel, label: string, getIcon: () => JSX.Element }[] = [
    {
        type: 'brochure',
        label: 'Broszura',
        getIcon: () => <ImageOutlined/>
    },
    {
        type: '2d',
        label: '2D',
        getIcon: () => <Filter2Outlined/>
    },
    {
        type: '3d',
        label: '3D',
        getIcon: () => <Filter3Outlined/>
    }
];

const FilesUploaded = (props: {
    files?: FilesModel,
    productId: Product["_id"],
    onFilesChanged: (files: FilesModel) => void
}) => {
    const fileAvailable = (fileType: keyof typeof fileTypes): boolean => !!props.files && !!props.files[fileType];

    const getFileTypeClassNames = (fileType: keyof typeof fileTypes): string => {
        const classNames = ["file-type"];
        if (fileAvailable(fileType)) {
            classNames.push("uploaded");
        }
        return classNames.join(" ");
    };

    return <>
        <Typography>Pliki</Typography>
        <Grid container spacing={2} className={"files-uploaded"}>
            {files.map((file, index) => {
                    const uploadedFile = props.files && props.files[file.type];
                    const fileSrc = uploadedFile && uploadedFile.srcResolved || "";
                    return (
                        <Grid item xs={12} className={"file-uploaded"} key={index}>
                            <Paper className={getFileTypeClassNames(file.type)}>
                                {file.getIcon()}
                                {file.label}
                            </Paper>
                            <div className={"file-control"}>
                                <Button
                                    disabled={!fileAvailable(file.type)}
                                    size="small"
                                    href={fileSrc}
                                    target={"_blank"}
                                    variant="contained">
                                    <span>Pobierz</span>
                                    <CloudDownloadOutlined/>
                                </Button>
                                <Button
                                    disabled={!fileAvailable(file.type)}
                                    size="small"
                                    variant="contained"
                                    onClick={(): void => props.onFilesChanged(update(props.files || {}, {
                                        $unset: [file.type]
                                    }))}
                                    color="secondary">
                                    <DeleteIcon/>
                                </Button>
                            </div>
                        </Grid>)
                }
            )}
        </Grid>
    </>
};

export default FilesUploaded;