import React, {
    CSSProperties,
    Dispatch,
    MouseEventHandler,
    Reducer,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState
} from "react";
import FormDialog, {FormDialogProps} from "../UI/FormDialog";
import ReactCrop, {Crop} from "react-image-crop";
import {Grid, makeStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {getElementHeight, getElementWidth} from "../../Helpers/SizeHelper";
import {Photo, ProductTemplate, TemplateGuideLine} from "mesmetric-v2-common/models";
import update from "immutability-helper"
import {getObjectId} from "../../Common/Utility";
import lodash from "lodash"
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import {BorderHorizontal, BorderVertical} from "@material-ui/icons";
import clsx from "clsx";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";

import WarningIcon from '@material-ui/icons/Warning';
import {CurrentProduct} from "../../Views/ProductCard";
import {ColorResult} from "react-color";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CirclePicker from "react-color/lib/components/circle/Circle";

const useStyles = makeStyles(theme => (
    {
        crop: {
            backgroundColor: '#fff',
            height: '100%',
            width: '100%',
            '& > div': {
                height: '100%'
            }
        },
        guideLine: {
            cursor: 'pointer',
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,0)',
            zIndex: 100,
            transition: '0.2s background-color',
            '& .line': {
                position: 'absolute',
                backgroundColor: '#f0f',
                '&:hover': {
                    backgroundColor: '#000'
                }
            },
            '&.horizontal': {
                width: '100%',
                height: '10px',
                '& .line': {
                    height: '1px',
                    width: '100%',
                    top: '5px',
                }
            },
            '&.vertical': {
                height: '100%',
                width: '10px',
                '& .line': {
                    width: '1px',
                    height: '100%',
                    left: '5px',
                }
            },
            '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.75)',
                '& .line': {
                    backgroundColor: '#000',
                }
            },

            '& div': {
                transition: '0.2s background-color',
                backgroundColor: '#f0f'
            }
        }
    }
));

const StyledDialog = withStyles({
    paper: {
        height: '100vh'
    }
})(FormDialog);

const getImageScale = (image: HTMLImageElement) => {
    const naturalRatio = image.naturalWidth / image.naturalHeight;
    const imageRatio = image.width / image.height;
    return naturalRatio > imageRatio ? (image.naturalWidth / image.width) : (image.naturalHeight / image.height);
};

interface PhotoCropperProps extends Omit<FormDialogProps, 'title' | 'children'> {
    aspect?: number,
    aspectName: string,
    src: string,
    currentCrop?: Photo.Crop,
    onGuidesUpdated: (guides: TemplateGuideLine[]) => void,
    guidelines?: TemplateGuideLine[]
}

type RGB = {
    r: number,
    g: number,
    b: number
}

interface CommonGuideProperties {
    onShiftMouseDown?: () => void
    onControlMouseDown?: () => void
    color?: RGB
}

interface BaseGuideProperties extends CommonGuideProperties {
    onMouseMove: (e: MouseEvent) => void
    lineDivStyle: CSSProperties
    className: string
}

interface GuideLineProperties extends CommonGuideProperties {
    container: HTMLDivElement | undefined
    position: number
    onUpdate: (position: number) => void
}


const useDraggableGuide = (onMouseMove: (e: MouseEvent) => void): MouseEventHandler => {
    const [isDragging, setIsDragging] = useState(false);
    const onMouseUp = () => {
        setIsDragging(false)
    };
    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mouseup', onMouseUp);
            document.addEventListener('mousemove', onMouseMove);
        }
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        }
    }, [isDragging]);

    return e => {
        e.preventDefault();
        setIsDragging(true)
    };
};

const GuideComponent: React.FC<BaseGuideProperties> = (props) => {
    const classes = useStyles();
    const onMouseDown = useDraggableGuide(props.onMouseMove);
    const color = props.color || {r: 255, g: 0, b: 255};
    return <div
        onMouseDown={e => {
            onMouseDown(e);
            if (e.shiftKey && props.onShiftMouseDown) {
                props.onShiftMouseDown();
            }
            if (e.ctrlKey && props.onControlMouseDown) {
                props.onControlMouseDown();
            }
        }}
        className={clsx(classes.guideLine, props.className)}
        style={{
            ...props.lineDivStyle
        }}>
        <div className={'line'} style={{
            backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 255)`,
        }}/>
    </div>
}

const HorizontalGuide = (props: GuideLineProperties) => {
    return <GuideComponent
        onControlMouseDown={props.onControlMouseDown}
        onShiftMouseDown={props.onShiftMouseDown}
        color={props.color}
        onMouseMove={e => {
            if (!props.container) {
                return;
            }
            const rect = props.container.getBoundingClientRect();
            const yPercent = (e.clientY - rect.top - 5) / rect.height;
            props.onUpdate(yPercent * 100);
        }}
        lineDivStyle={{
            top: props.position + '%'
        }}
        className={'horizontal'}
    />
}

const VerticalGuide = (props: GuideLineProperties) => {
    return <GuideComponent
        onControlMouseDown={props.onControlMouseDown}
        onShiftMouseDown={props.onShiftMouseDown}
        color={props.color}
        onMouseMove={e => {
            if (!props.container) {
                return;
            }
            const rect = props.container.getBoundingClientRect();
            const xPercent = (e.clientX - rect.left - 5) / rect.width;
            props.onUpdate(xPercent * 100);
        }}
        lineDivStyle={{
            left: props.position + '%'
        }}
        className={'vertical'}
    />
}

const guidesStorageKey = 'template-guides';

interface ColorPickerDialogProps {
    open: boolean
    onChange: (color: ColorResult) => void
    onClose: () => void
}

const ColorPickerDialog: React.FC<ColorPickerDialogProps> = (props) => {
    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogContent style={{
            padding: '24px 32px'
        }}>
            <CirclePicker onChange={props.onChange}/>
        </DialogContent>
    </Dialog>
}

const getGuideReducer: (onGuidesUpdated: (guides: TemplateGuideLine[]) => void) => Reducer<TemplateGuideLine[], any> = (onGuidesUpdated => {
    return (guides, action) => {
        switch (action.type) {
            case 'update': {
                const guideToUpdate = guides.findIndex(currentGuide => currentGuide._id === action.id);
                const updatedGuides = update(guides, {
                    [guideToUpdate]: {
                        position: {
                            $set: action.position
                        }
                    }
                });
                onGuidesUpdated(guides);
                // saveGuidesInTemplate(guides, action.templateId);
                return updatedGuides;
            }
            case 'setColor': {
                const guideToUpdate = guides.findIndex(currentGuide => currentGuide._id === action.id);
                const updatedGuides = update(guides, {
                    [guideToUpdate]: {
                        color: {
                            $set: action.color
                        }
                    }
                });
                onGuidesUpdated(updatedGuides);
                //saveGuidesInTemplate(guides, action.templateId);
                return updatedGuides;
            }
            case 'add': {
                const updatedGuides = update(guides, {
                    $push: [action.guide]
                });
                onGuidesUpdated(updatedGuides);
                //saveGuidesInTemplate(guides, action.templateId);


                return updatedGuides;
            }
            case 'remove': {
                const guideToDelete = guides.findIndex(currentGuide => currentGuide._id === action.id);
                const updatedGuides = update(guides, {
                    $splice: [
                        [guideToDelete, 1]
                    ]

                });
                // saveGuidesInTemplate(guides, action.templateId);
                onGuidesUpdated(updatedGuides);
                return updatedGuides;
            }
            default:
                return guides;
        }
    }
})

let z: any;

function PaddingSlider(props: { value: number, label: string, property: keyof Photo.Crop, stateSetter: Dispatch<SetStateAction<Photo.Crop>> }) {
    const setState = useCallback((newValue) => {
        props.stateSetter(crop => update(crop, {
                [props.property]: {
                    $set: newValue
                }
            })
        )
    }, [props.stateSetter, props.property]);
    return <Grid item xs={12} container spacing={1} alignItems={"center"}>
        <Grid item xs={2}>
            {props.label}
        </Grid>
        <Grid item xs={2}>
            <TextField value={props.value} onChange={e => {
                setState(e.target.value);
            }}/>
        </Grid>
        <Grid item xs={8}>
            <Slider value={props.value} max={1000} onChange={(e, newValue) => {
                setState(newValue);
            }}/>
        </Grid>
    </Grid>;
}

export default ({
                    src,
                    currentCrop,
                    aspect,
                    aspectName,
                    onGuidesUpdated,
                    guidelines,
                    ...dialogProps
                }
                    : PhotoCropperProps) => {
    const classes = useStyles();
    const product = useContext(CurrentProduct);
    const debugMode = false;
    const [reactCrop, setReactCrop] = useState<Crop>(aspect !== undefined ? {aspect: aspect} : {});
    const [debugData, setDebugData] = useState<any>({});
    const [photoCrop, setPhotoCrop] = useState<Photo.Crop>((!lodash.isEmpty(currentCrop) && currentCrop) || {
        w: 0, h: 0, x: 0, y: 0, padLeft: 0, padBottom: 0, padRight: 0, padTop: 0, _id: getObjectId(), aspect: aspectName
    });
    const imgSrc = src + `?pl=${photoCrop.padLeft}&pr=${photoCrop.padRight}&pt=${photoCrop.padTop}&pb=${photoCrop.padBottom}&temporary=1`
    const [guidesVisible, setGuidesVisible] = useState(true);
    const guideReducer = useMemo(() => getGuideReducer(onGuidesUpdated), [onGuidesUpdated]);
    const [guides, guidesDispatch] = useReducer(guideReducer, [], () => {
        if (!product) {
            return guidelines || [];
        }
        if (!product || !product.primaryCategory.template) {
            return [];
        }
        const template = product.primaryCategory.template as ProductTemplate;
        return template.guidelines ? template.guidelines : [];
    });
    const [croppedUrl, setCroppedUrl] = useState('');
    const [cropComponentStyle, setCropComponentStyle] = useState<CSSProperties>({
        width: '100%',
        height: '100%',
    });
    const [croppedImageStyle, setCroppedImageStyle] = useState<CSSProperties>({
        width: '100%',
        height: '100%',
    });
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [editedGuideId, setEditedGuideId] = useState<string | undefined>(undefined);

    useEffect(() => {
        const onWindowResize = (ev: any) => {
            if (!imageRef.current) {
                return;
            }
            setReactCropParams(imageRef.current)
            setCroppedImageParams()
        }
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        }
    }, [photoCrop]);

    const imageRef = useRef<HTMLImageElement | null>(null);
    const cropperContainerRef = useRef<HTMLDivElement | null>(null);
    const croppedImageRef = useRef<HTMLImageElement | null>(null);
    const croppedImageContainerRef = useRef<HTMLDivElement | null>(null);
    const croppedImageWrapperRef = useRef<HTMLDivElement | null>(null);

    function getCroppedUrl(crop: Photo.Crop) {
        return src + `?cx=${crop.x}&cy=${crop.y}&cw=${crop.w}&ch=${crop.h}&pl=${crop.padLeft}&pr=${crop.padRight}&pt=${crop.padTop}&pb=${crop.padBottom}`;
    }

    const onCropComplete = useCallback<(crop: Crop) => void>(crop => {
        if (!imageRef.current || crop.width === undefined || crop.height === undefined || crop.x === undefined || crop.y === undefined) {
            return;
        }
        const image = imageRef.current;
        const scale = getImageScale(image);
        setDebugData({
            scale,
            naturalWidth: image.naturalWidth,
            naturalHeight: image.naturalHeight,
            width: image.width,
            height: image.height,
        });
        const cropX = Math.floor((crop.x * scale));
        const cropY = Math.floor((crop.y * scale));
        const cropW = Math.floor(crop.width * scale);
        const cropH = Math.floor(crop.height * scale);
        const calculatedCrop = {
            x: cropX,
            y: cropY,
            w: Math.min(cropW, image.naturalWidth - cropX),
            h: Math.min(cropH, image.naturalHeight - cropY),
        };
        const updatedCrop = update(photoCrop, {
            $merge: calculatedCrop
        });
        setPhotoCrop(updatedCrop);
        setCroppedUrl(getCroppedUrl(updatedCrop));
    }, [imgSrc, photoCrop, reactCrop]);

    function setCroppedImageParams() {
        if (!croppedImageContainerRef.current || !croppedImageRef.current) {
            return;
        }
        const container = croppedImageContainerRef.current;
        const containerWidth = getElementWidth(container);
        const containerHeight = getElementHeight(container);
        const containerRatio = containerWidth / containerHeight;
        let imageHW: { width: string, height: string };
        if (containerRatio > 1) {
            imageHW = {
                height: '100%',
                width: String(100 / (containerWidth / (containerHeight))) + '%'
            };
        } else {
            imageHW = {
                height: String(100 / (containerHeight / (containerWidth))) + '%',
                width: '100%'
            };
        }
        setCroppedImageStyle(style => {
            return update(style, {
                $merge: imageHW
            })
        });
    }

    function setReactCropParams(image: HTMLImageElement) {
        let container = cropperContainerRef.current;
        if (container) {
            const containerWidth = getElementWidth(container);
            const containerHeight = getElementHeight(container);
            const containerRatio = containerWidth / containerHeight;
            const imageRatio = image.naturalWidth / image.naturalHeight
            let cropHW: { width: string, height: string };
            if (containerRatio > imageRatio) {
                cropHW = {
                    height: '100%',
                    width: String(100 / (containerWidth / (containerHeight * imageRatio))) + '%'
                };
            } else {
                cropHW = {
                    height: String(100 / (containerHeight / (containerWidth / imageRatio))) + '%',
                    width: '100%'
                };
            }
            setCropComponentStyle((cropComponentStyle => update(cropComponentStyle, {$merge: cropHW})));
        }
        const scale = getImageScale(image);
        setReactCrop((reactCrop) => {
            setPhotoCrop(photoCrop);
            return update(reactCrop, {
                $merge: {
                    x: photoCrop.x / scale,
                    y: photoCrop.y / scale,
                    width: photoCrop.w / scale,
                    height: photoCrop.h / scale
                }
            });
        });
    }

    const onImageLoaded = useCallback<(target: HTMLImageElement) => void>((target) => {
        imageRef.current = target;
        setTimeout(() => {
            setReactCropParams(target);
            setCroppedUrl(getCroppedUrl(photoCrop));
        }, 0);
    }, [imgSrc])

    function addNewGuide(guideType: 'vertical' | 'horizontal') {
        return () => {
            const newGuide: TemplateGuideLine = {
                position: 50,
                type: guideType,
                _id: getObjectId(),
                color: {r: 255, g: 0, b: 255},
            };
            guidesDispatch({type: 'add', guide: newGuide});
        };
    }

    return (
        <StyledDialog {...dialogProps} maxWidth={'xl'} canSave={true} isLoading={false}
                      onSave={() => {
                          if (!dialogProps.onSave) {
                              return;
                          }
                          dialogProps.onSave(photoCrop);
                      }}>
            <ColorPickerDialog
                open={colorPickerVisible}
                onClose={() => {
                    setColorPickerVisible(false);
                }}
                onChange={
                    color => {
                        guidesDispatch({
                            type: 'setColor',
                            id: editedGuideId,
                            color: color.rgb
                        });
                        setColorPickerVisible(false)
                    }
                }/>
            <Grid container style={{height: '100%'}} spacing={1}>
                <Grid item xs={6} style={{
                    height: 'calc( 100% - 48px )',

                }} ref={cropperContainerRef} container>
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: '#eee', alignItems: 'center',
                        justifyContent: 'center', display: 'flex'
                    }}>
                        <ReactCrop
                            className={classes.crop}
                            style={cropComponentStyle}
                            imageStyle={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain'
                            }}
                            crop={reactCrop}
                            src={imgSrc}
                            onChange={crop => {
                                setReactCrop(crop);
                            }}
                            onImageLoaded={onImageLoaded}
                            onComplete={onCropComplete}
                            crossorigin={"anonymous"}
                        />
                    </div>
                </Grid>
                <Grid item xs={6} style={{height: 'calc( 100% - 48px )'}}>
                    <div ref={croppedImageContainerRef} style={{
                        width: '100%', height: '100%', backgroundColor: '#eee', alignItems: 'center',
                        justifyContent: 'center', display: 'flex', position: 'relative'
                    }}>
                        {debugMode && <div style={{position: 'absolute'}}>
                            cX: {reactCrop.x} cY: {reactCrop.y} cW: {reactCrop.width} cH: {reactCrop.height}<br/>
                            tX: {photoCrop.x} tY: {photoCrop.y} tW: {photoCrop.w} tH: {photoCrop.h}<br/>
                            scale: {debugData.scale}<br/>
                            nW: {debugData.naturalWidth} nH: {debugData.naturalHeight} w: {debugData.width} h: {debugData.height}<br/>
                        </div>}
                        {croppedUrl && (

                            <div ref={croppedImageWrapperRef} style={{
                                ...croppedImageStyle,
                                position: 'absolute',
                                backgroundColor: '#fff',
                                border: '1px solid #eee'
                            }}>
                                {guidesVisible && guides.map(guide => {
                                    const GuideComponent = guide.type === 'horizontal' ? HorizontalGuide : VerticalGuide;
                                    return <GuideComponent
                                        onShiftMouseDown={() => {
                                            guidesDispatch({type: 'remove', id: guide._id});
                                        }}
                                        onControlMouseDown={() => {
                                            setEditedGuideId(guide._id);
                                            setColorPickerVisible(true);
                                        }}
                                        key={`guide-${guide._id}`}
                                        container={croppedImageWrapperRef.current || undefined}
                                        position={guide.position}
                                        onUpdate={(newPosition: any) => {
                                            guidesDispatch({type: 'update', id: guide._id, position: newPosition})
                                        }}
                                        color={guide.color}
                                    />;
                                })}
                                <img ref={croppedImageRef} style={{width: '100%', height: '100%', objectFit: 'contain'}}
                                     src={croppedUrl + '&temporary=1'} onLoad={event => {
                                    setCroppedImageParams();
                                }}/>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={6} style={{
                    height: '48px'
                }} container spacing={2}>
                    <Grid item xs={6} container>
                        <PaddingSlider value={photoCrop.padLeft} label={"Lewy"} property={"padLeft"}
                                       stateSetter={setPhotoCrop}/>
                        <PaddingSlider value={photoCrop.padRight} label={"Prawy"} property={"padRight"}
                                       stateSetter={setPhotoCrop}/>
                    </Grid>
                    <Grid item xs={6} container>
                        <PaddingSlider value={photoCrop.padTop} label={"Górny"} property={"padTop"}
                                       stateSetter={setPhotoCrop}/>
                        <PaddingSlider value={photoCrop.padBottom} label={"Dolny"} property={"padBottom"}
                                       stateSetter={setPhotoCrop}/>
                    </Grid>
                </Grid>
                <Grid alignItems={"center"} justify={'space-between'} item xs={6} style={{
                    height: '48px'
                }} container>
                    <Grid item xs={9}>
                        Linie pomocnicze <Checkbox checked={guidesVisible} onChange={(event, checked) => {
                        setGuidesVisible(checked)
                    }}/>
                        Nowa:
                        <IconButton onClick={addNewGuide('horizontal')}><BorderHorizontal/></IconButton>
                        <IconButton onClick={addNewGuide('vertical')}><BorderVertical/></IconButton>
                        Usuwanie linii: shift+click
                        Kolor: ctrl+click
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'right'}}>
                        Rozmiar: {photoCrop.w} x {photoCrop.h} {(photoCrop.w < 398 || photoCrop.h < 398) ?
                        <WarningIcon style={{fontSize: 16, color: '#ffb710'}}/> : undefined}
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    )
}