import React, {Component} from "react";
import Box from "../../Common/Box/Box";
import TextField from "../../Common/TextField/TextField";
import AutocompleteSelectField from "../../Common/AutocompleteSelectField/AutocompleteSelectField";
import Dictionary from "../../../../DataProviders/Dictionary";
import CalculatedPrice from "./CalculatedPrice";

interface PricingProps {
}

class Pricing extends Component<PricingProps> {
    public render = (): JSX.Element =>
        <Box title={"Cenówka"}>
            <TextField
                label={"Cena"}
                path={"price.value"}
                type={"number"}
            />
            <AutocompleteSelectField
                label={"Typ ceny"}
                path={"price.type"}
                optionsProvider={Dictionary.getCollectionData('priceTypes')}
                optionsMapper={((item: any) => ({
                    label: `${item.name} (${item.multiplier})`,
                    value: item
                }))}
                disableRemove
            />
            <AutocompleteSelectField
                label={"Waluta"}
                path={"price.currency"}
                optionsProvider={Dictionary.getCollectionData('currencies')}
                optionsMapper={((item: any) => ({
                    label: `${item.code} (${item.rate})`,
                    value: item
                }))}
            />
            <CalculatedPrice
                path={"price.calculated"}
            />
        </Box>
}

export default Pricing;