import React, {Component, Dispatch} from "react";
import Popper from '@material-ui/core/Popper';
import Button from "@material-ui/core/Button";
import axios from "axios";
import {Product} from "mesmetric-v2-common/models/Product";
import DeleteIcon from "@material-ui/icons/Delete";
import Fade from "@material-ui/core/Fade";
import "../popper.scss"
import {AppState} from "../../../Store";
import {AllActions} from "../../../ActionCreators/ProductsList";
import {ProductFilterState} from "../../../State/ProductFilter";
import {searchProductsByFilter} from "../../../ActionCreators/ProductFilter";
import {connect} from "react-redux";
import {EDIT_PRODUCT_SUB_PATH} from "../../../Views/ProductListing";
import AbsoluteSpinner from "../../UI/AbsoluteSpinner/AbsoluteSpinner";
import {getAxiosConfig} from "../../../ActionCreators/User";
import {parseError} from "../../../ActionCreators/Error";

interface OwnProps {
    productId: string,
    size?: 'small' | 'medium' | 'large'
}

interface DispatchProps {
    searchProductsByFilter: any
}

interface StateProps {
    filter: ProductFilterState
}

type Props = OwnProps & DispatchProps & StateProps

interface State {
    anchorEl: null | HTMLElement,
    id: string,
    productDeleting: boolean
}

class DeleteProductPopper extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            anchorEl: null,
            id: "deleteProduct",
            productDeleting: false
        }
    }

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: this.state.anchorEl ? null : event.currentTarget
        });
    };

    deleteProduct = () => {
        this.setState({
            productDeleting: true
        });

        axios.delete<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.productId}`, getAxiosConfig())
            .then(() => {
                this.setState({
                    anchorEl: null
                });
                this.props.searchProductsByFilter(this.props.filter);
                if (window.location.href.indexOf(`${EDIT_PRODUCT_SUB_PATH}/${this.props.productId}`) > -1) {
                    window.history.back();
                }
            })
            .catch(parseError)
            .finally(() => {
                this.setState({
                    productDeleting: false
                });
            })

    };

    onClosePopper = () => {
        this.setState({
            anchorEl: null
        })
    };

    render = (): JSX.Element => {
        return (
            <>
                <Button
                    variant="contained"
                    color={"secondary"}
                    size={this.props.size}
                    className={"delete-button"}
                    aria-describedby={this.state.id}
                    onClick={this.handleClick}>
                    <DeleteIcon/>
                </Button>
                <Popper id={this.state.id} open={Boolean(this.state.anchorEl)} anchorEl={this.state.anchorEl}
                        transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className="popper">
                                <span>Jesteś pewien, że chcesz usunąć ten produkt?</span>
                                <div className={"buttons"}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.deleteProduct}>
                                        <span>POTWIERDŹ</span>
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.onClosePopper}>
                                        <span>ANULUJ</span>
                                    </Button>
                                </div>
                                {this.state.productDeleting && <AbsoluteSpinner/>}
                            </div>
                        </Fade>
                    )}
                </Popper>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    filter: state.ProductFilter
});

const mapDispatchToProps = (dispatch: Dispatch<AllActions>) => ({
    searchProductsByFilter: (filter: ProductFilterState) => dispatch(searchProductsByFilter(filter))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProductPopper);