import {Photo} from "mesmetric-v2-common/models";

type CropPostTransform = {
    w?: number,
    h?: number
}

export function getCroppedSrc(photo: Photo.Model, postTransform?: CropPostTransform): string {
    if (!photo.src) {
        return '';
    }
    const paddingParameterMap = {
        'padLeft': 'pl',
        'padRight': 'pr',
        'padTop': 'pt',
        'padBottom': 'pb',
    };
    let crop = photo.crops.find(c => c.active);
    if (!crop) {
        crop = photo.crops.find(crop => crop.aspect === '1:1');
    }
    const post = postTransform || {w: 100};
    const queryTokens: string[] = [];
    if (crop) {
        (['x', 'y', 'w', 'h'] as Array<keyof Photo.Crop>).forEach(prop => {
            queryTokens.push(`c${prop}=${Math.floor(Number(crop?.[prop]))}`);
        });
        (['padLeft', 'padRight', 'padTop', 'padBottom'] as Array<keyof Pick<Photo.Crop, 'padLeft' | 'padRight' | 'padTop' | 'padBottom'>>).forEach(prop => {
            if (crop?.[prop] !== undefined) {
                queryTokens.push(`${paddingParameterMap[prop]}=${crop[prop]}`)
            }
        });
    }
    (['w', 'h'] as Array<keyof CropPostTransform>).forEach(key => {
        if (post[key] === undefined) {
            return;
        }
        queryTokens.push(`${key}=${post[key]}`);
    });
    queryTokens.push('t=lanczos');
    return photo.srcResolved + '?' + queryTokens.join('&');
}