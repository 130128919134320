import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {emphasize, makeStyles, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from "react-select";
import {OptionProps} from "react-select/src/components/Option";
import {ControlProps} from "react-select/src/components/Control";
import {MenuProps, NoticeProps} from "react-select/src/components/Menu";
import {PlaceholderProps} from "react-select/src/components/Placeholder";
import {SingleValueProps} from "react-select/src/components/SingleValue";
import {ValueContainerProps} from "react-select/src/components/containers";
import {MultiValueProps} from "react-select/src/components/MultiValue";

type DefaultOptionType = {
    [key: string]: any
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function NoOptionsMessage(props: NoticeProps<DefaultOptionType>) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({inputRef, ...props}: any) {
    return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

function Control(props: ControlProps<DefaultOptionType>) {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps},
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}


function Option(props: OptionProps<DefaultOptionType>) {
    const {onMouseMove, onMouseOver, ...innerProps} = props.innerProps;
    return {}
}

function Placeholder(props: PlaceholderProps<DefaultOptionType>) {
    const {selectProps, innerProps = {}, children} = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
            {children}
        </Typography>
    );
}

function SingleValue(props: SingleValueProps<DefaultOptionType>) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props: ValueContainerProps<DefaultOptionType>) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props: MultiValueProps<DefaultOptionType>) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={clsx(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props: MenuProps<DefaultOptionType>) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    // Menu,
    MultiValue,
    // NoOptionsMessage,
    // Option,
    // Placeholder,
    // SingleValue,
    // ValueContainer,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null
};


type AutoCompleteSelectProps<OptionType> = {
    optionsProvider: Promise<any>
    optionsMapper: (item: any, index?: number, array?: any) => unknown
    property: string
    isMulti: boolean
    noOptionsMessage: string
    placeholder: string
    label: string
    value: any
    onChange: (changeEvent: {
        target: {
            value: any
        }
    }) => void
}


export default function AutoCompleteSelect<OptionType = DefaultOptionType>(props: AutoCompleteSelectProps<OptionType>) {
    const classes = useStyles();
    const theme = useTheme();
    const [options, setOptions] = useState([] as OptionType[]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        props.optionsProvider.then((result: Array<any>) => {
            const options = result.map(props.optionsMapper);
            setOptions(options as OptionType[]);
            setIsLoading(false);
        });
    }, []);

    const getCurrentValue = (realValue: any, options: any) => {
        if (props.isMulti) {
            return realValue.map((currentValueItem: any) => {
                return getValueFromOptions(currentValueItem, options);
            })
        } else {
            return getValueFromOptions(realValue, options)
        }
    };

    const selectStyles = {
        input: (base: any) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    const inputId = `select-${props.property}`;

    const getSearchTermTester = (searchTerm: string): (labelToTest: string) => boolean => {
        let regexString = searchTerm;
        if (searchTerm.indexOf(' ') === -1) {
            regexString = '^' + regexString;
        }
        const regex = new RegExp(regexString, 'i');

        return (labelToTest) => {
            if (searchTerm.indexOf(' ') !== -1) {
                return regex.test(labelToTest);
            }
            return labelToTest.split(' ').reduce((hasPassed: boolean, currentLabelToken: string) => {
                return hasPassed || regex.test(currentLabelToken);
            }, false);
        }
    }

    const getValueFromOptions = (value: any, options: any) => {
        return {
            label: options.find((option: any) => option.value._id == value._id).label,
            value: value
        }
    }
    return (
        <Select
            filterOption={(option, searchTerm) => getSearchTermTester(searchTerm)(option.label)}
            noOptionsMessage={() => props.noOptionsMessage}
            classes={classes}
            styles={selectStyles}
            inputId={inputId}
            TextFieldProps={{
                margin: 'normal',
                label: props.label,
                InputLabelProps: {
                    htmlFor: {inputId},
                    shrink: true,
                },
            }}
            placeholder={isLoading ? 'Wczytuję opcje' : props.placeholder}
            options={options}
            components={components}
            value={(isLoading || props.value === null || props.value === undefined) ? false : getCurrentValue(props.value, options)}
            onChange={(newValue: any) => {
                props.onChange({
                    target: {
                        value: props.isMulti ? ((newValue || []) as any[]).map(newValueItem => newValueItem.value) : (newValue as any).value
                    }
                })
            }}
            isMulti={props.isMulti}
        />
    );
}