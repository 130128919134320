import React from "react";
import {Table, TableBody} from "@material-ui/core";
import MuiTableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import {Dictionary, DictionaryItem} from "mesmetric-v2-common/models";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {languageLabels, languages} from "mesmetric-v2-common";
import CreateNewButton from "./UI/MesmetricButton";
import withStyles from "@material-ui/core/styles/withStyles";
import {getObjectId} from "../Common/Utility";
import TrashIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DragAndDropArray from "../Helpers/DragAndDropArray";
import update from "immutability-helper"

const TableCell = withStyles((theme) => ({
    body: {
        borderBottom: 'none',
        padding: theme.spacing(1)
    }
}))(MuiTableCell);

type dictionaryTableProps = {
    dictionary: Dictionary
    onChange: (updatedDictionary: Dictionary) => void
}


export default React.memo(({dictionary, onChange}: dictionaryTableProps): JSX.Element => {
    return <div>
        <TextField label="Nazwa słownika" fullWidth value={dictionary.label} onChange={event => {
            dictionary.label = event.target.value;
            onChange({...dictionary});
        }}/>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell key={`cell-drag`}/>
                    {Object.values(languageLabels).map(value => {
                        return <TableCell key={`cell-${value}`}>{value}</TableCell>
                    })}
                    <TableCell key={`cell-action`}/>
                </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={function (result: any) {
                DragAndDropArray.moveItem(dictionary.items, result.source.index, result.destination.index, (sortedItems: any) => {
                    onChange(update(dictionary, {
                        items: {
                            $set: sortedItems
                        }
                    }));
                })
            }}>
                <Droppable droppableId="droppable">
                    {(provided: any, snapshot: any) => (
                        <TableBody ref={provided.innerRef}>
                            {dictionary.items.map((row: any, index: any) => (
                                <Draggable key={row._id} index={index} draggableId={`draggable-${row._id}`}>
                                    {(provided, snapshot) => (
                                        <TableRow style={snapshot.isDragging ? {display: 'table'} : undefined}
                                                  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                        >
                                            <TableCell style={{width: '1px', verticalAlign: "top"}} key={`cell-drag-handle`}>
                                                <DragHandleIcon/>
                                            </TableCell>
                                            {(Object.keys(languageLabels) as Array<languages>).map(languageId => {
                                                const duplicate = row.label[languageId] && dictionary.items.filter(item => item.label[languageId]?.toUpperCase()?.trim() === row.label[languageId].toUpperCase().trim()).length > 1;
                                                const noTranslation = languageId === "en" && !row.label[languageId];
                                                const getErrorMessage = () => {
                                                    if (duplicate) {
                                                        return "Duplikat";
                                                    } else if (noTranslation) {
                                                        return "Brak tłumaczenia";
                                                    }
                                                };
                                                return <TableCell style={{width: '50%', verticalAlign: "top"}}
                                                                  key={`cell-${languageId}`}>
                                                    <TextField fullWidth
                                                               error={duplicate || noTranslation}
                                                               helperText={getErrorMessage()}
                                                               value={row.label[languageId] || ''}
                                                               onChange={event => {
                                                                   onChange(update(dictionary, {
                                                                       items: {
                                                                           [index]: {
                                                                               label: {
                                                                                   [languageId]: {
                                                                                       $set: event.target.value
                                                                                   }
                                                                               }
                                                                           }
                                                                       }
                                                                   }));
                                                               }}/>
                                                </TableCell>
                                            })}
                                            <TableCell style={{width: '1px', verticalAlign: "top"}}>
                                                <IconButton size={"small"}
                                                            onClick={e => {
                                                                onChange(update(dictionary, {
                                                                    items: {
                                                                        $splice: [[index, 1]]
                                                                    }
                                                                }));
                                                            }}>
                                                    <TrashIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Draggable>
                            ))
                            }
                            {provided.placeholder}
                        </TableBody>
                    )}
                </Droppable>
            </DragDropContext>

        </Table>
        <CreateNewButton onClick={(e: any) => {
            const newItem: DictionaryItem = {
                label: {
                    pl: "Nowe słowo",
                    en: "New item"
                },
                _id: getObjectId()
            };
            dictionary.items.push(newItem);
            onChange({
                ...dictionary
            });
        }} buttonText={"Dodaj nowe słowo"}/>
    </div>
})