import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import MesmetricTable, {ColumnDefinition} from "../Components/MesmetricTable";
import {RouteComponentProps} from "react-router";
import MesmetricButton from "../Components/UI/MesmetricButton";
import {ProductTemplate} from "mesmetric-v2-common/models";
import {TextField} from "@material-ui/core";
import FormDialog from "../Components/UI/FormDialog";
import {getAxiosConfig} from "../ActionCreators/User";
import {parseError} from "../ActionCreators/Error";

const tableColumns: ColumnDefinition<ProductTemplate>[] = [
    {
        accessPath: 'label',
        name: 'label',
        label: 'Nazwa szablonu',
    }
]

export default (props: RouteComponentProps) => {
    const [templates, setTemplates] = useState<ProductTemplate[]>([]);
    const [newDialogVisible, setNewDialogVisible] = useState<boolean>(false);
    const onDialogClose = useCallback(() => setNewDialogVisible(false), []);
    const [newTemplateData, setNewTemplateData] = useState<ProductTemplate>({
        attributeGroups: [],
        label: "",
        categories: [],
        guidelines: []
    })
    const [isLoading, setIsLoading] = useState(false);
    const fetchTemplates = () => {
        return axios.get<ProductTemplate[]>(process.env.REACT_APP_DATA_ENDPOINT + '/templates', getAxiosConfig())
            .then(({data}) => setTemplates(data)).catch(parseError)
    }
    useEffect(() => {
        fetchTemplates()
    }, []);
    return <>
        <FormDialog canSave={newTemplateData.label !== ''} isLoading={isLoading} title="Nowy szablon"
                    open={newDialogVisible} onClose={onDialogClose} onSave={() => {
            setIsLoading(true);
            axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/templates', newTemplateData, getAxiosConfig())
                .then(fetchTemplates)
                .then(() => {
                    setIsLoading(false);
                    setNewTemplateData({...newTemplateData, label: ''});
                    onDialogClose();
                }).catch(parseError)
        }}>
            <TextField
                disabled={isLoading}
                autoFocus
                margin="dense"
                id="name"
                label="Nazwa nowego szablonu"
                value={newTemplateData.label}
                onChange={e => {
                    setNewTemplateData({...newTemplateData, label: e.target.value})
                }
                }
                fullWidth
            />
        </FormDialog>
        <MesmetricTable rowOnClick={(row: ProductTemplate) => {
            props.history.push(`/templates/edit/${row._id}`)
        }} columns={tableColumns} rows={templates}/>
        <MesmetricButton
            onClick={() => {
                setNewDialogVisible(true);
            }}/>
    </>
}
