import {searchProducts} from "../Views/ProductListing/ProductsList";
import {replaceProducts, setIsSearching} from "./ProductsList";
import {debounce} from "lodash";
import {AppState} from "../Store";
import {parseError} from "./Error";

export enum ActionTypes {
    updateFilterField = "updateFilterField"
}

export interface UpdateFilterFieldAction {
    type: ActionTypes.updateFilterField,
    field: string,
    value: any
}

const debouncedSearch = debounce((dispatch, getState: () => AppState) => {
    const filter = getState().ProductFilter;
    dispatch(setIsSearching(true));
    searchProducts(filter, undefined, 250)
        .then(({count, products}) => {
            dispatch(replaceProducts(products));
        })
        .catch(parseError)
        .finally(() => {
            dispatch(setIsSearching(false));
        })

}, 250);

export function searchProductsByFilter(filter: any): any {
    return (dispatch: any) => {
        dispatch(setIsSearching(true));
        searchProducts(filter, undefined, 250)
            .then(({count, products}) => {
                dispatch(replaceProducts(products));
            })
            .catch(parseError)
            .finally(() => {
                dispatch(setIsSearching(false));
            })
    }
}

export function UpdateFilterField(field: string, value: any): any {
    return (dispatch: any, getState: () => AppState) => {
        dispatch(_UpdateFilterField(field, value))
    }
}

export function search(): any {
    return (dispatch: any, getState: () => AppState) => {
        debouncedSearch(dispatch, getState);
    }
}

function _UpdateFilterField(field: string, value: any): UpdateFilterFieldAction {
    return {
        type: ActionTypes.updateFilterField,
        field: field,
        value: value
    }
}

export type AllActions = UpdateFilterFieldAction;