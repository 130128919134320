import axios from "axios";
import {getAxiosConfig} from "./User";
import {parseError} from "./Error";
import {ThunkAction} from "redux-thunk";
import {AppState} from "../Store";
import {ItemData} from "../Views/Brands/ItemForm/ItemForm";

export interface BackendBrand {
    _id: string,
    catalogue?: {
        file: any,
        image: ""
    },
    code: string,
    isActive: boolean,
    logo?: string,
    name: string,
    order: number,
    priceMultiplier: number,
    isRemovable?: boolean
}

export enum ActionTypes {
    setBrands = "setBrands",
    setIsSearchingBrands = "setIsSearchingBrands",
}

export interface SetBrandsAction {
    type: ActionTypes.setBrands
    brands: BackendBrand[]
}

export interface SetIsSearchingAction {
    type: ActionTypes.setIsSearchingBrands,
    isSearching: boolean
}

function setBrands(brands: BackendBrand[]): SetBrandsAction {
    return {
        type: ActionTypes.setBrands,
        brands: brands
    };
}

export const MESSAGES_FROM_SERVER: { [key: string]: string } = {
    BRAND_NOT_FOUND: "Nie ma takiej marki",
    BRAND_HAVE_PRODUCTS: "Istnieją produkty przypisane do tej marki"
};

export const fetchBrands = (): ThunkAction<void, AppState, {}, SetIsSearchingAction | SetBrandsAction> => {
    return async (dispatch: any) => {
        dispatch({
            type: ActionTypes.setIsSearchingBrands,
            isSearching: true
        });
        dispatch({
            type: ActionTypes.setBrands,
            brands: []
        });
        try {
            const brands = (await axios.get<BackendBrand[]>(`${process.env.REACT_APP_DATA_ENDPOINT}/brands`, getAxiosConfig())).data;
            dispatch(setBrands(brands));
        } catch (e) {
            parseError(e);
        }
        dispatch({
            type: ActionTypes.setIsSearchingBrands,
            isSearching: false
        });
    }
};

export const getBrands = (): ThunkAction<Promise<BackendBrand[]>, AppState, {}, never> =>
    async () => {
        try {
            const data = (await axios.get(`${process.env.REACT_APP_DATA_ENDPOINT}/brands`, getAxiosConfig())).data;
            return data;
        } catch (e) {
            parseError(e);
            return []
        }
    };

export const addBrand = (userData: ItemData): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/add`, {...userData}, getAxiosConfig());
            dispatch(fetchBrands());
        } catch (e) {
            parseError(e);
        }
    };

export const editBrand = (id: string, userData: ItemData): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/edit/` + id, {...userData}, getAxiosConfig());
            dispatch(fetchBrands());
        } catch (e) {
            parseError(e);
        }
    };

export const deleteBrand = (id: string): ThunkAction<Promise<void>, AppState, {}, never> =>
    async (dispatch) => {
        try {
            await axios.post(`${process.env.REACT_APP_DATA_ENDPOINT}/brands/delete/` + id, {}, getAxiosConfig());
            dispatch(fetchBrands());
        } catch (e) {
            parseError(e);
        }
    };

export type AllActions =
    SetBrandsAction |
    SetIsSearchingAction;