import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {Button, PropTypes} from "@material-ui/core";
import {AppState} from "../../../../Store";
import {updateValue} from "../../../../ActionCreators/ProductData";
import "./checkboxButton.scss";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

interface ExternalProps {
    path: string,
    label: string,
    color?: PropTypes.Color
}

interface StateProps {
    value: any,
    label: string,
    color?: PropTypes.Color
}

interface DispatchProps {
    onChange: (value: any) => void
}

type CheckboxButtonProps = StateProps & DispatchProps;

class CheckboxButton extends Component<CheckboxButtonProps> {
    public render = () =>
        <Button
            variant="contained"
            className={"checkbox-button"}
            color={this.props.value ? (this.props.color || "primary") : undefined}
            size={"small"}
            onClick={() => this.props.onChange(!this.props.value)}
        >
            <>
                <div className={"checkbox-blank-square"}/>
                <CheckBoxOutlineBlankIcon className={"check-outline"}/>
                {this.props.value && <CheckIcon className={"check"}/>}
                {this.props.label}</>
        </Button>
}

const mapStateToProps = (state: AppState, externalProps: ExternalProps): StateProps => ({
    value: _.get(state.ProductData.productData, externalProps.path, ""),
    label: externalProps.label,
    color: externalProps.color
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>, externalProps: ExternalProps): DispatchProps => ({
    onChange: (value: any) => dispatch(updateValue(externalProps.path, value))
});

export const CheckboxButtonNotConnected = CheckboxButton;
export default connect(mapStateToProps, mapDispatchToProps)(CheckboxButton)