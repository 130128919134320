import {ActionTypes, AllActions} from "../ActionCreators/ProductsList";
import {initialState, ProductsListState} from "../State/ProductsList";
import update from "immutability-helper"

export default function ProductsListReducer (state: ProductsListState = initialState, action: AllActions): ProductsListState {
    switch (action.type) {
        case ActionTypes.setScroll:
            return update(state, {
                scrollY: {
                    $set: action.data
                }
            });
        case ActionTypes.replaceProducts:
            return update(state, {
                products: {
                    $set: action.data
                }
            });
        case ActionTypes.setProductsAt:
        {
            return update(state, {
                products: {
                    $splice: [
                        [action.at, 0, ...action.data]
                    ]
                }
            })
        }
        case ActionTypes.setIsSearching: {
            return update(state, {
                isSearching: {
                    $set: action.data
                }
            });
        }
        case ActionTypes.updateProductInList: {
            const productIndex = state.products.findIndex(product => {
                return product._id === action.product._id
            });
            return update(state, {
                products: {
                    [productIndex]: {
                        $set: action.product
                    }
                }
            });
        }
        default:
            return state;
    }
}

