import {applyMiddleware, combineReducers, compose, createStore} from 'redux'

import * as Reducers from "./Reducers"

import thunk from 'redux-thunk';

// @ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers(
    {
        ProductCard: Reducers.ProductCard,
        ProductsList: Reducers.ProductsList,
        Categories: Reducers.Categories,
        Brands: Reducers.Brands,
        ProductFilter: Reducers.ProductFilter,
        ProductData: Reducers.ProductData,
        User: Reducers.User,
        Notifications: Reducers.Notifications,
        Products: Reducers.Products,
        ProductsFilters: Reducers.ProductsFilters
    });
export const store = createStore(rootReducer, {}, composeEnhancer(applyMiddleware(thunk)));
export default store
export type AppState = ReturnType<typeof rootReducer>
