const parseAndSumItems:(items: Array<string | null>) => number = (items) => {
    return items.map(v => v ? parseInt(v, 10) : 0).reduce((sum, current) => sum+current, 0)
}

const getHorizontalPadding:(element:HTMLElement) => number = (element: HTMLElement) => {
    const computedStyle = window.getComputedStyle(element);
    return parseAndSumItems([computedStyle.paddingLeft, computedStyle.paddingRight])
}

const getVerticalPadding:(element:HTMLElement) => number = (element: HTMLElement) => {
    const computedStyle = window.getComputedStyle(element);
    return parseAndSumItems([computedStyle.paddingTop, computedStyle.paddingBottom])
}

export const getElementWidth = (element:HTMLElement): number => {
    return element.offsetWidth - getHorizontalPadding(element);
}

export const getElementHeight = (element:HTMLElement): number => {
    return element.offsetHeight - getVerticalPadding(element);
}
