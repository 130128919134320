import React, {useCallback, useState} from "react";
import {DictionaryItem as DictionaryItemModel,} from "mesmetric-v2-common/models";
import Creatable from 'react-select/creatable';
import update from "immutability-helper";
import {buildUpdateSpec, getObjectId} from "../../Common/Utility";
import axios from "axios";
import AbsoluteSpinner from "../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import {ProductFilter} from "mesmetric-v2-common/models/ProductFilter";
import {getAxiosConfig} from "../../ActionCreators/User";
import {parseError} from "../../ActionCreators/Error";

interface AttributesProps {
    product?: any,
    setProductData?: any,
    setProduct?: any,
    refreshProduct: () => Promise<void>
}

export const Filters = React.memo<AttributesProps>((props) => {
    const product = props.product;
    const onChange = useCallback((newValue, filterId) => {
        props.setProductData((product: any) => {
            return update(product, buildUpdateSpec(['filters', filterId], {
                $set: newValue
            }));
        })

    }, [props.setProduct]);
    if (product === undefined) {
        return <div>Wczytuję produkt...</div>
    }
    if (product.primaryCategory.template === undefined) {
        return <div>Brak przypisanego szablonu</div>
    }
    return <FilterGroupItems
        filters={product.primaryCategory.template.filterGroup?.filters}
        onChange={onChange}
        refreshProduct={props.refreshProduct}
        value={product.filters}/>
});

interface FilterGroupItemsProps {
    filters: any,
    onChange: (newValue: any, filterId: string) => void,
    refreshProduct: () => Promise<void>,
    value: any
}

const FilterGroupItems: React.FC<FilterGroupItemsProps> = (props => {
    return <>{props.filters?.map((filter: ProductFilter) => {
        const values = (props.value !== undefined && props.value.hasOwnProperty(filter._id)) ? props.value[filter._id] : undefined;
        return <>
            <div>{filter.label.pl}</div>
            <DictionaryItem
                onChange={newValue => {
                    if (newValue !== null) {
                        props.onChange(newValue.sort((a: any, b: any) => a.label.localeCompare(b.label)).map((item: any) => item.value), filter._id);
                    } else {
                        props.onChange(null, filter._id);
                    }
                }}
                onCreate={(item) => new Promise<string>(resolve => {
                    const itemId = getObjectId();
                    axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/dictionaries/add/', {
                        dictionaryId: filter.dictionary._id,
                        label: item,
                        itemId
                    }, getAxiosConfig()).then(() => props.refreshProduct().then(() => resolve(itemId)).catch(parseError)).catch(parseError);
                })
                }
                item={filter}
                values={values && mapValuesToOptions(filter.dictionary.items, values as string[])}
            />
        </>;

    })}
    </>
})

interface DictionaryItemProps {
    item: ProductFilter,
    values?: any,
    onChange: (newValue: any) => void,
    onCreate: (newItem: string) => Promise<string>
}

const DictionaryItem = React.memo<DictionaryItemProps>(props => {
    const [isAdding, setIdAdding] = useState(false);
    const getItems = () => props.item.dictionary.items.map(item => {
        return {
            label: item.label.pl,
            value: item._id
        }
    }).sort((a, b) => a.label.localeCompare(b.label));

    return <div style={{position: "relative"}}>
        {isAdding && <AbsoluteSpinner/>}
        <Creatable
            placeholder={"Wybierz słowa"}
            value={props.values || ''}
            onChange={props.onChange}
            onCreateOption={newItem => {
                setIdAdding(true);
                props.onCreate(newItem).then(id => {
                    setIdAdding(false);
                    props.onChange((props.values || []).concat({label: newItem, value: id}))
                })
            }}
            isMulti
            options={getItems()}
            formatCreateLabel={(newItem) => `Dodaj "${newItem}"`}
            closeMenuOnSelect={false}/>
    </div>
});
DictionaryItem.displayName = 'DictionaryItem';

function mapValuesToOptions(items: DictionaryItemModel[], selectedValues: string[]) {
    return selectedValues.map(valueId => {
        const foundItem = items.find(item => item._id === valueId);
        return {
            label: foundItem && foundItem.label.pl,
            value: valueId
        }
    })
}

