import {FormControl, TextField} from "@material-ui/core";
import React, {useReducer, useState} from "react";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import set from "lodash.set"
import get from "lodash.get"
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import PaddedPaper from "../Components/PaddedPaper/PaddedPaper";

const BoundInput = ({fieldId, templateData, dispatch, processData, ...props}) => {
    return <TextField {...props} value={get(templateData, fieldId)} onChange={e => {
        let processedData = e.target.value;
        if (processData) {
            processedData = processData(processedData);
        }
        dispatch({field: fieldId, value: processedData})
    }}/>
}

const updateField = (state, action) => {
    return {
        ...set(state, action.field, action.value)
    }
}

const attributeTypes = {
    size: "size",
    dictionary: "dictionary"
}


export default (props) => {
    const [templateData, dispatch] = useReducer(updateField, {
        type: attributeTypes.dictionary,
        isMulti: true,
        label: {
            pl: "Label PL",
            en: "Label EN"
        },
        dictionary: []
    });
    const [sizes, setSizes] = useState([]);

    return <PaddedPaper>
        <FormControl component='fieldset' fullWidth={true}>
            <BoundInput dispatch={dispatch} fieldId={'id'} label="Identyfikator" templateData={templateData}/>
            <BoundInput dispatch={dispatch} fieldId={'label.pl'} label="Nazwa PL" templateData={templateData}/>
            <BoundInput dispatch={dispatch} fieldId={'label.en'} label="Nazwa EN" templateData={templateData}/>
            <Checkbox checked={templateData.isMulti} onChange={e => {
                dispatch({field:'isMulti', value: e.target.checked})
            }}/>
            <Select value={templateData.type} label="Typ atrybutu" onChange={e => {
                dispatch({field: 'type', value: e.target.value});
            }}>
                {Object.values(attributeTypes).map(attributeType => <MenuItem value={attributeType} key={attributeType}>{attributeType}</MenuItem>)}
            </Select>

            {templateData.type == attributeTypes.dictionary &&
            <TextField multiline value={templateData.dictionary.join(',')} onChange={e => {
                dispatch({
                    field: 'dictionary',
                    value: e.target.value.replace(/[\n\t]/g, ',').split(',').map(item => item.toLowerCase())
                })
            }}/>
            }
            <Button onClick={e => {
                const cleanData = templateData.dictionary.map(item => item.trim()).filter(item => item)
                dispatch({field: 'dictionary', value: cleanData});
            }}>Wyczyść</Button>
            <TextField label="Output" margin="normal" multiline value={JSON.stringify(templateData, null, 4)}
                       onChange={e => {
                           JSON.parse(e.target.value)
                       }
                       }/>
        </FormControl>
        <Typography>Sizes</Typography>
        <FormControl component='fieldset'>
            <TextField multiline value={sizes.join(',')} onChange={e => {
                setSizes(e.target.value.replace(/[\n\t]/g, ',').split(',').map(item => item.toLowerCase()));
            }}/>
            }
            <Button onClick={e => {
                setSizes(sizes.map(item => item.trim()).filter(item => item));
            }}>Wyczyść</Button>
            <TextField label="Output" margin="normal" multiline value={JSON.stringify(
                sizes.map(size => ({
                    type: 'size',
                    label: {
                        pl: size
                    }
                })),false, 4
            )}/>
        </FormControl>
    </PaddedPaper>
}